import { Component, OnDestroy, OnInit } from '@angular/core';
import { CertificatesFiltersService } from '../../services/certificates-filters.service';
import { CertificateTagInterface } from '../../../../core/models/certificate.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-selected-tags-list',
  templateUrl: './selected-tags-list.component.html',
})
export class SelectedTagsListComponent implements OnInit, OnDestroy {
  selectedTags: CertificateTagInterface[] = [];

  subscription = new Subscription();

  constructor(
    private certificatesFiltersService: CertificatesFiltersService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.certificatesFiltersService.getSelectedTagsAsObservable().subscribe(tags => {
        this.selectedTags = tags;
      })
    );
  }

  onTagRemove(tagId: number): void {
    this.certificatesFiltersService.unselectTag(tagId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
