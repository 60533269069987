import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  GROUPS_DEPTH_LIMIT,
  OrderItemsGroupService,
  OTHER_ITEMS_DEPTH_LIMIT,
} from '../../../../core/services/order-items-group/order-items-group.service';
import { DrakeStoreService } from '../services/drake-store/drake-store.service';
import { RowTypes } from '../../order-articles-list.interface';
import { DraggableDirective } from './ngx-draggable.directive';
import {flattenDeep} from "../../../../core/util/flatten-deep/flatten-deep.helper";

let i = 10000;

function getNextId() {
  return i++;
}

/**
 * Makes the container droppable and children draggable.
 *
 * @export
 */
@Directive({ selector: '[appDroppable]' })
export class DroppableDirective implements OnInit, OnDestroy, AfterViewInit {
  defaultZone = '';

  @Input() model: any;
  @Input() copy = false;
  @Input() removeOnSpill = false;
  @Input() groupHead: any;
  @Input() isDisabled = false;

  @Output() drop: EventEmitter<any> = new EventEmitter<any>();

  @Output() drag: EventEmitter<any> = new EventEmitter<any>();

  @Output() over: EventEmitter<any> = new EventEmitter<any>();

  @Output() out: EventEmitter<any> = new EventEmitter<any>();

  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  @Input() dropZone = this.defaultZone;

  get container(): any {
    return this.el.nativeElement;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private drakesService: DrakeStoreService,
    private orderItemsGroupService: OrderItemsGroupService
  ) {}

  accepts(el: DraggableDirective, targetComponent: DroppableDirective): boolean {
    if (targetComponent.isDisabled) {
      return false
    }

    const nestLimitByRowType =
      el.model.rowType === RowTypes.GROUP || el.model.rowType === RowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP
        ? GROUPS_DEPTH_LIMIT
        : OTHER_ITEMS_DEPTH_LIMIT;

    const recalculatedModel = this.orderItemsGroupService.setLevels([Object.assign({}, el.model)], true);

    let deepestGroupLevel = recalculatedModel[0] ? recalculatedModel[0].groupLevel : 0;

    if (!targetComponent.groupHead) {
      return true;
    }

    if (targetComponent.groupHead.groupLevel + deepestGroupLevel > nestLimitByRowType) {
      return false;
    }

    const flattenGroups = flattenDeep(recalculatedModel).filter(
      (row) => row.rowType === RowTypes.GROUP || row.rowType === RowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP
    );

    if (flattenGroups && flattenGroups.length) {
      deepestGroupLevel = flattenGroups.reduce((prev, current) => {
        return prev.groupLevel > current.groupLevel ? prev : current;
      }).groupLevel;
    }

    return targetComponent.groupHead.groupLevel + deepestGroupLevel < nestLimitByRowType;
  }

  ngOnInit(): void {
    this.defaultZone = `@@DefaultDropZone-${getNextId()}@@`;
    this.drakesService.registerDroppable(this);
  }

  ngAfterViewInit(): void {
    this.over.subscribe(() => {
      this.renderer.addClass(this.container, 'gu-over');
    });
    this.out.subscribe(() => {
      this.renderer.removeClass(this.container, 'gu-over');
    });
  }

  ngOnDestroy(): void {
    this.drakesService.removeDroppable(this);
  }
}
