import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as CustomValidators from '../../../../shared/class/custom-validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceRequestItemFileInterface, PriceRequestItemInterface, PriceRequestItemStatusEnum } from '../../../../core/models/price-request-item.model';
import { Subscription, debounceTime, distinctUntilChanged, filter, map, retry, skip, startWith, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../ui-elements/toast/toast.service';
import { PriceRequestItemsService } from '../../services/price-request-items.service';

@Component({
  selector: 'app-custom-price-request-item-form-modal',
  templateUrl: './custom-price-request-item-form-modal.component.html',
  styleUrls: ['./custom-price-request-item-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomPriceRequestItemFormModalComponent implements OnInit, OnDestroy {
  @Input() priceRequestId: number;
  @Input() priceRequestItemId: number;
  @Output() saved = new EventEmitter<PriceRequestItemInterface>();
  @Output() discarded = new EventEmitter<void>();
  
  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }
  
  private subscription = new Subscription();
  form: FormGroup = null;
  isUploadingFiles = false;
  isSubmitting = false;
  isDraft = false;

  constructor(
    protected priceRequestItemsService: PriceRequestItemsService,
    protected activeModal: NgbActiveModal,
    protected translate: TranslateService,
    protected toastService: ToastService,  
  ) { }

  ngOnInit(): void {
    if (!this.form) {
      this.constructForm();
    }

    this.subscription.add(
      this.form.valueChanges.subscribe((formValue: PriceRequestItemInterface) => {
        this.isUploadingFiles = !!formValue.customMadePriceRequestItemFiles.find(({ inProgress }) => inProgress);
      })
    );

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          debounceTime(2000),
          startWith(this.form.getRawValue()),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skip(1),
          filter(() => {
            // autosaves only if all touched fields are valid and item is not being saved
            return (
              Object.values(this.form.controls).every((control) => !control.touched || (control.touched && control.valid)) &&
              !this.isSubmitting
            );
          }),
          switchMap((formValue) => {
            const itemCompleted = this.form.valid;
            return this.priceRequestItemsService
              .update(this.priceRequestId, this.priceRequestItemId, formValue, this.form.valid)
              .pipe(map(() => itemCompleted));
          }),
          retry()
        )
        .subscribe({
          next: (itemCompleted) => {
            let suffix = 'SUCCESSFULLY_SAVED_DRAFT';

            if (itemCompleted) {
              this.isDraft = false;
              suffix = 'CUSTOM_MADE_ITEM_SAVED';
            }

            this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
              this.toastService.success(translation);
            });
          },
          error: () => {
            const suffix = this.isDraft ? 'FAILED_TO_SAVE_DRAFT' : 'FAILED_TO_SAVE_ITEM';
            this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
              this.toastService.danger(translation);
            });
          },
        })
    );
  }

  constructForm(fields?: PriceRequestItemInterface): void {
    this.isDraft = fields?.status === PriceRequestItemStatusEnum.DRAFT;

    this.form = new FormGroup({
      title: new FormControl<string>(fields?.title ?? '', Validators.required),
      quantity: new FormControl<number>(fields?.quantity, [Validators.required, Validators.min(1), CustomValidators.numbers]),
      description: new FormControl<string>(fields?.description ?? '', Validators.required),
      dimensions: new FormControl<string>(fields?.dimensions ?? '', Validators.maxLength(255)),
      finishes: new FormControl<String>(fields?.finishes, [Validators.required, CustomValidators.notEmpty('finishes')]),
      customMadePriceRequestItemFiles: new FormControl<PriceRequestItemFileInterface[]>(fields?.customMadePriceRequestItemFiles ?? []),
    });
  }

  onSubmit(): void {
    this.isSubmitting = true;

    this.subscription.add(
      this.priceRequestItemsService.update(
        this.priceRequestId, 
        this.priceRequestItemId, 
        this.form.getRawValue(), 
        true
      ).subscribe({
        next: (response) => {
          this.translate.get('INQUIRIES.PRICE_REQUESTS.NEW_ITEM.CUSTOM_MADE_ITEM_SAVED').subscribe((translation: string) => {
            this.toastService.success(translation);
          });
          
          this.saved.emit(response);
          this.activeModal.dismiss();
        },
        error: (error) => {
          this.isSubmitting = false;
        }
      })
    );
  }

  onClose(): void {
    if(this.isSubmitting || this.isUploadingFiles) {
      return;
    }

    if(!this.isDraft) {
      this.saved.emit(this.form.value);
      this.activeModal.dismiss();
      return;
    }

    this.onDiscard();
  }

  onDiscard(): void {
    if(this.isSubmitting || this.isUploadingFiles) {
      return;
    }

    const suffix = this.isDraft ? 'DRAFT_DISCARDED' : 'DISCARDED';

    this.subscription.add(
      this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
        this.priceRequestItemsService.delete(this.priceRequestId, this.priceRequestItemId).subscribe(() => {
          this.toastService.success(translation);
          this.discarded.emit();
          this.activeModal.dismiss();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
