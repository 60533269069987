import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderArticleInterface, OrderArticleType } from '../../../../../../core/models/order-article.model';
import { LanguageStoreHandlerService } from '../../../../../../core/services/language/language-store-handler.service';
import { NoResultsFoundComponent } from '../../../../../../shared/components/no-results-found/no-results-found.component';
import { FieldType } from '../../../../../../shared/directives/hide-by-field-type/field-types.enum';
import { HideByFieldTypeDirective } from '../../../../../../shared/directives/hide-by-field-type/hide-by-field-type.directive';
import { SharedModule } from '../../../../../../shared/shared.module';
import { VisibilityByVariablesComponent } from '../../../../../../visibility-by-variables/visibility-by-variables.component';
import {
  OrderArticlesListColumns,
  OrderArticlesListRowInterface,
} from '../../../../order-articles-list.interface';
import { DiscountPipe } from '../../../pipes/discount.pipe';

@Component({
    selector: 'app-order-articles-list-row-details-list',
    templateUrl: './row-details-list.component.html',
    styleUrls: ['./row-details-list.component.scss'],
    imports: [SharedModule, VisibilityByVariablesComponent, HideByFieldTypeDirective, NoResultsFoundComponent, DiscountPipe]
})
export class RowDetailsListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() columns: string[];
  @Input() columnConfig: OrderArticlesListColumns;
  @Input() row: OrderArticlesListRowInterface;

  private subscription: Subscription = new Subscription();

  rowsGroups = [];
  fieldTypes = FieldType;
  orderArticleTypes = OrderArticleType;

  uiLanguage = 'en';

  constructor(private languageStore: LanguageStoreHandlerService) {}

  ngOnInit() {
    this.subscription.add(
      this.languageStore.getCurrentAsObservable().subscribe(language => {
        this.uiLanguage = language.short;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.row.additionalParts.length) {
      const flattenAdditionalParts = this.flattenAdditionalParts(this.row.additionalParts);
      const grouped = flattenAdditionalParts.reduce(
        (carry: OrderArticleInterface[], row: OrderArticleInterface) => {
          const foundIndex = carry.findIndex(child => child.fullCode === row.fullCode);

          if (foundIndex > -1) {
            const found = carry[foundIndex];
            const quantity = found.quantity + row.quantity;
            const totalPrice = found.totalPrice + row.totalPrice;
            carry.splice(foundIndex, 1, {
              ...found,
              quantity,
              totalPrice,
            });
          } else {
            carry.push(row);
          }

          return carry;
        },
        []
      );

      this.rowsGroups = [
        {
          rows: grouped.sort((prev, next) => {
            return prev.position - next.position;
          }),
        },
      ];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isDimensionsVisible() {
    return [this.fieldTypes.DIMENSIONS, this.fieldTypes.WEIGHT, this.fieldTypes.VOLUME].some(item => this.columns.includes(item));
  }

  isImgVisible() {
    return this.columns.includes(this.fieldTypes.IMG);
  }

  isDiscountVisible() {
    return this.columns.includes(this.fieldTypes.DISCOUNT);
  }

  getTranslatedDescription(item: OrderArticleInterface, locale: string) {
    if (item.translations.length) {
      const current = item.translations.filter(i => i.locale === locale);
      return current[0]?.content || item.translations[0].content;
    }

    return item.longText;
  }

  private flattenAdditionalParts(array: OrderArticleInterface[]): OrderArticleInterface[] {
    return array.reduce((acc, e) => {
      return acc.concat(e);
    }, []);
  }
}
