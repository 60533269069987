import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { noWhitespace } from '../../../shared/class/custom-validators';
import { MessagesInterface } from '../../../core/models/messages.model';
import { DomSanitizer } from '@angular/platform-browser';
import { FileDropInvalidReasonEnum, FileSizeEnum } from '../../../api.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ChatHelperService } from '../../../shared/services/chat-helper/chat-helper.service';
import { FileValidationErrorsType } from '../../../shared/services/error/error.service';

export enum MessageFieldThemeEnum {
  DEFAULT = 'default',
  COMMUNICATION_TAB = 'communication-tab',
}

export const DEFAULT_CHAT_ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/x-pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/dwg',
  'application/x-dwg',
  'image/vnd.dwg',
  '.dwg',
  '.jpg',
  '.eps',
  '.ods',
  '.numbers',
  '.pptx',
  '.ppt',
  '.cdr',
];

@Component({
  selector: 'app-message-field',
  templateUrl: './message-field.component.html',
  styleUrls: ['./message-field.component.scss'],
})
export class MessageFieldComponent implements OnInit, OnDestroy {
  @Input() acceptedFileTypes = DEFAULT_CHAT_ACCEPTED_FILE_TYPES;
  @Input() multiple = true;
  @Input() maxFileSize = FileSizeEnum.SIZE_1GB;
  @Input() theme = MessageFieldThemeEnum.DEFAULT;
  @Output() submit = new EventEmitter<Partial<MessagesInterface>>();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('filesList') filesList: ElementRef;

  @HostListener('document:dragover', ['$event'])
  onDocumentDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDraggingSubject.next(true);
  }

  @HostListener('document:drop', ['$event'])
  @HostListener('document:dragleave', ['$event'])
  onDocumentDropOrDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDraggingSubject.next(!!event.relatedTarget);
  }

  @HostListener('drop', ['$event'])
  onModalDrop(event: DragEvent) {
    event.preventDefault();
    this.onDrop(event);
  }

  themeEnum = MessageFieldThemeEnum;

  fileDropErrorCode: FileDropInvalidReasonEnum;
  form: ReturnType<typeof this.constructForm>;
  isDraggingSubject = new Subject<boolean>();
  isDragging = false;
  isSending = false;
  validationErrors: FileValidationErrorsType;
  subscription = new Subscription();
  wasSent = false;

  constructor(
    private sanitizer:DomSanitizer,
    private chatHelperService: ChatHelperService,
  ) { }

  ngOnInit() {
    this.form = this.constructForm();

    this.subscription.add(
      this.isDraggingSubject
        .pipe(debounceTime(1)) // prevents flickering
        .subscribe(isDragging => {
          this.isDragging = isDragging;
        })
    );

    this.subscription.add(
      this.chatHelperService.chatState.subscribe(chatState => {
        const { isSending, validationErrors } = chatState;

        this.isSending = isSending;
        this.validationErrors = validationErrors;

        if (isSending) {
          this.form.controls.text.disable();
          this.form.controls.files.disable();
          this.wasSent = true;
        } else {
          this.form.controls.text.enable();
          this.form.controls.files.enable();
          this.putInvalidFilesAtTheStart();
        }

        if (validationErrors?.length) {
          this.wasSent = false;
        }

        if (this.wasSent && !isSending && !validationErrors?.length) {
          this.form.setValue({ text: '', files: [] });
          this.wasSent = false;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructForm() {
    return new FormGroup({
      text: new FormControl('', [ noWhitespace ]),
      files: new FormControl<File[]>([])
    }, {
      validators: (formGroup: FormGroup) => {
        const { text, files } = formGroup.controls;
        const isValid = text.value.length || files.value.length;

        return isValid ? null : { neitherFieldFilled: true };
      }
    });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSend();
    }
  }

  onSend() {
    if (this.form.valid) {
      this.submit.emit(this.form.value);
      this.fileInput.nativeElement.value = null;
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDraggingSubject.next(false);
    const files = Array.from(event.dataTransfer.files);
    this.patchFiles(files);
  }

  onFileChange(event: Event) {
    const files = Array.from((event.target as HTMLInputElement).files);
    this.patchFiles(files);
  }

  patchFiles(files: File[]) {
    if(!this.multiple && (files.length > 1 || this.form.controls.files.value.length)) {
      return this.displayDropErrorCode(FileDropInvalidReasonEnum.GLOBAL_FILE_MAX_SIZE_ERROR);
    }

    if(!this.acceptedFileTypes.includes('*')) {
      const mimeTypes = this.acceptedFileTypes.filter(type => !type.startsWith('.'));
      const fileExtensions = this.acceptedFileTypes.filter(type => type.startsWith('.'));

      const hasInvalidFiles = files.some(file => {
        const isInvalidMimeType = !mimeTypes.includes(file.type);
        const isInvalidExtension = !fileExtensions.some(extension => file.name.toLocaleLowerCase().endsWith(extension));

        return isInvalidMimeType && isInvalidExtension;
      });

      if (hasInvalidFiles) {
        return this.displayDropErrorCode(FileDropInvalidReasonEnum.GLOBAL_FILE_MIME_TYPE_ERROR);
      }
    }

    const hasInvalidFileSize = files.some(file => file.size > this.maxFileSize);

    if (hasInvalidFileSize) {
      return this.displayDropErrorCode(FileDropInvalidReasonEnum.GLOBAL_FILE_MAX_SIZE_ERROR);
    }

    const selectedFiles = this.form.controls.files.value;
    this.form.patchValue({ files: [...files, ...selectedFiles] });
    this.validationErrors = [
      ...Array(files.length).fill(null),
      ...(this.validationErrors ?? []),
    ];
  }

  clearDropErrorCode() {
    this.fileDropErrorCode = null;
  }

  displayDropErrorCode(errorCode: FileDropInvalidReasonEnum) {
    this.fileDropErrorCode = errorCode;

    setTimeout(() => {
      this.clearDropErrorCode();
    }, 3000);
  }

  get accept() {
    return this.acceptedFileTypes?.join(',');
  }

  isImage(file: File) {
    return file.type.startsWith('image');
  }

  generateImagePreview(file: File) {
    // if bypass is not used, then browser adds 'unsafe:' prefix to the url
    // which causes the image to not be displayed
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  removeFileAtIndex(index: number) {
    const files = this.form.controls.files.value;
    files.splice(index, 1);

    this.form.patchValue({ files });
    this.fileInput.nativeElement.value = null;

    this.validationErrors.splice(index, 1);
    this.chatHelperService.setValidationErrors(this.validationErrors);
  }

  putInvalidFilesAtTheStart() {
    if(!this.validationErrors) {
      return;
    }

    const errorIndexes = this.validationErrors.map(Boolean);
    const files = this.form.controls.files.value;

    const invalidFiles = files.filter((file, i) => errorIndexes[i]);
    const validFiles = files.filter((file, i) => !errorIndexes[i]);
    const validationErrors = this.validationErrors.filter((error, i) => errorIndexes[i]);

    this.validationErrors = [
      ...validationErrors,
      ...Array(validFiles.length).fill(null),
    ];
    this.form.patchValue({ files: [...invalidFiles, ...validFiles] });
  }

  get availablePreviewSpots() {
    const filesListWidth = this.filesList.nativeElement.offsetWidth;

    const filePreviewWidth = 80;
    const gap = 24;

    const availableSpots = Math.floor((filesListWidth - gap) / (filePreviewWidth + gap));
    const selectFilesCount = this.form.controls.files.value.length;

    return selectFilesCount <= availableSpots ? availableSpots : availableSpots - 1;
  }

  get visibleFiles() {
    return this.form.controls.files.value.slice(0, this.availablePreviewSpots);
  }
}
