import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { UserGuard } from './core/guards/user.guard';
import { HomeComponent } from './core/home/home.component';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { RouteTypeEnums, OrderRoutePath, GeneralRoutePaths, InquiriesRoutePath, RoutePathParams } from './core/enums/route-types.enum';
import { LogoutComponent } from './core/logout/logout.component';
import { AdminGuard } from './core/guards/admin.guard';
import { ImpersonateComponent } from './core/impersonate/impersonate.component';
import { RegularUserGuard } from './core/guards/regular-user.guard';
import { SomethingWentWrongComponent } from './errors/something-went-wrong/something-went-wrong.component';
import { UnsupportedBrowserComponent } from './errors/unsupported-browser/unsupported-browser.component';
import { ContainerType } from './core/enums/container-type.enum';
import { RoleGuard } from './core/guards/role.guard';
import { UserRole } from './core/enums/user-role.enum';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NotAdminGuard } from './core/guards/not-admin.guard';
import { ImpersonateResolver } from './core/impersonate/resolver/impersonate.resolver';
import { PricelistDownloadComponent } from './shared/components/pricelist-download/pricelist-download.component';
import { environment } from '../environments/environment';
import { ResizeNotifier } from './resize-observer/resize-notifier.service';
import { ValidLanguageGuard } from './core/guards/valid-language.guard';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard] },
  {
    path: GeneralRoutePaths.SIGN_IN,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [UserGuard],
    data: {
      containerType: ContainerType.FULL_WIDTH,
    },
  },
  {
    path: GeneralRoutePaths.LOGOUT,
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: GeneralRoutePaths.TERMS,
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: `${GeneralRoutePaths.IMPERSONATE}/${RoutePathParams.EMAIL}`,
    canActivate: [AdminGuard],
    resolve: { email: ImpersonateResolver },
    component: ImpersonateComponent,
  },
  {
    path: GeneralRoutePaths.CATALOGUE,
    data: { id: RouteTypeEnums.CATALOGUE },
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule),
  },
  {
    path: GeneralRoutePaths.CONFIGURATION,
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./configurator-modal/configurator-modal.module').then(m => m.ConfiguratorModalModule),
  },
  {
    path: OrderRoutePath.ROOT,
    data: {
      id: RouteTypeEnums.ORDERS,
      allowedRoles: [UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU],
    },
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: GeneralRoutePaths.USER,
    data: { id: RouteTypeEnums.USER },
    canActivate: [RegularUserGuard, NotAdminGuard, AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: GeneralRoutePaths.NEWS,
    data: {
      id: RouteTypeEnums.NEWS,
      containerType: ContainerType.FULL_WIDTH,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
  },
  {
    path: GeneralRoutePaths.INFO,
    data: {
      id: RouteTypeEnums.INFO,
      uri: 'info',
      containerType: ContainerType.FULL_WIDTH,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
  },
  {
    path: GeneralRoutePaths.ESTIMATES,
    data: {
      id: RouteTypeEnums.ESTIMATES,
      containerType: ContainerType.FULL_WIDTH
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./estimates/estimates.module').then(m => m.EstimatesModule),
  },
  {
    path: GeneralRoutePaths.DOWNLOAD_PRICELIST,
    canActivate: [AuthGuard],
    component: PricelistDownloadComponent,
  },
  {
    path: GeneralRoutePaths.FEATURE_FLAGS,
    data: {
      allowedRoles: `${ environment.production ? [UserRole.ROLE_ADMIN] : [UserRole.ROLE_ADMIN, UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU]}`,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./feature-flags/feature-flags.module').then(m => m.FeatureFlagsModule),
  },
  {
    path: GeneralRoutePaths.UI_INVENTORY,
    data: {
      allowedRoles: `${ environment.production ? [UserRole.ROLE_ADMIN] : [UserRole.ROLE_ADMIN, UserRole.ROLE_PM, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU]}`,
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./component-inventory/component-inventory.module').then(m => m.ComponentInventoryModule),
  },
  {
    path: InquiriesRoutePath.ROOT,
    data: {
      id: RouteTypeEnums.INQUIRIES,
      uri: 'inquiries',
    },
    canActivate: [RegularUserGuard, AuthGuard, RoleGuard],
    loadChildren: () => import('./inquiries/inquiries.module').then(m => m.InquiriesModule),
  },
  { path: GeneralRoutePaths.PAGE_NOT_FOUND, component: PageNotFoundComponent },
  { path: GeneralRoutePaths.SOMETHING_WENT_WRONG, component: SomethingWentWrongComponent },
  { path: GeneralRoutePaths.UNSUPPORTED_BROWSER, component: UnsupportedBrowserComponent },
  { path: GeneralRoutePaths.MAINTENANCE, component: MaintenanceComponent },
  {
    path: RoutePathParams.LANGUAGE,
    canActivate: [ValidLanguageGuard],
    children: [
      {
        path: GeneralRoutePaths.SIGN_IN,
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [UserGuard],
        data: {
          containerType: ContainerType.FULL_WIDTH,
        },
      },
      {
        path: GeneralRoutePaths.TERMS,
        loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
      },
      { path: GeneralRoutePaths.PAGE_NOT_FOUND, component: PageNotFoundComponent },
      { path: GeneralRoutePaths.SOMETHING_WENT_WRONG, component: SomethingWentWrongComponent },
      { path: GeneralRoutePaths.UNSUPPORTED_BROWSER, component: UnsupportedBrowserComponent },
    ]
  },
  { path: '**', redirectTo: `/${GeneralRoutePaths.PAGE_NOT_FOUND}` },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: () => {
        const topOffset = ResizeNotifier.getCurrentValue().navbarBorderBox?.height || 0;
        return [0, topOffset + 16];
      }
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
