interface ExcludedEndpointInterface {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  pattern: RegExp;
}

export const EXCLUDED_ENDPOINTS: ExcludedEndpointInterface[] = [
  {
    method: 'POST',
    pattern: /\/custom-made-price-requests\/\d+\/share\/validate-email/,
  },
  {
    method: 'POST',
    pattern: /\/claims\/\d+\/share\/validate-email/,
  },
];
