import { Injectable } from '@angular/core';
import { UserService } from '../../../core/services/user/user.service';
import { UserInterface } from '../../../core/models/user.model';
import { RouteTypeEnums } from '../../../core/enums/route-types.enum';
import { switchMap } from 'rxjs/operators';
import { TreeService } from '../../../core/tree/tree.service';
import { NavbarElements } from '../../../core/tree/navbar-elements';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderInterface } from '../../../core/models/order.model';
import { ExtraListElementInterface } from '../../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { PriceRequestInterface } from '../../../core/models/price-request.model';

@Injectable({
  providedIn: 'root',
})
export class AddItemHelperService {
  private lastOrder$ = new BehaviorSubject<OrderInterface | null>(null);
  private lastGroup$ = new BehaviorSubject<ExtraListElementInterface | null>(null);
  private lastPriceRequest$ = new BehaviorSubject<PriceRequestInterface | null>(null);

  constructor(private userService: UserService, private treeService: TreeService) {}

  lastOrderAsObservable(): Observable<OrderInterface | null> {
    return this.lastOrder$.asObservable();
  }

  lastGroupAsObservable(): Observable<ExtraListElementInterface | null> {
    return this.lastGroup$.asObservable();
  }

  lastPriceRequestAsObservable(): Observable<PriceRequestInterface | null> {
    return this.lastPriceRequest$.asObservable();
  }

  setLastOrder(order?: OrderInterface) {
    this.lastOrder$.next(order);
  }

  setLastGroup(group: ExtraListElementInterface) {
    this.lastGroup$.next(group);
  }

  setLastPriceRequest(priceRequest: PriceRequestInterface) {
    this.lastPriceRequest$.next(priceRequest);
  }

  itemAddToOrderClicked(idOfOrder: number, idOfGroup?: number) {
    this.userService
      .fromStorage()
      .pipe(
        switchMap((user) =>
          this.userService.update(user.id, {
            lastUpdatedOrder: idOfOrder,
            lastUpdatedPageBreak: idOfGroup,
            lastUpdatedCustomMadePriceRequest: null,
          })
        )
      )
      .subscribe((updatedUser: UserInterface) => {
        this.lastPriceRequest$.next(null);
        this.lastOrder$.next(updatedUser.lastUpdatedOrder);
        this.lastGroup$.next(updatedUser.lastUpdatedPageBreak);
  
        this.treeService.open(
          NavbarElements.find(({ id }) => id === RouteTypeEnums.CATALOGUE),
          null,
          true
        );
      });
  }

  itemBasedOnStandardAddToPriceRequestClicked( priceRequest: PriceRequestInterface) {
    this.userService
      .fromStorage()
      .pipe(
        switchMap((user) =>
          this.userService.update(user.id, {
            lastUpdatedOrder: null,
            lastUpdatedPageBreak: null,
            lastUpdatedCustomMadePriceRequest: priceRequest.id,
          })
        )
      )
      .subscribe(() => {
        this.lastOrder$.next(null);
        this.lastGroup$.next(null);
        this.lastPriceRequest$.next(priceRequest);

        this.treeService.open(
          NavbarElements.find(({ id }) => id === RouteTypeEnums.CATALOGUE),
          null,
          true
        );
      });
  }
}
