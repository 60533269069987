import { Injectable } from '@angular/core';
import {
  areItemsInSameGroup,
  OrderArticlesListRow,
  pageBreakKey,
  RowTypes,
} from '../../../orders/order-articles-list/order-articles-list.interface';
import {
  ExtraListElementInterface,
  ExtraListElementRowInterface,
} from '../../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';
import { SaleMode } from '../../../shared/components/list-mode-switch/sale-mode.types';
import { OrderArticleType } from '../../models/order-article.model';

export const GROUPS_DEPTH_LIMIT = 2; // group levels are 0 based
export const OTHER_ITEMS_DEPTH_LIMIT = 3; // other items has +1 of groupLevel

export const GROUP_LIKE_ROW_TYPES = [RowTypes.GROUP, RowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP];

export function getSortedSelectedItemsToGroup(items) {
  const groups = items.filter(item => GROUP_LIKE_ROW_TYPES.includes(item.rowType));
  let rows = items.filter(item => !GROUP_LIKE_ROW_TYPES.includes(item.rowType));
  if (groups && groups.length) {
    rows = rows.filter(item => {
      if (!item.pageBreak) {
        return true;
      }
      const found = groups.find(group => group.id === item.pageBreak.id);
      return found ? item.pageBreak.id !== found.id : true;
    });
  }
  const filteredItems = [...groups, ...rows];
  return areItemsInSameGroup(filteredItems) ? filteredItems : [];
}

@Injectable({
  providedIn: 'root',
})
export class OrderItemsGroupService {
  private saleMode: SaleMode = SaleMode.ORDER;

  constructor(private listModeSwitchService: ListModeSwitchService) {
    this.listModeSwitchService.saleModeAsObservable().subscribe(saleMode => {
      this.saleMode = +saleMode;
    });
  }

  /**
   * @param {ExtraListElementInterface[]} groups
   * @returns {ExtraListElementInterface[]}
   */
  groupExtraItemsByParent(groups: ExtraListElementInterface[]): ExtraListElementInterface[] {
    groups.map(group => {
      group.children = groups.filter(filterGroup => filterGroup.parent && group.id === filterGroup.parent.id);
      group.rowType = RowTypes.GROUP;
    });
    return groups.filter(group => !group.parent); // filter to removeDroppable grouped groups
  }

  /**
   * Defines every group children depth level
   */
  setLevels(rows, updateOnlyLevel = false) {
    return this.map(rows, 0, updateOnlyLevel);
  }

  private getRecalculatedCountAndPrice(item) {
    const productCount = this.getProductsCount(item.children || []);
    const childrenTotalPrice = item.children.reduce((accumulator, current) => {
      if (current.type === OrderArticleType.OTHER_SUPPLIER_ITEM && this.saleMode !== SaleMode.SALE) {
        return accumulator;
      }

      return accumulator + (+current.formattedTotalConfigurationPrice || current.childrenTotalPrice || 0);
    }, 0);

    return { productCount, childrenTotalPrice };
  }

  private map(arr, initLevel, updateOnlyLevel) {
    const childrenInitLevel = initLevel + 1;
    return arr.map(item => {
      item.groupLevel = initLevel;
      item.children = this.innerMap(item.children || [], childrenInitLevel, updateOnlyLevel);
      if (!updateOnlyLevel) {
        const { productCount, childrenTotalPrice } = this.getRecalculatedCountAndPrice(item);
        item.productCount = productCount;
        item.childrenTotalPrice = childrenTotalPrice;
      }
      return item;
    });
  }

  private innerMap(arr = [], i, updateOnlyLevel) {
    const newArr = arr.map(item => {
      item.groupLevel = i;
      return item;
    });
    i++;
    newArr.map(item => {
      if (item.children !== undefined) {
        item.children = this.innerMap(item.children, i, updateOnlyLevel);
        if (!updateOnlyLevel) {
          const { productCount, childrenTotalPrice } = this.getRecalculatedCountAndPrice(item);
          item.productCount = productCount;
          item.childrenTotalPrice = childrenTotalPrice;
        }
      }
      return item;
    });
    return newArr;
  }

  getRowsCount(rows: OrderArticlesListRow[]): number {
    if (!rows.length) {
      return 0;
    }

    return rows.reduce((acc, current) => {
      acc++;
      if (GROUP_LIKE_ROW_TYPES.includes(current.rowType)) {
        acc += this.getRowsCount(current.children);
      }

      return acc;
    }, 0);
  }

  /**
   * Count all products in order
   */
  getProductsCount(rows: OrderArticlesListRow[] = []): number {
    return rows.reduce((acc, current) => {
      if (current.rowType === RowTypes.GROUP || current.rowType === RowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP) {
        acc = acc + this.getProductsCount(current.children);
      }
      if (current.rowType === RowTypes.PRODUCT) {
        acc++;
      }
      return acc;
    }, 0);
  }

  regroupRows(rows: OrderArticlesListRow[], group = null) {
    return rows.map(row => {
      row[pageBreakKey(row)] = group;

      if (RowTypes.GROUP === row.rowType) {
        this.regroupRows(row.children ?? [], row);
      }

      return row;
    });
  }
}
