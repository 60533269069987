<div
  [class]="'ngx-dnd-container ' + levelClass()"
  appDroppable
  [dropZone]="dropZone"
  [groupHead]="groupHead"
  [model]="model"
  [copy]="copy"
  [isDisabled]="dropzoneDisabled"
  [ngClass]="{
    'gu-empty': !isMultiple() && isOpen && visibleEmptyGroup,
    'nested': isMultiple() && model[0]?.groupLevel > 0
  }"
  [removeOnSpill]="removeOnSpill"
>
  <div *ngIf="!isMultiple() && isOpen && visibleEmptyGroup" class="empty-group-placeholder">
    {{ 'ORDER_ARTICLES.EMPTY_GROUP' | translate }}
  </div>
  <ng-container *ngIf="model">
    <ng-container *ngFor="let item of modelAsArray; let last = last; let first = first; trackBy: trackByFn">
      <draggable-item
        [visibleEmptyGroup]="visibleEmptyGroup"
        (drop)="onDrop($event)"
        (drag)="onDrag($event)"
        appDraggable
        [model]="item"
        [dropZone]="dropZone"
        [dropZones]="dropZones"
        [copy]="copy"
        [moves]="moves"
        [removeOnSpill]="removeOnSpill"
        [droppableItemClass]="droppableItemClass"
        [ngClass]="{
          'border-bottom': last && isArray() && model[0]?.groupLevel == 0
        }"
      ></draggable-item>
    </ng-container>
     <div *ngIf="isMultiple() && levelClass() === 'level-zero'" class="base-dropzone-extender gu-empty"></div>
  </ng-container>
</div>
