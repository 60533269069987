import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FormHelper } from '../../../../core/util/form-helper/form.helper';
import { validDateFormat } from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { DateInputComponent } from '../../../../ui-elements/date-input/date-input.component';
import { TextFieldComponent } from '../../../../ui-elements/text-field/text-field/text-field.component';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

const MAX_PERCENTAGE_VALUE = 100;

@Component({
    selector: 'app-document-template-payment-conditions',
    templateUrl: './document-template-payment-conditions.component.html',
    imports: [SharedModule, TextFieldComponent, DateInputComponent]
})
export class DocumentTemplatePaymentConditionsComponent implements OnInit, OnDestroy {
  @Input() currency: string;
  @Input() exchangeRate = 1;
  private subscriptions: Subscription = new Subscription();
  totalValues: { percent; price } = { percent: 0, price: 0 };
  orderPayments: DocumentTemplate.OrderPayment[] = [];
  order: DocumentTemplate.Order;
  initializationRequired = true;

  textFieldThemeTypes = TextFieldThemeTypes;
  form: UntypedFormGroup;
  maxPercentageValue = MAX_PERCENTAGE_VALUE;

  constructor(private documentTemplateService: DocumentTemplateService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.documentTemplateService.getDataAsObservable().subscribe(({ order, orderPayments }) => {
        this.order = order;
        this.orderPayments = orderPayments;
        this.totalValues.percent = 0;
        this.totalValues.price = 0;
        if (this.initializationRequired) {
          this.form = new UntypedFormGroup({ payments: new UntypedFormArray([]) });
          this.orderPayments.forEach(({ id, title, paymentDueDateTimestamp, percentage, price }) => {
            this.totalValues.percent += +percentage;
            this.totalValues.price += +price;
            const row = this.createRow();
            this.patchRow(row, id, title, percentage, price, paymentDueDateTimestamp);
            (this.form.get('payments') as UntypedFormArray).push(row);
          });
          this.initializationRequired = false;
        } else {
          const rowControls = (this.form.get('payments') as UntypedFormArray).controls;
          this.orderPayments.forEach(({ id, title, paymentDueDateTimestamp, percentage, price }, index) => {
            this.totalValues.percent += +percentage;
            this.totalValues.price += +price;
            if (rowControls[index]) {
              const rowControl = rowControls[index];
              this.patchRow(rowControl, id, title, percentage, price, paymentDueDateTimestamp);
            } else {
              const row = this.createRow();
              this.patchRow(row, id, title, percentage, price, paymentDueDateTimestamp);
              (this.form.get('payments') as UntypedFormArray).push(row);
            }
          });
        }
      })
    );
  }

  patchRow(
    row: AbstractControl,
    id: number,
    title: string,
    percentage: number | string,
    price: number | string,
    paymentDueDateTimestamp?: string | number
  ) {
    row.patchValue(
      {
        orderPaymentId: id,
        orderPaymentTitle: title,
        orderPaymentDueDate: paymentDueDateTimestamp ? moment(paymentDueDateTimestamp).format('YYYY-MM-DD') : '',
        orderPaymentPercentage: +percentage || '',
        orderPaymentPrice: +price || '',
      },
      { emitEvent: false }
    );
  }

  onRemove(index: number) {
    const formArray = this.form.get('payments') as UntypedFormArray;
    const formGroup = formArray.at(index);
    formArray.removeAt(index);
    const paymentId = formGroup.get('orderPaymentId').value;
    if (paymentId) {
      this.orderPayments.splice(index, 1);
      this.documentTemplateService.updateData({ orderPayments: this.orderPayments });
    }
  }

  onAddRow(row: UntypedFormGroup = this.createRow()) {
    (this.form.get('payments') as UntypedFormArray).push(row);
    this.updateOrderPayments();
  }

  private createRow(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      orderPaymentId: new UntypedFormControl(),
      orderPaymentTitle: new UntypedFormControl('', [Validators.maxLength(45)]),
      orderPaymentDueDate: new UntypedFormControl('', validDateFormat),
      orderPaymentPercentage: new UntypedFormControl('', [Validators.min(0), Validators.max(100)]),
      orderPaymentPrice: new UntypedFormControl(''),
    });

    this.subscriptions.add(
      formGroup.valueChanges
        .pipe(
          filter(() => formGroup.valid),
          distinctUntilChanged(),
          debounceTime(500)
        )
        .subscribe(values => {
          this.updateOrderPayments(values);
        })
    );
    return formGroup;
  }

  private updateOrderPayments(values?) {
    if (!values) {
      let percentage = 0;
      if (this.orderPayments.length > 0 && this.totalValues.percent < MAX_PERCENTAGE_VALUE) {
        percentage = MAX_PERCENTAGE_VALUE - this.totalValues.percent;
      }
      this.documentTemplateService.updateData({ orderPayments: [...this.orderPayments, { percentage }] });
      return;
    }
    const { orderPaymentId, orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage } = values;
    if (orderPaymentId !== null) {
      const found = this.orderPayments.find(payment => payment.id === orderPaymentId);
      if (found) {
        this.appendChangedValues({ orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage }, found);
        this.documentTemplateService.updateData({ orderPayments: this.orderPayments });
      }
    } else {
      const orderPayment: DocumentTemplate.OrderPayment = { percentage: orderPaymentPercentage };
      this.orderPayments = [...this.orderPayments, orderPayment];
      this.appendChangedValues({ orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage }, orderPayment);
      this.documentTemplateService.updateData({ orderPayments: [...this.orderPayments, orderPayment] });
    }
  }

  private appendChangedValues(
    { orderPaymentTitle, orderPaymentDueDate, orderPaymentPercentage },
    orderPayment: DocumentTemplate.OrderPayment = {}
  ): DocumentTemplate.OrderPayment {
    const body: DocumentTemplate.OrderPayment = orderPayment;
    body.title = orderPaymentTitle;

    if (orderPaymentPercentage !== null) {
      body.percentage = orderPaymentPercentage;
    }

    if (orderPaymentDueDate) {
      body.paymentDueDateTimestamp = moment(orderPaymentDueDate).format();
    }
    return body;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
