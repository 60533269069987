import { FullOrderArticleInterface } from '../../core/models/full-order-article.model';
import { ExtraListElementRowInterface } from './order-articles-list/components/extra-row/extra-items.model';
import {
  OrderArticleInterface,
  OrderArticleMigrationStatusType,
} from '../../core/models/order-article.model';
import { ItemsListColumnInterface } from '../../core/models/items-list-column.model';
import { OrderArticleMaterialInterface } from "../../ui-elements/order-article-material-input/order-article-material-interface";

export type OrderArticlesListRow = OrderArticlesListRowInterface | ExtraListElementRowInterface;

export type OrderArticlesListRowInterface = OrderArticleInterface & {
  children?: OrderArticlesListRow[],
  additionalParts?: OrderArticleInterface[];

  belongsToLockedGroup?: boolean;

  item?: OrderArticlesListRowItemInterface;
  size?: OrderArticlesListRowSizeInterface;

  materials?: any[];
  materialsAndType?: { type: number | string; materials: OrderArticleMaterialInterface[] };

  selected: boolean;
  rowType: RowTypes.PRODUCT;

  originals?: FullOrderArticleInterface;
  parent?: any;

  childrenTotalPrice?: number;
  totalConfigurationPrice?: number;

  migrationStatus?: OrderArticleMigrationStatusType;

  groupLevel?: number;
  order?: number;
  cut: boolean;

  totalVolume?: number;
  totalWeight?: number;
};

export type OrderArticlesListRowItemInterface = Pick<OrderArticleInterface,
  'category' |
  'code' |
  'system' |
  'img' |
  'shortText' |
  'longText' |
  'title' |
  'translations'
>

export type OrderArticlesListRowSizeInterface = Pick<OrderArticleInterface,
  'depth' |
  'details' |
  'volume' |
  'weight' |
  'width' |
  'height' |
  'dimensions'
>

export enum RowTypes {
  PRODUCT,
  EXTRA,
  GROUP,
  LOCKED_PRICE_REQUEST_ITEMS_GROUP
}

export interface OrderArticlesListColumns {
  selectBox: ItemsListColumnInterface;
  item: ItemsListColumnInterface;
  materialsAndType: ItemsListColumnInterface;
  details: ItemsListColumnInterface;
  quantity: ItemsListColumnInterface;
  price: ItemsListColumnInterface;
  discount?: ItemsListColumnInterface;
  saleDiscount?: ItemsListColumnInterface;
  totalPrice: ItemsListColumnInterface;
  actions: ItemsListColumnInterface;
}

export function pageBreakKey(item: OrderArticlesListRow) {
  return item.rowType === RowTypes.PRODUCT ? 'pageBreak' : 'parent';
}

export function areItemsInSameGroup(elements: OrderArticlesListRow[]) {
  const [firstItem] = elements;
  const firstItemPageBreak = firstItem ? firstItem[pageBreakKey(firstItem)] : null;
  return elements.every(item => {
    const pageBreak = item[pageBreakKey(item)];
    const parentInList = pageBreak && !!elements.find(element => element.rowType === RowTypes.GROUP && element.id === pageBreak.id);
    return (pageBreak && firstItemPageBreak && pageBreak.id === firstItemPageBreak.id) || pageBreak === firstItemPageBreak || parentInList;
  });
}

/** Update row cut property if row is in clipboard cut
 * items array. Also check if group is cut and ignore setting to children items cut property
 */
export function deepMapCutRows(row, cutItems, cutGroups) {
  if (cutGroups.find(group => row[pageBreakKey(row)] && group.id === row[pageBreakKey(row)].id)) {
    return row;
  }
  const foundIndex = cutItems.findIndex(item => item.id === row.id);
  row.cut = foundIndex > -1;

  if (row.children && row.children.length) {
    row.children.map(child => deepMapCutRows(child, cutItems, cutGroups));
  }

  return row;
}
