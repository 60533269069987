<div class="card shadow-card border-0 shadow-sm certificate-card d-flex flex-column p-3 overflow-hidden position-relative">
  <ng-container *ngIf="certificate; else skeletonContent">
    <div class="d-flex flex-column flex-shrink-0 mb-2">
      <h4 class="m-0">{{ certificate.title }}</h4>
      <div class="text-gray">{{ certificate.area }}</div>
    </div>
    <div class="flex-grow-1 overflow-hidden">
      <img
        class="w-100 h-100 object-fit-cover"
        [src]="certificate.image.url"
        [alt]="certificate.title"
      />
    </div>
    <div class="actions d-flex flex-column gap-2 overflow-hidden flex-shrink-0 mt-2">
      <a
        class="btn btn-secondary d-flex gap-2 align-items-center justify-content-center w-full text-nowrap no-outline"
        [href]="certificate.file.url"
        target="_blank"
      >
        <span>{{ 'CERTIFICATES.VIEW' | translate }}</span>
        <i class="icon ni-open-in-new"></i>
      </a>
      <a
        class="btn btn-secondary d-flex gap-2 align-items-center justify-content-center w-full text-nowrap no-outline"
        [href]="certificate.file.url"
        download
      >
        <span>{{ 'CERTIFICATES.DOWNLOAD_PDF' | translate }}</span>
        <i class="icon ni-download"></i>
      </a>
    </div>
  </ng-container>
</div>

<ng-template #skeletonContent>
  <div class="w-100 skeleton-filler mb-1" [style.height.px]="26"></div>
  <div class="w-50 skeleton-filler mb-2" [style.height.px]="18"></div>
  <div class="w-100 skeleton-filler flex-grow-1"></div>
</ng-template>
