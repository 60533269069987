import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DroppableDirective } from '../../../draggable/directives/ngx-droppable.directive';
import { DraggingDetectorService } from '../../../draggable/services/dragging-detector/dragging-detector.service';
import { DraggableContentDirective } from '../../directives/draggable-content/draggable-content.directive';
import { Subscription } from 'rxjs';
import { OpenGroupsService } from '../group/open-groups/open-groups.service';
import { OrderArticlesListRow } from '../../../order-articles-list.interface';

let i = 0;

function getNextId() {
  return i++;
}

/**
 * Component that allows nested ngxDroppable and ngxDraggables
 *
 * @export
 */
@Component({
  selector: 'draggable-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @ContentChild(DraggableContentDirective, { read: TemplateRef })
  contentDirective;

  @Input() model: OrderArticlesListRow[] | OrderArticlesListRow;
  @Input() copy = false;
  @Input() removeOnSpill = false;
  @Input() groupHead;
  @Input() droppableItemClass: string | ((o: any) => any);
  @Input() dropzoneDisabled = false;

  private _defaultZones: string[];
  @Input() dropZone = `@@DefaultDropZone-${getNextId()}@@`;
  @Input() dropZones: string[];

  @Input() moves: (model: any, source: any, handle: any, sibling: any) => boolean;

  @Input() visibleEmptyGroup = true;

  @Input() containerTemplate: TemplateRef<any> = null;

  @ContentChild(TemplateRef, { static: true })
  template: TemplateRef<any>;

  @ViewChild(DroppableDirective, { static: true })
  droppable: any;

  @Output() drop: EventEmitter<any> = new EventEmitter<any>();
  @Output() drag: EventEmitter<any> = new EventEmitter<any>();
  @Output() over: EventEmitter<any> = new EventEmitter<any>();
  @Output() out: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  dragging = false;
  isOpen: boolean;

  constructor(private draggingDetectorService: DraggingDetectorService, private openGroupsService: OpenGroupsService) {}

  ngOnInit() {
    this._defaultZones = [this.dropZone];
    this.dropZones = [...(this.dropZones ?? []), ...(this._defaultZones ?? [])];
    this.template = this.containerTemplate ?? this.template;

    this.subscriptions.add(
      this.draggingDetectorService.getStatusAsObservable().subscribe((status) => {
        this.dragging = status;
      })
    );
    if (this.groupHead) {
      this.subscriptions.add(
        this.openGroupsService.getOpenGroupsAsObservable().subscribe(() => {
          this.isOpen = this.openGroupsService.isOpen(this.groupHead);
          this.groupHead.isOpen = this.isOpen;
        })
      );
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.droppable.drag.subscribe((v: any) => this.onDrag(v)));
    this.subscriptions.add(this.droppable.drop.subscribe((v: any) => this.onDrop(v)));
    this.subscriptions.add(this.droppable.over.subscribe((v: any) => this.over.emit(v)));
    this.subscriptions.add(this.droppable.out.subscribe((v: any) => this.out.emit(v)));
    this.subscriptions.add(this.droppable.remove.subscribe((v: any) => this.remove.emit(v)));
    this.subscriptions.add(
      this.droppable.cancel.subscribe((v: any) => {
        this.draggingDetectorService.setStatus(false);
        this.cancel.emit(v);
      })
    );
  }

  onDrop(v) {
    this.draggingDetectorService.setStatus(false);
    this.drop.emit(v);
  }

  onDrag(v) {
    this.draggingDetectorService.setStatus(true);
    this.drag.emit(v);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  trackByFn(index, item) {
    return item ? item.id : index;
  }

  levelClass() {
    return Array.isArray(this.model) && this.model.length && this.model[0]?.groupLevel > 0 ? `level-${this.model[0]?.groupLevel}` : 'level-zero';
  }

  isMultiple() {
    return Array.isArray(this.model) && this.model.length;
  }

  isArray() {
    return Array.isArray(this.model);
  }

  get modelAsArray() {
    return Array.isArray(this.model) ? this.model : [this.model];
  }
}
