import { ALLOWED_MAXIMUM_GROUP_LEVEL } from './../../orders/controls/controls.component';
import { Component, EventEmitter, OnChanges, OnDestroy, Output, ViewChild, OnInit } from '@angular/core';
import { ExtraItemsService } from '../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.service';
import { ExtraListElementsTypes } from '../../orders/order-articles-list/order-articles-list/components/extra-row/extra-row-types.enum';
import {
  CreateExtraListElementInterface,
  ExtraListElementInterface
} from '../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { LoaderService } from '../../ui-elements/loader/loader.service';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { OrderItemsGroupService } from '../../core/services/order-items-group/order-items-group.service';
import { OrderInterface } from '../../core/models/order.model';
import { CreateNewItemEventInterface, NewItemType } from '../new-item/new-item.component';
import { SelectionMenuService } from '../selection-menu.service';
import { OpenGroupsService } from '../../orders/order-articles-list/order-articles-list/components/group/open-groups/open-groups.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-selection-menu-order-group-select-list',
  templateUrl: './order-group-select-list.component.html',
  styleUrls: ['../selection-menu.component.scss'],
  providers: [OrderItemsGroupService]
})
export class OrderGroupSelectListComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  @Output() switchTabBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedOrder: OrderInterface;
  openedOrder: OrderInterface;
  selectedGroup: ExtraListElementInterface;
  openGroups: ExtraListElementInterface[] = []; // - indicates if group should be expanded or not
  activeGroups: ExtraListElementInterface[] = []; // - indicates if add button should be shown when group is open
  groups: ExtraListElementInterface[] = [];
  newItemType = NewItemType;
  maxGroupLevel = ALLOWED_MAXIMUM_GROUP_LEVEL - 1;
  createdGroup: ExtraListElementInterface;
  isNewGroupFormActive = false;
  isNewSubGroupFormActive = false;

  private subscriptions: Subscription = new Subscription();

  constructor(private extraItemsService: ExtraItemsService,
    private orderItemsGroupService: OrderItemsGroupService,
    private loaderService: LoaderService,
    private openGroupService: OpenGroupsService,
    private selectionMenuService: SelectionMenuService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.selectionMenuService.selectedOrderAsObservable().subscribe(order => {
        this.selectedOrder = order;
        this.loadGroups();
      })
    );

    this.subscriptions.add(
      this.selectionMenuService.openedOrderAsObservable().subscribe(order => {
        this.openedOrder = order;
        this.loadGroups();
      })
    );

    this.subscriptions.add(
      this.selectionMenuService.selectedGroupAsObservable().subscribe(selectedGroup => {
        this.selectedGroup = selectedGroup;
        if (!selectedGroup) {
          return;
        }

        // groups might not be loaded yet
        if (this.groups.length > 0) {
          this.openGroupRecursive(selectedGroup);
        }
      })
    );
  }

  ngOnChanges() {
    this.loadGroups();
  }

  private loadGroups() {
    if (!this.openedOrder) {
      return;
    }

    this.loaderService.load(this.extraItemsService.fetch(this.openedOrder.id).noCache(), !this.groups.length ? this.loader : null)
      .subscribe((extraItems: ExtraListElementInterface[]) => {
        if (extraItems && extraItems.length) {
          const groups = extraItems.filter((extraItem) => extraItem.type === ExtraListElementsTypes.GROUP);
          this.groups = this.orderItemsGroupService.setLevels(this.orderItemsGroupService.groupExtraItemsByParent(groups))
            .sort((prev, next) => {
              return prev.position - next.position;
            });

          if (this.selectedGroup) {
            this.openGroupRecursive(this.selectedGroup);
          }

          if (this.createdGroup) {
            this.openGroupRecursive(this.createdGroup);
            this.deactivateAllExceptOne(this.activeGroups, this.createdGroup);
            this.activeGroups.push(this.createdGroup);
          }
        }
      });
  }

  isGroupSelected(group: ExtraListElementInterface) {
    return group?.id === this.selectedGroup?.id;
  }

  onCreateNewGroup(item: CreateNewItemEventInterface, parent: ExtraListElementInterface = null) {
    this.isNewGroupFormActive = false;
    this.isNewSubGroupFormActive = false;
    const extraItem: CreateExtraListElementInterface = {
      type: ExtraListElementsTypes.GROUP,
      title: item.title,
      order: this.openedOrder.id,
      position: 0,
      parent: parent?.id || null
    };

    this.activeGroups = [];

    this.createdGroup = null;
    this.loaderService.load(
      this.extraItemsService.create(extraItem), this.loader).subscribe((group: ExtraListElementInterface) => {
        // try to remember fresh group information
        // it will be used to open up just added group
        this.createdGroup = group;

        this.loadGroups();
      });
  }

  onGoBack() {
    this.switchTabBack.emit(true);
  }

  isSelected(group): boolean {
    return (this.selectedGroup && group) && (this.selectedGroup.id === group.id);
  }

  onToggleSelectGroup(group) {
    this.selectedGroup = this.isSelected(group) ? null : group;
  }

  onSelectOrder() {
    this.selectionMenuService.selectOrder(this.openedOrder);
    this.selectionMenuService.selectGroups(null);
    this.selectionMenuService.close();
  }

  onSelectGroup(group) {
    this.selectionMenuService.selectOrder(this.openedOrder);
    this.selectionMenuService.selectGroups(group);
    this.selectionMenuService.close();
  }

  onToggleGroupOpenState(group: ExtraListElementInterface) {
    this.deactivateAllExceptOne(this.activeGroups, group);

    if (group.isOpen) {
      if (!this.groupIsInList(group, this.activeGroups)) {
        this.activeGroups.push(group);

        return;
      }

      // toggle open and active states
      this.closeGroup(group);

      return;
    }

    // close groups with same level if exists
    this.openGroups.filter((grp) => grp.groupLevel === group.groupLevel && this.selectedGroup?.id !== grp.id).forEach((grp) => {
      this.closeGroup(grp);
    });

    // open last one selected group
    this.openGroup(group);

    if (!this.groupIsInList(group, this.activeGroups)) {
      this.activeGroups.push(group);
    }
  }

  onEditGroup() {
    this.activeGroups = [];
  }

  onNewGroupFormToggle() {
    this.isNewGroupFormActive = !this.isNewGroupFormActive;
    this.isNewSubGroupFormActive = false;
    this.activeGroups = [];
  }

  onNewSubGroupFormToggle() {
    this.isNewSubGroupFormActive = !this.isNewSubGroupFormActive;
  }

  groupIsInList(group: ExtraListElementInterface, list: ExtraListElementInterface[]) {
    if (!list || !group) {
      return false;
    }

    return list.some((grp) => grp.id === group.id);
  }

  private deactivateAllExceptOne(groups: ExtraListElementInterface[], targetGroup: ExtraListElementInterface) {
    if (!groups || !targetGroup) {
      return;
    }

    this.isNewGroupFormActive = false;
    this.isNewSubGroupFormActive = false;

    groups.forEach((grp) => {
      if (grp.id !== targetGroup.id) {
        this.deactivateGroup(grp);

        this.deactivateAllExceptOne(grp.children, targetGroup);
      }
    })
  }

  private deactivateGroup(group: ExtraListElementInterface) {
    if (this.groupIsInList(group, this.activeGroups)) {
      this.activeGroups.splice(this.activeGroups.indexOf(group), 1);
    }
  }

  private closeGroup(group: ExtraListElementInterface) {
    if (!group) {
      return;
    }

    group.isOpen = false;
    if (this.groupIsInList(group, this.openGroups)) {
      this.openGroups.splice(this.openGroups.indexOf(group), 1);
    }
  }

  private openGroup(group: ExtraListElementInterface) {
    if (!group) {
      return;
    }
    group.isOpen = true;
    if (!this.groupIsInList(group, this.openGroups)) {
      this.openGroups.push(group);
    }
  }

  private openGroupRecursive(group: ExtraListElementInterface) {
    if (!group) {
      return;
    }

    this.openGroup(group);

    if (group.parent) {
      this.openGroupRecursive(group.parent);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.openGroupService.closeAll();
  }
}
