import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessagesInterface } from '../../../core/models/messages.model';
import { NotificationInterface } from '../../../core/models/notification.model';
import { NotificationTranslationType } from '../../../core/enums/notification-translation-type.enum';
import { ClaimStatusToTranslationMap } from '../../../core/enums/claim-state.enum';
import { ProjectReservationStatusTranslationMap } from '../../../core/models/project-reservation.model';
import { PriceRequestStatusTranslationMap } from '../../../core/models/price-request.model';

@Injectable({
  providedIn: 'root',
})
export class MessageCodeTranslationService {
  constructor(private translator: TranslateService) {}

  getMessageCodeTranslation(notification: NotificationInterface | MessagesInterface) {
    const translationCode = (notification as NotificationInterface).message
      ? (notification as NotificationInterface).message
      : (notification as MessagesInterface).text;
    const user = (notification as NotificationInterface).senderFirstname
      ? (notification as NotificationInterface).senderFirstname
      : (notification as MessagesInterface).firstName;
    const itemCode = notification.details?.itemCode;
    let status = '';
    let previousStatus = '';
    switch (translationCode) {
      case NotificationTranslationType.TRANSLATION_ORDER_ARTICLE_REMOVED:
        if (notification.details.count <= 1) {
          return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_REMOVED.SINGULAR', {
            itemCode,
          });
        }
        return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_REMOVED.PLURAL', {
          user,
          count: notification.details.count,
        });
      case NotificationTranslationType.TRANSLATION_AX_ADDED_ITEM:
        return this.translator.get('TRANSLATION_CODE.ADDED_ITEM', { item: notification.details.item });
      case NotificationTranslationType.TRANSLATION_AX_CHANGED_QNT:
        return this.translator.get('TRANSLATION_CODE.CHANGED_QUANTITY', { item: notification.details.item });
      case NotificationTranslationType.TRANSLATION_AX_ORDER_UPDATE:
        return this.translator.get('TRANSLATION_CODE.ORDER_UPDATE');
      case NotificationTranslationType.TRANSLATION_AX_DATE_CHANGE:
        return this.translator.get('TRANSLATION_CODE.DATE_CHANGE');
      case NotificationTranslationType.TRANSLATION_AX_ADDRESS_CHANGE:
        return this.translator.get('TRANSLATION_CODE.ADDRESS_CHANGE');
      case NotificationTranslationType.TRANSLATION_AX_DELETED_LINE:
        return this.translator.get('TRANSLATION_CODE.DELETED_LINE', { item: notification.details.item });
      case NotificationTranslationType.TRANSLATION_AX_PRICE_CHANGE:
        return this.translator.get('TRANSLATION_CODE.PRICE_CHANGE');
      case NotificationTranslationType.TRANSLATION_ORDER_ARTICLE_ADDED:
        if (notification.details.count <= 1) {
          return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_ADDED.SINGULAR', {
            itemCode,
          });
        }
        return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_ADDED.PLURAL', {
          user,
          count: notification.details.count,
        });
      case NotificationTranslationType.TRANSLATION_ORDER_ARTICLE_UPDATED:
        if (notification.details.count <= 1) {
          return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_UPDATED.SINGULAR', {
            itemCode,
          });
        }
        return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_UPDATED.PLURAL', {
          user,
          count: notification.details.count,
        });
      case NotificationTranslationType.TRANSLATION_ORDER_STATE_CHANGED_ARCHIVED:
        return this.translator.get('TRANSLATION_CODE.STATE_CHANGED.ARCHIVED');
      case NotificationTranslationType.TRANSLATION_ORDER_STATE_CHANGED_LOADED:
        return this.translator.get('TRANSLATION_CODE.STATE_CHANGED.LOADED');
      case NotificationTranslationType.TRANSLATION_ORDER_STATE_CHANGED_CONFIRMED:
        return this.translator.get('TRANSLATION_CODE.STATE_CHANGED.CONFIRMED');
      case NotificationTranslationType.TRANSLATION_ORDER_STATE_CHANGED_WAITING:
        return this.translator.get('TRANSLATION_CODE.STATE_CHANGED.WAITING');
      case NotificationTranslationType.TRANSLATION_ARTICLE_PHOTOS_IMPORTED:
        return this.translator.get('TRANSLATION_CODE.IMPORTED_PHOTOS', {
          importDate: notification.details.importDate,
          importCount: notification.details.importCount,
          articleCodes: notification.details.articleCodes,
        });
      case NotificationTranslationType.TRANSLATION_NON_STANDARD_ORDER_ARTICLE_UPDATED:
        return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_UPDATED.NON_STANDARD', { user });
      case NotificationTranslationType.TRANSLATION_STANDARD_ORDER_ARTICLE_UPDATED:
        return this.translator.get('TRANSLATION_CODE.ORDER_ARTICLE_UPDATED.STANDARD', { user });
      case NotificationTranslationType.TRANSLATION_CLAIM_NEW_MESSAGE:
        return this.translator.get('SETTINGS.NOTIFICATIONS.COMPUTED_NEW_MESSAGE');
      case NotificationTranslationType.TRANSLATION_CLAIM_STATE_CHANGE:
        status = this.translator.instant(ClaimStatusToTranslationMap[notification.details.status]);
        previousStatus = this.translator.instant(ClaimStatusToTranslationMap[notification.details.previousStatus]);
        return this.translator.get('TRANSLATION_CODE.CLAIM_STATE_CHANGE', { status, previousStatus });
      case NotificationTranslationType.TRANSLATION_PROJECT_RESERVATION_NEW_MESSAGE:
        return this.translator.get('SETTINGS.NOTIFICATIONS.COMPUTED_NEW_MESSAGE');
      case NotificationTranslationType.TRANSLATION_PROJECT_RESERVATION_STATE_CHANGE:
        status = this.translator.instant(ProjectReservationStatusTranslationMap[notification.details.status]);
        previousStatus = this.translator.instant(ProjectReservationStatusTranslationMap[notification.details.previousStatus]);
        return this.translator.get('TRANSLATION_CODE.TRANSLATION_PROJECT_RESERVATION_STATE_CHANGE', { status, previousStatus });
      case NotificationTranslationType.TRANSLATION_CUSTOM_MADE_PRICE_REQUEST_NEW_MESSAGE:
        return this.translator.get('SETTINGS.NOTIFICATIONS.COMPUTED_NEW_MESSAGE');
      case NotificationTranslationType.TRANSLATION_CUSTOM_MADE_PRICE_REQUEST_STATE_CHANGE:
        status = this.translator.instant(PriceRequestStatusTranslationMap[notification.details.status]);
        previousStatus = this.translator.instant(PriceRequestStatusTranslationMap[notification.details.previousStatus]);
        return this.translator.get('TRANSLATION_CODE.TRANSLATION_CUSTOM_MADE_PRICE_REQUEST_STATE_CHANGE', { status, previousStatus });
      default:
        return of(translationCode);
    }
  }
}
