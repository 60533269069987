import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import { SelectionMenuService } from '../../../selection-menu/selection-menu.service';
import {OrderArticleFormItemType} from '../../../core/models/order-article.model';
import {OrderArticleService} from '../../../core/services/order-article/order-article.service';
import {LoaderService} from '../../../ui-elements/loader/loader.service';
import {UserService} from '../../../core/services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {FileUploadService} from '../../../ui-elements/file-upload/file-upload.service';
import {CustomArticleModalModel} from '../custom-article-modal.model';
import {Observable} from 'rxjs';
import {UserRole} from '../../../core/enums/user-role.enum';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {noWhitespace, ucsMinLength} from "../../../shared/class/custom-validators";
import { ToastService } from '../../../ui-elements/toast/toast.service';
import {ListModeSwitchService} from "../../../shared/components/list-mode-switch/list-mode-switch.service";
import {OrderState} from "../../../core/enums/order.state.enum";

@Component({
  selector: 'app-non-standard-item-modal',
  templateUrl: './non-standard-item-modal.component.html',
  styleUrls: ['../custom-article-modal.component.scss'],
  // Needed as modal html element is rendered out of component scope (at the end of the <body>)
  encapsulation: ViewEncapsulation.None,
})
export class NonStandardItemModalComponent extends CustomArticleModalModel implements OnInit, OnDestroy {
  @ViewChild('content') content: TemplateRef<any>;

  protected itemType = OrderArticleFormItemType.CUSTOM_NON_STANDARD_ITEM;

  canChange = true;

  constructor(
    fb: UntypedFormBuilder,
    selectionMenuService: SelectionMenuService,
    orderArticleService: OrderArticleService,
    loaderService: LoaderService,
    userService: UserService,
    translateService: TranslateService,
    fileUploadService: FileUploadService,
    toastService: ToastService,
    modalService: NgbModal,
    listModeSwitchService: ListModeSwitchService,
  ) {
    super(
      fb,
      selectionMenuService,
      orderArticleService,
      loaderService,
      userService,
      translateService,
      fileUploadService,
      toastService,
      modalService,
      listModeSwitchService,
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService
      .fromStorage()
      .subscribe((user) => {
        // Not actually used, as EDIT button is not shown for dealer when order status is not draft anymore
        if (this.user?.role.name === UserRole.ROLE_DEALER && this.selectedOrder.state !== OrderState.DRAFT) {
          this.canChange = false;
        }
      });
  }

  getWarningMessage(): string {
    if (!this.selectedOrder) {
      return 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ERRORS.NO_ORDER_SELECTED';
    }
    return 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ERRORS.ADD_REQUIRED_STUFF';
  }

  getButtonMessage(): Observable<string> {
    return new Observable(observer => {
      let translationKey = 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.ADD_TO_OFFER';
      if (this.item?.id) {
        translationKey = 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.SAVE';
      }

      this.translateService.get(translationKey).subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  addInputLongText() {
    this.inputLongText.addValidators([
      Validators.required,
      ucsMinLength(5),
      noWhitespace,
    ]);

    super.addInputLongText();
  }
}
