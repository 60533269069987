import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActionType } from './action-type.enum';
import { ExtraListElementsTypes } from '../order-articles-list/order-articles-list/components/extra-row/extra-row-types.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRole } from '../../core/enums/user-role.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OrderItemsClipboardService } from '../order-items-clipboard/order-items-clipboard.service';
import {
  ClipboardRowInterface,
  OrderItemsPasteEvent,
  OrderItemsPasteEventType,
} from '../order-items-clipboard/order-items-clipboard.interface';
import { PermissionActions } from '../../permissions.config';
import { OrderInterface } from '../../core/models/order.model';
import { FullScreenModeService } from '../../core/services/full-screen-mode/full-screen-mode.service';
import { getSortedSelectedItemsToGroup } from '../../core/services/order-items-group/order-items-group.service';
// tslint:disable-next-line:max-line-length
import { OrderItemsGroupingValidatorService } from '../order-articles-list/order-articles-list/components/group/order-items-grouping-validator/order-items-grouping-validator.service';
import {
  OrderArticlesListRow,
  OrderArticlesListRowInterface,
  pageBreakKey,
  RowTypes,
} from '../order-articles-list/order-articles-list.interface';
import { SelectedRowsService } from '../order-articles-list/services/selected-rows/selected-rows.service';
import { OrderArticlesRowsService } from '../order-articles-list/services/order-articles-rows/order-articles-rows.service';
import { UngroupEventInterface } from '../order-articles-list/order-articles-list/components/group/group.component';
import { ElementsCountPlural } from '../../core/constants/elements-count-plural.constants';
import { TranslationKeyManager } from '../../core/util/translation-key.manager';
import { OrderPasteActionType } from '../order-items-clipboard/order-item-type.enum';
import { OrdersService } from '../orders.service';
import { FullOrderArticleInterface } from '../../core/models/full-order-article.model';
import { OpenGroupsService } from '../order-articles-list/order-articles-list/components/group/open-groups/open-groups.service';
import { ImportCsvModalComponent } from './import-csv-modal/import-csv-modal.component';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { ToastService } from '../../ui-elements/toast/toast.service';
import { InquiriesRoutePath } from '../../core/enums/route-types.enum';
import { OrderArticleMigrationStatusType, OrderArticleType } from '../../core/models/order-article.model';
import { PriceRequestItemStatusEnum } from '../../core/models/price-request-item.model';
import { OrderState } from '../../core/enums/order.state.enum';
import { UserService } from '../../core/services/user/user.service';
import FeatureFlags from '../../../../featureFlags.json';
import { OrderArticleRowsFilteringService } from '../order-articles-list/services/order-article-rows-filtering/order-article-rows-filtering.service';

export interface AllowedActionsInterface {
  modify: boolean;
  edit: boolean;
  group: boolean;
  ungroup: boolean;
}

export class AllowedActions implements AllowedActionsInterface {
  modify = false;
  edit = false;
  group = false;
  ungroup = false;
}

export const ALLOWED_MAXIMUM_GROUP_LEVEL = 3;

enum DisabledCopyControlReasonEnum {
  OLD_NONSTANDARD,
  PRICE_REQUEST_ITEM_OR_GROUP_INSIDE_SENT_TO_AX_ORDER
}

interface DisabledVisibleActionInterface {
  name: ActionType;
  reason?: DisabledCopyControlReasonEnum;
}

@Component({
  selector: 'app-order-articles-list-controls',
  templateUrl: './controls.component.html',
})
export class ControlsComponent implements OnInit, OnDestroy, OnChanges {
  allowedSelectedItemsActions: AllowedActionsInterface = new AllowedActions();
  permissionActions = PermissionActions;

  @Input() availableActions: ActionType[] = [];
  @Input() order?: OrderInterface;

  @Output() modify: EventEmitter<any> = new EventEmitter();
  @Output() copy: EventEmitter<any> = new EventEmitter();
  @Output() cut: EventEmitter<any> = new EventEmitter();
  @Output() paste: EventEmitter<OrderItemsPasteEvent> = new EventEmitter<OrderItemsPasteEvent>();
  @Output() addItem: EventEmitter<any> = new EventEmitter();
  @Output() addExtraItem: EventEmitter<ExtraListElementsTypes> = new EventEmitter();
  extraItemTypes = ExtraListElementsTypes;
  @Output() addDiscount: EventEmitter<any> = new EventEmitter();
  @Output() uploadFile: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<{ id: number; rowType: RowTypes }[]> = new EventEmitter<{ id: number; rowType: RowTypes }[]>();
  @Output() otherSupplierItemAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() nonStandardItemAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() groupItems: EventEmitter<void> = new EventEmitter<void>();
  @Output() exportOrderToPdf: EventEmitter<LoaderComponent> = new EventEmitter<null>();

  @ViewChild('loader', { static: false }) loader: LoaderComponent;

  userRole = UserRole;
  clipboardCount = 0;
  selectedCount: { orderArticles: number; extraItems: number; total: number } = {
    orderArticles: 0,
    extraItems: 0,
    total: 0,
  };
  toGroupCount = 0;
  clipboardItems: ClipboardRowInterface[] = [];
  singularPluralMap: { [k: string]: string } = ElementsCountPlural;
  selectedRows: OrderArticlesListRow[] = [];
  rows: OrderArticlesListRow[] = [];
  shouldAllowGroup = true;
  shouldAllowAddGroup = true;
  actionType = ActionType;
  rowTypes = RowTypes;
  loading: boolean;
  disabledActions: string[] = [];
  visibleDisabledActions: DisabledVisibleActionInterface[] = [];
  orderFullCodes: string[] = [];
  orderArticles: FullOrderArticleInterface[] = [];
  importInProgress = false;
  rowsFilterEnabled = false;
  inquiriesRoutePath = InquiriesRoutePath;
  orderArticleTypes = OrderArticleType;
  disabledActionsWhileActiveFilter: ActionType[] = [ActionType.PASTE, ActionType.GROUP, ActionType.UNGROUP];
  FeatureFlags = FeatureFlags;

  private subscription = new Subscription();

  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private translator: TranslateService,
    private orderItemsGroupingValidator: OrderItemsGroupingValidatorService,
    private fullScreenModeService: FullScreenModeService,
    private selectedRowsService: SelectedRowsService,
    private orderItemsClipboardService: OrderItemsClipboardService,
    private orderArticlesRowsService: OrderArticlesRowsService,
    private ordersService: OrdersService,
    private openGroupsService: OpenGroupsService,
    private orderArticleRowsFilteringService: OrderArticleRowsFilteringService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableActions) {
      this.allowedSelectedItemsActions = this.enableActionsByCountAndRows(this.selectedCount.total, this.selectedRows);
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.orderItemsClipboardService.getClipboardItemsAsObservable().subscribe(items => {
        this.clipboardCount = this.orderItemsClipboardService.countClipboardItems(items);
        this.clipboardItems = items;
      })
    );

    this.subscription.add(
      this.selectedRowsService.getSelectedRowsAsObservable().subscribe(selectedRows => {
        this.selectedRows = selectedRows;
        const orderArticlesCount = this.selectedRows.filter(item => item.rowType === RowTypes.PRODUCT).length;
        const extraItemsCount = this.selectedRows.filter(item => item.rowType !== RowTypes.PRODUCT).length;

        this.selectedCount = {
          orderArticles: orderArticlesCount,
          extraItems: extraItemsCount,
          total: orderArticlesCount + extraItemsCount,
        };

        if (this.selectedRows && this.selectedRows.length) {
          const itemsToGroup = getSortedSelectedItemsToGroup(this.selectedRows);
          this.toGroupCount = itemsToGroup.length;
          this.shouldAllowGroup = this.orderItemsGroupingValidator.shouldAllowGroup(this.selectedRows, itemsToGroup);
          this.shouldAllowAddGroup = this.orderItemsGroupingValidator.shouldAllowAddGroup(this.selectedRows);
        }
        this.allowedSelectedItemsActions = this.enableActionsByCountAndRows(this.selectedCount.total, selectedRows);
      })
    );

    this.subscription.add(
      this.orderArticlesRowsService.rows.subscribe(rows => {
        this.rows = rows;
      })
    );

    this.subscription.add(
      this.ordersService.articleFullCodesInOrderAsObservable().subscribe(orderFullCodes => {
        this.orderFullCodes = orderFullCodes;
      })
    );

    this.subscription.add(
      this.ordersService.reloadedArticlesAsObservable().subscribe(articles => {
        this.orderArticles = articles;
      })
    );

    this.subscription.add(
      this.ordersService.importInProgressAsObservable().subscribe(status => {
        this.importInProgress = status;
      })
    );

    this.subscription.add(
      this.orderArticleRowsFilteringService.getIsFilterEnabledObservable().subscribe((isEnabled) => {
        this.rowsFilterEnabled = isEnabled;
      })
    );
  }

  private disableFullScreenMode(): void {
    this.fullScreenModeService.changeModeStatus(false);
  }

  onModify() {
    this.modify.emit();
  }

  onCopy() {
    this.orderItemsClipboardService.copy(this.order.id, this.selectedRows);
    this.translator
      .get(
        `ORDER_PRODUCTS_LIST.CONTROLS.COPIED_SUCCESSFULLY${TranslationKeyManager.determinePluralSingularTranslationKeyByNumber(
          this.selectedCount.total
        )}`,
        { count: this.selectedCount.total }
      )
      .subscribe(translation => {
        this.toastService.success(translation);
      });
    this.selectedRowsService.resetSelectedRows();
  }

  onCut() {
    this.orderItemsClipboardService.cut(this.order.id, this.selectedRows);
    this.translator.get(`ORDER_PRODUCTS_LIST.CONTROLS.CUT_SUCCESSFULLY`, { count: this.selectedCount.total }).subscribe(translation => {
      this.toastService.success(translation);
    });
    this.selectedRowsService.resetSelectedRows();
  }

  onPaste() {
    if (!this.loading && this.shouldAllowPaste() && this.order) {
      this.loading = true;
      const tempSelectedRows = [...this.selectedRows];

      this.orderItemsClipboardService
        .paste(this.order.id, this.selectedRows, this.rows, this.orderFullCodes, this.orderArticles)
        .subscribe(({ event }) => {
          if (event !== OrderItemsPasteEventType.CANCEL) {
            this.orderItemsClipboardService.clearClipboard();
            const { parent: selectedGroup } = this.selectedRowsService.getSelectedItemsAndParent(tempSelectedRows);
            this.paste.emit(event);
            if (selectedGroup) {
              this.openGroupsService.open(selectedGroup);
            }
          }

          this.loading = false;
        });
      this.selectedRowsService.resetSelectedRows();
    }
  }

  onAddItem() {
    this.addItem.emit();
    this.disableFullScreenMode();
  }

  onAddNonStandardItem() {
    this.nonStandardItemAdd.emit();
    this.disableFullScreenMode();
  }

  onAddOtherSupplierItem() {
    this.otherSupplierItemAdd.emit();
    this.disableFullScreenMode();
  }

  onFileSelect(event) {
    const files: FileList = event.target.files;

    this.disableFullScreenMode();
    this.uploadFile.emit(files);

    // clear the file input
    event.srcElement.value = null;
  }

  openCsvUploadModal() {
    const csvImportModalRef = this.modalService.open(ImportCsvModalComponent, {
      size: 'lg',
      scrollable: true,
      beforeDismiss: () => false,
    });

    csvImportModalRef.componentInstance.order = this.order;
    csvImportModalRef.componentInstance.confirm.subscribe(() => {
      this.ordersService.refreshOrderImportStatus$.next(true);
    });
  }

  onExtraItemAdd(type: ExtraListElementsTypes) {
    this.addExtraItem.emit(type);
    this.disableFullScreenMode();
  }

  onGroupItems() {
    this.groupItems.emit();
    this.disableFullScreenMode();
  }

  onExportOrderToPdf() {
    this.exportOrderToPdf.emit(this.loader);
  }

  protected enableActionsByCountAndRows(selectedCount: number, selectedRows: OrderArticlesListRow[]): AllowedActionsInterface {
    let actions = [];
    this.disabledActions = [];
    this.visibleDisabledActions = [];
    if (!selectedCount) {
      const toEnable = this.enableActions(actions);
      return toEnable;
    }

    const { articles, groups, priceRequestItemGroups } = {
      // in possible actions context article and extra items are basically the same
      articles: this.selectedRows.filter(row => row.rowType === RowTypes.PRODUCT),
      groups: this.selectedRows.filter(row => row.rowType === RowTypes.GROUP),
      priceRequestItemGroups: this.selectedRows.filter(row => row.rowType === RowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP),
    };

    if (articles.length === 1) {
      actions = [ActionType.MODIFY, ActionType.GROUP];

      // we can't use edit action if item is removed by migration or is price request item
      if (
        selectedRows[0] &&
        selectedRows[0].rowType === RowTypes.PRODUCT &&
        selectedRows[0].type !== OrderArticleType.PRICE_REQUEST_ITEM &&
        selectedRows[0].migrationStatus !== OrderArticleMigrationStatusType.REMOVED
      ) {
        actions.push(ActionType.EDIT);
      } else {
        this.disableActionsByMigrationStatus(selectedRows);
      }
    } else if (selectedCount > 1) {
      actions = [ActionType.MODIFY, ActionType.GROUP];
      this.disableActionsByMigrationStatus(selectedRows);
    } else if (!articles.length) {
      actions = [ActionType.MODIFY];
    }

    if (groups.length) {
      actions.push(ActionType.UNGROUP, ActionType.GROUP);
    }

    // hide copy/cut for canceled and expired price request items
    const canceledOrExpiredItemSelected = articles
      .some((item) =>
        [PriceRequestItemStatusEnum.CANCELED, PriceRequestItemStatusEnum.EXPIRED].includes(
          (item as OrderArticlesListRowInterface).customMadePriceRequestItem?.status
        )
      );

    if (canceledOrExpiredItemSelected) {
      this.pushIfNotExists(this.disabledActions, ActionType.COPY);
      this.pushIfNotExists(this.disabledActions, ActionType.CUT);
    }

    const oldNonStandardItemSelected = articles.some((article) => article.type === OrderArticleType.CUSTOM_ITEM_TYPE);

    if (oldNonStandardItemSelected) {
      // disable copy/cut for old non-standards
      if (FeatureFlags.oldNonStandardsRestrictionsEnabled && !this.userService.isPmNarbutas()) {
        this.visibleDisabledActions.push({ name: ActionType.COPY, reason: DisabledCopyControlReasonEnum.OLD_NONSTANDARD });
        this.visibleDisabledActions.push({ name: ActionType.CUT });
      }

      // disable all but group and delete for expired non-standards
      if (!FeatureFlags.oldNonStandardsSupported && this.order?.state === OrderState.DRAFT && this.userService.isDealer()) {
        this.pushIfNotExists(this.disabledActions, ActionType.EDIT);
        this.pushIfNotExists(this.disabledActions, ActionType.COPY);
        this.pushIfNotExists(this.disabledActions, ActionType.CUT);
        this.pushIfNotExists(this.disabledActions, ActionType.DISCOUNT);
      }
    }

    // disable copy when order is already sent to AX
    const priceRequestItemSelected = articles.some((article) => article.type === OrderArticleType.PRICE_REQUEST_ITEM);

    const sentToAxaptaStates = [
      OrderState.IN_AX,
      OrderState.AX_ARCHIVED,
      OrderState.AX_CONFIRMED,
      OrderState.AX_ARCHIVE_CONFIRMED,
      OrderState.AX_LOADED,
    ];

    if ((priceRequestItemSelected || priceRequestItemGroups.length) && sentToAxaptaStates.includes(this.order?.state)) {
      const disabledCopyActionIndex = this.visibleDisabledActions.findIndex((action) => action.name === ActionType.COPY);

      if (disabledCopyActionIndex === -1) {
        this.visibleDisabledActions.push({
          name: ActionType.COPY,
          reason: DisabledCopyControlReasonEnum.PRICE_REQUEST_ITEM_OR_GROUP_INSIDE_SENT_TO_AX_ORDER,
        });
      } else {
        this.visibleDisabledActions[disabledCopyActionIndex] = {
          ...this.visibleDisabledActions[disabledCopyActionIndex],
          reason: DisabledCopyControlReasonEnum.PRICE_REQUEST_ITEM_OR_GROUP_INSIDE_SENT_TO_AX_ORDER,
        };
      }
    }

    // disable ungroup for locked price request item groups if all of them are not in selected groups themselves
    const selectedGroupsIds = groups.map(({ id }) => id);
    const parentGroupsAreSelected = priceRequestItemGroups.every(lockedGroup => selectedGroupsIds.includes(lockedGroup.parent?.id));

    if (priceRequestItemGroups.length && !parentGroupsAreSelected) {
      this.pushIfNotExists(this.disabledActions, ActionType.UNGROUP);
    }

    return this.enableActions(actions);
  }

  protected enableActions(actions: Array<ActionType>): AllowedActionsInterface {
    if (!actions.length) {
      return new AllowedActions();
    }

    return actions.reduce((carry: AllowedActionsInterface, action: ActionType) => {
      if (typeof carry[action] !== 'undefined' && this.availableActions.includes(action)) {
        carry[action] = true;
      }

      return carry;
    }, new AllowedActions());
  }

  protected disableActionsByMigrationStatus(selectedRows: OrderArticlesListRow[]) {
    if (!selectedRows.length) {
      return;
    }

    selectedRows.forEach(item => {
      if (item.rowType === RowTypes.PRODUCT && item.migrationStatus === OrderArticleMigrationStatusType.REMOVED) {
        this.pushIfNotExists(this.disabledActions, ActionType.COPY);
        this.pushIfNotExists(this.disabledActions, ActionType.CUT);
        this.pushIfNotExists(this.disabledActions, ActionType.DISCOUNT);
      }
    });
  }

  protected pushIfNotExists(target: string[], item: string) {
    if (target.indexOf(item) === -1) {
      target.push(item);
    }

    return target;
  }

  isControlAllowed(action: ActionType): boolean {
    return (
      this.availableActions.includes(action) &&
      !this.disabledActions.includes(action) &&
      (!this.rowsFilterEnabled || !this.disabledActionsWhileActiveFilter.includes(action))
    );
  }

  isControlDisabled(actionName: ActionType): boolean {
    return this.visibleDisabledActions.map(action => action.name).includes(actionName);
  }

  getCopyControlTooltipContent(): string {
    if (this.isControlDisabled(this.actionType.COPY)) {
      const reason = this.visibleDisabledActions.find(action => action.name === ActionType.COPY).reason;

      switch (reason) {
        case DisabledCopyControlReasonEnum.OLD_NONSTANDARD:
          return this.translator.instant('CLIPBOARD.TOOLTIPS.CANT_COPY_OLD');
        case DisabledCopyControlReasonEnum.PRICE_REQUEST_ITEM_OR_GROUP_INSIDE_SENT_TO_AX_ORDER:
          return this.translator.instant('CLIPBOARD.TOOLTIPS.CANT_COPY_FROM_SENT_TO_AX_ORDER');
        default:
          return '';
      }
    }

    const copyTranslation = this.translator.instant('CLIPBOARD.COPY');
    const elementsCountTranslation = this.getSelectedElementsCountTranslation();

    return copyTranslation + ' ' + elementsCountTranslation;
  }

  getCutControlTooltipContent(): string {
    if (this.isControlDisabled(this.actionType.CUT)) {
      return this.translator.instant('CLIPBOARD.TOOLTIPS.CANT_CUT_OLD');
    }

    const cutTranslation = this.translator.instant('CLIPBOARD.CUT');
    const elementsCountTranslation = this.getSelectedElementsCountTranslation();

    return cutTranslation + ' ' + elementsCountTranslation;
  }

  private getSelectedElementsCountTranslation(): string {
    const count = this.selectedCount.total;

    let pluralKey = 'other';
    if (ElementsCountPlural[`=${count}`]) {
      pluralKey = `=${count}`;
    }

    const elementsCountTranslationKey = ElementsCountPlural[pluralKey];

    return this.translator.instant(elementsCountTranslationKey, { count });
  }

  findMinByGroupLevel(prev: OrderArticlesListRow, current: OrderArticlesListRow) {
    return prev.groupLevel < current.groupLevel ? prev : current;
  }

  shouldAllowPaste(): boolean {
    const selectedGroups = this.selectedRows.filter(row => row.rowType === RowTypes.GROUP);
    const lowestSelectedGroup = !selectedGroups.length ? null : selectedGroups.reduce(this.findMinByGroupLevel);
    const groupsInGroupLevel = selectedGroups.filter(row => row.groupLevel === lowestSelectedGroup?.groupLevel);

    // if more than one item is selected, can't paste
    if(this.selectedCount.orderArticles > 0 && groupsInGroupLevel.length !== 1) {
      return false;
    }

    if (!this.selectedRows?.length) {
      return true;
    }

    const copiedItems = this.orderItemsClipboardService.getClipboardItems();

    if (!(copiedItems && copiedItems.length)) {
      return false;
    }

    const copiedGroups = copiedItems.filter(item => item.rowType === RowTypes.GROUP);

    const actionType = this.orderItemsClipboardService.getClipboardAction();

    const sameGroupSelected = copiedGroups.some(clipboardItem => {
      return !!(
        this.selectedRows.find(selectedRow => clipboardItem.id === selectedRow.id) ||
        this.selectedRows.find(
          selectedRow => selectedRow[pageBreakKey(selectedRow)] && clipboardItem.id === selectedRow[pageBreakKey(selectedRow)].id
        )
      );
    });

    const sameRowSelected = copiedItems
      .filter(item => item.rowType !== RowTypes.GROUP)
      .some(clipboardItem => {
        return !!this.selectedRows.find(selectedRow => clipboardItem.id === selectedRow.id);
      });

    if (actionType === OrderPasteActionType.CUT && (sameGroupSelected || sameRowSelected)) {
      return false;
    }

    if (actionType === OrderPasteActionType.COPY && sameGroupSelected) {
      return false;
    }

    if (!(copiedGroups && copiedGroups.length)) {
      return true;
    }

    const findMax = (prev, current) => {
      return prev.groupLevel > current.groupLevel ? prev : current;
    };

    const highestSelectedRow = this.selectedRows.reduce(findMax);

    const highestClipboardRow = copiedItems.reduce(findMax);

    const lowestLevelOfClipboard = Math.min.apply(
      Math,
      copiedItems.map(item => item.groupLevel)
    );

    const highestClipboardGroup = copiedGroups.reduce(findMax);

    const highestSelectedGroup = selectedGroups && selectedGroups.length ? selectedGroups.reduce(findMax) : null;

    if (
      highestClipboardGroup.groupLevel - lowestLevelOfClipboard + (highestSelectedGroup ? highestSelectedGroup.groupLevel : 0) + 1 >=
      ALLOWED_MAXIMUM_GROUP_LEVEL
    ) {
      return false;
    }

    const copiedItemsDepth = highestClipboardRow.groupLevel - lowestLevelOfClipboard;

    const calculation = highestSelectedRow.groupLevel + copiedItemsDepth;

    if (!highestClipboardRow.groupLevel && !lowestLevelOfClipboard) {
      return true;
    }
    return this.clipboardCount > 0 && calculation <= ALLOWED_MAXIMUM_GROUP_LEVEL;
  }

  onDiscount() {
    this.addDiscount.emit();
  }

  onRemove() {
    this.remove.emit(
      this.selectedRows.map(({ id, rowType }) => {
        return { id, rowType };
      })
    );
  }

  /**
   * Remove selected groups and ungroup child items
   */
  onUngroup() {
    const groups = this.selectedRows.filter(row => row.rowType === RowTypes.GROUP);
    const lowestGroupLevel = Math.min.apply(
      Math,
      groups.map(item => item.groupLevel)
    );
    const groupsToRemove: UngroupEventInterface[] = groups
      .reduce((acc, group) => {
        const children = group.children.reduce((acc1, current) => {
          acc1.push({ ...current, [pageBreakKey(current)]: group.parent || null });
          return acc1;
        }, []);
        acc.push({ group: { id: group.id, rowType: group.rowType, groupLevel: group.groupLevel }, children });
        return acc;
      }, [])
      .filter(({ group }) => group.groupLevel === lowestGroupLevel);
    this.orderArticlesRowsService.ungroup.next(groupsToRemove);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
