import { Component, OnDestroy, OnInit } from '@angular/core';
import { CertificateTagCategoryInterface, CertificateTagInterface } from '../../../core/models/certificate.model';
import { CertificatesFiltersService } from '../services/certificates-filters.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-certificate-filters',
  templateUrl: './filters.component.html',
})
export class CertificateFiltersComponent implements OnInit, OnDestroy {
  categories = new Map<CertificateTagCategoryInterface, CertificateTagInterface[]>();
  expandedCategory: CertificateTagCategoryInterface | null = null;
  tags: CertificateTagInterface[] = [];
  form: ReturnType<typeof this.buildTagsSearchForm>;
  
  subscription = new Subscription();

  constructor(
    private certificatesFiltersService: CertificatesFiltersService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.certificatesFiltersService.getTagsAsObservable().subscribe(tags => {
      this.categories = this.certificatesFiltersService.groupTagsByCategory(tags);
      this.tags = tags;
    }));

    this.form = this.buildTagsSearchForm();
  }

  buildTagsSearchForm() {
    return new FormGroup({
      search: new FormControl<string>(''),
    });
  }

  onTagSelected(tag: CertificateTagInterface): void {
    this.certificatesFiltersService.selectTag(tag.id);
    this.form.controls.search.patchValue('');
  }

  onToggleCategoryCollapse(category: CertificateTagCategoryInterface): void {
    this.expandedCategory = this.expandedCategory?.id === category.id ? null : category;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
