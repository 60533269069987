import {Injectable} from '@angular/core';
import {OrderNotificationOpenerService} from './order-notification-opener.service';
import {NotificationInterface, NotificationObjectType} from '../../core/models/notification.model';
import { ClaimNotificationOpenerService } from './claim-notification-opener.service';
import { ProjectReservationNotificationOpenerService } from './project-reservation-notification-opener.service';
import { PriceRequestNotificationOpenerService } from './price-request-notification-opener.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationOpenerService {
  constructor(
    private orderNotificationOpener: OrderNotificationOpenerService,
    private claimNotificationOpener: ClaimNotificationOpenerService,
    private projectReservationNotificationOpener: ProjectReservationNotificationOpenerService,
    private priceRequestNotificationOpener: PriceRequestNotificationOpenerService,
  ) { }

  open(notification: NotificationInterface) {
    switch (notification.object) {
      case NotificationObjectType.ORDER:
        this.orderNotificationOpener.open(notification);
        break;
      case NotificationObjectType.CLAIM:
        this.claimNotificationOpener.open(notification);
        break;
      case NotificationObjectType.PROJECT_RESERVATION:
        this.projectReservationNotificationOpener.open(notification);
        break;
      case NotificationObjectType.CUSTOM_MADE_PRICE_REQUEST:
        this.priceRequestNotificationOpener.open(notification);
        break;
      default:
        throw new Error(`[NotificationOpenerService] Can not determine opener of the notification by object type: ${notification.object}`);
    }
  }
}
