<div class="shadow-sm bg-white">
  <button
    (click)="onToggleCollapseClick()"
    class="d-flex justify-content-between align-items-center w-100 bg-white border-0 p-3"
  >
    <h3 class="m-0 category-title">{{ category.title }}</h3>
    <i
      *ngIf="!hasTagsSelectedInCategory; else clearButton"
      class="icon color-gray"
      [class.ni-plus]="isCollapsed"
      [class.ni-minus]="!isCollapsed"
    ></i>
  </button>
  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="d-flex flex-column px-3 pb-3">
      <div
        *ngFor="let subcategory of subcategories | keyvalue; let last = last"
        class="d-flex flex-column"
      >
        <ng-container *ngTemplateOutlet="subcategoryTagsList; context: { $implicit: subcategory }"></ng-container>
        <hr *ngIf="!last" class="my-4" />
      </div>
    </div>
  </div>
</div>

<ng-template #clearButton>
  <button
    class="btn bg-white border-0 p-0"
    (click)="onClear($event)"
  >
    {{ 'CERTIFICATES.FILTERS.CLEAR' | translate }}
  </button>
</ng-template>

<ng-template #subcategoryTagsList let-subcategory>
  <div
    *ngIf="!onlyOneSubcategory" 
    class="d-flex justify-content-between align-items-center mt-1"
		(click)="collapse.toggle()"
  >
    <h4 class="m-0">{{ subcategory.key.title }}</h4>
    <i
      class="icon color-gray"
      [class.ni-arrow-drop-down]="isSubcategoryCollapsed[subcategory.key.id]"
      [class.ni-arrow-drop-up]="!isSubcategoryCollapsed[subcategory.key.id]"
    ></i>
  </div>

  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isSubcategoryCollapsed[subcategory.key.id]"
  >
    <div
      class="d-flex flex-column gap-2 overflow-auto"
      [class.mt-3]="!onlyOneSubcategory"
      [style.max-height.px]="180"
    >
      <div
        *ngFor="let tag of subcategory.value"
        class="d-flex gap-2 ps-2 align-items-center"
      >
        <input
          class="form-check-input m-0"
          type="checkbox"
          [id]="'tag-' + tag.id"
          [checked]="selectedIds.includes(tag.id)"
          (change)="onTagChange(tag.id)"
        />
        <label class="label color-black ps-0 text-break--word" [for]="'tag-' + tag.id">
          {{ tag.title }}
        </label>
      </div>
    </div>

  </div>
</ng-template>
