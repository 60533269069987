<div
  [ngClass]="itemClass"
>
  <div
    class="d-inline-flex align-items-center"
    [ngbPopover]="popover"
    placement="bottom"
    popoverClass="material-list-item-popover border-0 rounded-0 bg-transparent"
    triggers="mouseenter:mouseleave"
    container="body"
    [animation]="false"
  >
    <ng-container *ngIf="material.img; else defaultPatternImage">
      <div class="material-thumbnail me-1 mb-1 rounded-circle border border-gray-400 overflow-hidden d-flex align-items-center justify-content-center">
        <div 
          class="material-thumbnail"
          [class.material-thumbnail-placeholder]="MaterialsHelper.isMaterialPlaceholderImage(material.img)"
          [style.background-image]="'url(' + ((material.img | resizeImage: imageSizes.SIZE_16x16 | uncache | async) ?? '') + ')'"
        ></div>
      </div>
    </ng-container>

    <div class="small" *ngIf="materialText && showMaterialTitle">
      {{ materialText }}
    </div>

    <ng-template #defaultPatternImage>
      <div class="material-thumbnail me-1 mb-1 rounded-circle border border-gray-400 overflow-hidden d-flex align-items-center justify-content-center">
        <img
          class="material-thumbnail material-thumbnail-placeholder"
          src="/assets/icons/icon-material-placeholder-small.svg"
        />
      </div>
    </ng-template>
  </div>
</div>

<ng-template #popover>
  <div class="material-preview-popover" [ngClass]="{ 'm-0': !popoverImgSrc }">
    <svg
      width="110"
      height="110"
      class="material-image"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4h48.324L55.199 0l6.875 4.4H110V110H0V4.4z" />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="110" height="110">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4h48.324L55.199 0l6.875 4.4H110V110H0V4.4z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path fill="url(#pattern0)" d="M-10-2.917h120v120h-120z" />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0" transform="scale(.01)" />
        </pattern>
        <image
          id="image0"
          [attr.width]="MaterialsHelper.isMaterialPlaceholderImage(popoverImgSrc) ? 70 : 110"
          [attr.height]="MaterialsHelper.isMaterialPlaceholderImage(popoverImgSrc) ? 70 : 110"
          [attr.x]="MaterialsHelper.isMaterialPlaceholderImage(popoverImgSrc) ? 20 : 0" 
          [attr.y]="MaterialsHelper.isMaterialPlaceholderImage(popoverImgSrc) ? 15 : 0"
          [attr.xlink:href]="(popoverImgSrc ? (popoverImgSrc | resizeImage: imageSizes.SIZE_120x120 | uncache | async) : '/assets/icons/icon-material-placeholder-large.svg')"
        />
      </defs>
    </svg>
    <div class="popover-text bg-gray-800 color-white text-center">
      {{ materialText }}
    </div>
  </div>
</ng-template>
