import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, finalize, map, of, switchMap, timer } from 'rxjs';
import { SharedWithUserInterface } from '../../core/models/user.model';
import { email as emailValidator } from '../../shared/class/custom-validators';
import { ToastService } from '../../ui-elements/toast/toast.service';
import { ShareInquiryPayloadInterface, ShareInquiryServiceInterface } from './share-inquiry-service.interface';

@Component({
  selector: 'app-share-inquiry-modal',
  templateUrl: './share-inquiry-modal.component.html'
})
export class ShareInquiryModalComponent implements OnInit {
  @Input() inquiryId: number;
  @Input() inquiryService: ShareInquiryServiceInterface;
  @Input() shouldDisplayInfoText: boolean = false;
  @Input() infoTextTranslationKey?: string;
  @Output() sharedWithCount: EventEmitter<number> = new EventEmitter();

  form: FormGroup;
  userToBeAddedToTheList: SharedWithUserInterface = null;

  usersToBeSharedWith: SharedWithUserInterface[] = [];
  usersAlreadySharedWith: SharedWithUserInterface[] = [];

  loading = true;
  isSubmitting = false;

  constructor(private translator: TranslateService, private activeModal: NgbActiveModal, private toastService: ToastService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, emailValidator], [this.userAlreadyAddedToTheList(), this.serverEmailValidation()]),
    });

    this.inquiryService
      .getShares(this.inquiryId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((users) => {
        this.usersAlreadySharedWith = users;
      });
  }

  onAdd(): void {
    this.usersToBeSharedWith.unshift(this.userToBeAddedToTheList);
    this.userToBeAddedToTheList = null;
    this.form.reset();
  }

  onRemoveUserFromList(user: SharedWithUserInterface): void {
    this.usersToBeSharedWith = this.usersToBeSharedWith.filter((userToBeSharedWith) => userToBeSharedWith !== user);
  }

  onCancel(): void {
    if (this.isSubmitting) {
      return;
    }

    this.activeModal.dismiss();
  }

  onSubmit(): void {
    this.isSubmitting = true;

    const usersToShareWith: ShareInquiryPayloadInterface = {
      users: this.usersToBeSharedWith.map((user) => {
        return { email: user.email };
      }),
    };

    this.inquiryService
      .share(this.inquiryId, usersToShareWith)
      .pipe(
        switchMap(() => {
          return this.translator.get('INQUIRIES.SHARE_INQUIRY.SHARE_SETTINGS_SAVED');
        })
      )
      .subscribe({
        next: (translation) => {
          this.toastService.success(translation);
          this.sharedWithCount.emit(this.usersToBeSharedWith.length + this.usersAlreadySharedWith.length);
          this.activeModal.close();
        },
        error: () => {
          this.isSubmitting = false;
        },
      });
  }

  get users(): SharedWithUserInterface[] {
    return [...this.usersToBeSharedWith, ...this.usersAlreadySharedWith];
  }

  get emailCustomError(): string {
    return this.form.controls.email.errors?.emailCustomError;
  }

  userAlreadyAddedToTheList(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const alreadyAdded = this.usersToBeSharedWith.some((user) => user.email === control.value);

      if (!alreadyAdded) {
        return of(null);
      }

      return this.translator.get('INQUIRIES.SHARE_INQUIRY.ALREADY_ADDED').pipe(
        map((errorTranslation) => {
          control.markAsTouched();

          return { emailCustomError: errorTranslation };
        })
      );
    };
  }

  serverEmailValidation(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return this.inquiryService.validateEmail(this.inquiryId, control.value).pipe(
            map((user) => {
              this.userToBeAddedToTheList = user;

              return null;
            }),
            catchError((response) => {
              control.markAsTouched();

              return of({ emailCustomError: response.error.errors?.children?.email?.errors[0] });
            })
          );
        })
      );
    };
  }
}
