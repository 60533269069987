import { Injectable } from '@angular/core';
import { PriceRequestItemInterface } from '../../../core/models/price-request-item.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PriceRequestItemsClipboardService {
  private clipboardKey = 'priceRequestItemsClipboard';
  private clipboardSubject = new BehaviorSubject<PriceRequestItemInterface[]>([]);

  constructor() {
    this.loadClipboard();
  }

  private loadClipboard(): void {
    const clipboard = localStorage.getItem(this.clipboardKey);

    if (clipboard) {
      this.clipboardSubject.next(JSON.parse(clipboard));
    }
  }

  private saveClipboard(): void {
    const clipboard = this.clipboardSubject.getValue();
    localStorage.setItem(this.clipboardKey, JSON.stringify(clipboard));
  }

  set(items: PriceRequestItemInterface[]): void {
    this.clipboardSubject.next(items);
    this.saveClipboard();
  }

  remove(item: PriceRequestItemInterface): void {
    const clipboard = this.clipboardSubject.getValue();
    const filteredClipboard = clipboard.filter((clipboardItem) => clipboardItem.id !== item.id);
    this.clipboardSubject.next(filteredClipboard);
    this.saveClipboard();
  }

  clearClipboard(): void {
    this.clipboardSubject.next([]);
    this.saveClipboard();
  }

  getItems(): PriceRequestItemInterface[] {
    return this.clipboardSubject.getValue();
  }

  getItemsAsObservable(): BehaviorSubject<PriceRequestItemInterface[]> {
    return this.clipboardSubject;
  }
}
