import { GROUP_LIKE_ROW_TYPES } from "../../services/order-items-group/order-items-group.service";

export function flattenDeep(arr1) {
  return arr1.reduce((acc, val) => {
    if (val.children && val.children.length) {
      return acc.concat(val, flattenDeep(val.children));
    } else {
      return acc.concat(val);
    }
  }, []);
}

export function deepFlattenGroups(arr1) {
  return arr1.reduce((acc, val) => {
    if (GROUP_LIKE_ROW_TYPES.includes(val.rowType) && val.children?.length) {
      return acc.concat(val, deepFlattenGroups(val.children));
    } else {
      return acc.concat(val);
    }
  }, []);
}