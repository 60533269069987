<button
  class="btn btn-lg no-outline h-100 px-3 d-flex align-items-center text-truncate rounded-0 w-100 hover-color-black"
  (click)="onMenuOpenerClick()"
  data-menu-toggler
>
  <ng-container [ngSwitch]="selectionMenuEntity">
    <ng-container *ngSwitchCase="selectionMenuEntities.PRICE_REQUEST">
      <i class="icon ni-widgets-fill mx-2 color-black"></i>
      <span class="text-truncate color-black fw-bold" [style.max-width.rem]="16">
        {{ selectedPriceRequest?.title }}
      </span>
    </ng-container>
    
    <span
      class="d-flex align-items-center justify-content-center h-100"
      [ngbTooltip]="(isDealer 
        ? 'CONFIGURATOR_MODAL.ORDER_SELECT.SELECT_OFFER' 
        : 'CONFIGURATOR_MODAL.ORDER_SELECT.SELECT_ORDER') 
        | translate"
      [disableTooltip]="!!selectedOrder"
    >
      <ng-container *ngSwitchCase="selectionMenuEntities.ORDER">
        <i class="icon ni-folder mx-2 color-black"></i>
        <span class="text-truncate color-black fw-bold" [style.max-width.rem]="16">
          {{ selectedOrder?.title }}
          <ng-container *ngIf="selectedGroup"> / {{ getSelectedGroupTitle(selectedGroup) }}</ng-container>
        </span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <i class="icon ni-warning-circle mx-2 text-warning"></i>
        <span class="text-truncate color-black fw-bold" [style.max-width.rem]="16">
          {{ (isDealer ? 'CONFIGURATOR_MODAL.ERRORS.NO_OFFER_SELECTED' : 'CONFIGURATOR_MODAL.ERRORS.NO_ORDER_SELECTED') | translate }}
        </span>
      </ng-container>
    </span>
  </ng-container>
</button>
