import { Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, ReplaySubject, Subscription, timer, Subject, interval, merge, combineLatest, forkJoin } from 'rxjs';
import { filter, map, share, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FullOrderArticleInterface } from '../core/models/full-order-article.model';
import { OrderArticleType, OrderArticleInterface } from '../core/models/order-article.model';
import { OrderInterface } from '../core/models/order.model';
import { UserInterface } from '../core/models/user.model';
import { FullScreenModeService } from '../core/services/full-screen-mode/full-screen-mode.service';
import { PriceListStoreHandlerService } from '../core/services/price-list/price-list-store-handler.service';
import { UserService } from '../core/services/user/user.service';
import { routeTypeByRoleAndOrderState, shouldAllowAccessOrderInRouteByRoleAndState } from '../permissions.config';
import { SaleMode } from '../shared/components/list-mode-switch/sale-mode.types';
import { OrderArticlesListRow, pageBreakKey } from './order-articles-list/order-articles-list.interface';
import { ExtraListElementInterface } from './order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { ExtraItemsService } from './order-articles-list/order-articles-list/components/extra-row/extra-items.service';
import { SelectedRowsService } from './order-articles-list/services/selected-rows/selected-rows.service';
import { OrdersService } from './orders.service';
import { OpenGroupsService } from './order-articles-list/order-articles-list/components/group/open-groups/open-groups.service';
import { pause } from '../core/operators/pause.operator';
import { ListModeSwitchService } from '../shared/components/list-mode-switch/list-mode-switch.service';
import { OrderListRouteType, OrderRoutePath, RouteTypeEnums } from "../core/enums/route-types.enum";
import { OrderImportState, OrderMigrationState, OrderState } from "../core/enums/order.state.enum";
import { TreeService } from '../core/tree/tree.service';
import { NavbarElements } from '../core/tree/navbar-elements';
import { ExtraListElementsTypes } from "./order-articles-list/order-articles-list/components/extra-row/extra-row-types.enum";

const MIN_POLL_INTERVAL_TIME = 1000;
const MAX_POLL_INTERVAL_TIME = 20000;
const POLL_STEP_MULTIPLIER = 1.5;
const STATUS_CHECKING_POLL_INTERVAL = 1000 * 10; // 10s

export enum TableMessageActionType {
  REORDER,
  PROFORMA_INVOICE
}

@Injectable()
export abstract class AbstractViewComponent implements OnInit, OnDestroy {
  private orderLoadTrigger$ = new Subject<void>();
  protected refreshArticles$: Subject<number> = new Subject<number>();
  private pauseRefreshArticles$: Subject<boolean> = new Subject<boolean>();

  private refreshOrderImportStatus$: Subject<number> = new Subject<number>();
  private refreshOrderMigrationStatus$: Subject<number> = new Subject<number>();
  private destroyRefreshOrderImportStatus$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private destroyRefreshOrderMigrationStatus$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  protected subscriptions: Subscription = new Subscription();
  protected orderLoaded: Subject<OrderInterface> = new Subject<OrderInterface>();

  loading = true;
  order: OrderInterface;
  articles: FullOrderArticleInterface[];
  extraItems: ExtraListElementInterface[];
  fullScreenModeEnabled: boolean;
  tableMessageActionType = TableMessageActionType;
  user: UserInterface;
  saleMode: SaleMode;
  orderId: number;
  refreshMessageTimer: Subscription;
  doOutdateCheck = false;
  outdatedDiffers = [];
  invalidArticlesExist = false;
  totalOrderVolume = 0;
  totalOrderWeight = 0;
  reloading = false;
  importState = OrderImportState.NONE;
  importStates = OrderImportState;
  importTotal = 0;
  imported = 0;
  indexBySentToAXStatus = '';
  migrationStates = OrderMigrationState;
  migratedPercent = 0;
  migrateTotal = 100;
  zipped: Observable<[FullOrderArticleInterface[], ExtraListElementInterface[]]>;
  getArticlesRequestSubscription = null;
  checkArticleMigrationVersions = true;

  treeService = inject(TreeService);

  private orderId$: Observable<number> = this.route.params.pipe(
    map(params => +params['id']),
    tap(id => { this.orderId = id; }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * Emits when order id changes, or orderLoadTrigger$ emits, or when sale mode changes.
   */
  private orderFetch$: Observable<[number, SaleMode]> = combineLatest([
    merge(
      this.orderId$,
      this.orderLoadTrigger$.pipe(
        withLatestFrom(this.orderId$),
        map(([, id]) => id)
      )
    ),
    this.listModeSwitchService.saleModeAsObservable(),
  ]).pipe(
    tap(([orderId, saleMode]) => this.saleMode = saleMode)
  );

  private data$: Observable<[OrderInterface, FullOrderArticleInterface[], ExtraListElementInterface[]]> = this.orderFetch$.pipe(
    // This switchMap emits observable
    switchMap(([orderId]) => of(
      this.ordersService.getOne(orderId).noCache().pipe(
        withLatestFrom(this.userService.fromStorage()),
        tap(([order, user]) => this.user = user),
        filter(([order, user]) => {
          return this.redirectIfOrderIsNotAccessibleByUser(user.role.name, this.route.routeConfig.data.id, order);
        }),
        map(([order, user]) => order),
        share() // multicast this observable because it'll be subscribed 3 times in next switchMap
      )
    )),
    switchMap(orderObservable => forkJoin([
      orderObservable,
      orderObservable.pipe(
        withLatestFrom(this.listModeSwitchService.saleModeAsObservable()),
        switchMap(([order, saleMode]) => {
          return this.ordersService.getArticles(order.id, saleMode, this.checkArticleMigrationVersions).noCache();
        })
      ),
      orderObservable.pipe(
        switchMap(order => this.extraListElementsService.fetch(order.id).noCache())
      )
    ])),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  orderUpdate$ = new Subject<OrderInterface>();
  order$: Observable<OrderInterface> = merge(
    this.data$.pipe(
      map(([order]) => order)
    ),
    this.orderUpdate$
  );

  articles$: Observable<FullOrderArticleInterface[]> = this.data$.pipe(
    tap(() => {
      this.checkArticleMigrationVersions = true;
    }),
    map(([, article]) => article)
  );

  extraItems$: Observable<ExtraListElementInterface[]> = this.data$.pipe(
    map(([, , extraItems]) => extraItems)
  );

  loading$: Observable<boolean> = merge(
    this.orderFetch$.pipe(
      map(() => true)
    ),
    this.data$.pipe(
      map(() => false)
    )
  );

  protected constructor(
    protected route: ActivatedRoute,
    protected ordersService: OrdersService,
    protected extraListElementsService: ExtraItemsService,
    protected fullScreenModeService: FullScreenModeService,
    protected router: Router,
    protected userService: UserService,
    protected selectedRowsService: SelectedRowsService,
    protected listModeSwitchService?: ListModeSwitchService,
    protected priceListStoreHandlerService?: PriceListStoreHandlerService,
    protected openGroupsService?: OpenGroupsService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.order$.pipe(
        withLatestFrom(this.userService.fromStorage())
      ).subscribe(([order, user]) => {
        this.order = order;
        this.importState = order.importState;

        if (this.isImportInProgress(order)) {
          this.startRefreshOrderImportStatusPolling();
        }

        if (this.isMigrationInProgress(order)) {
          this.startRefreshOrderMigrationStatusPolling();
        }

        this.changeGlobalPricelist(this.order);
        this.orderLoaded.next(order);

        if (
          [OrderState.WAITING, OrderState.AX_CONFIRMED, OrderState.AX_LOADED].includes(order.state) &&
          !this.isMigrationInProgress(order)
        ) {
          this.runRefreshMessage();
        }

        if (
          this.order &&
          user.lastUpdatedOrder &&
          user.lastUpdatedOrder.id === this.order.id &&
          !!user.lastUpdatedPageBreak
        ) {
          this.openGroupsService.open(user.lastUpdatedPageBreak as OrderArticlesListRow);
        }
      })
    );

    this.subscriptions.add(
      this.articles$.subscribe(articles => {
        this.validateOrderArticles(articles);

        this.articles = articles.map((article) => ({
          ...article,
          orderArticle: {
            ...article.orderArticle,
            belongsToLockedGroup: article.orderArticle.pageBreak?.type === ExtraListElementsTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP,
          },
        }));

        if (this.articles && this.articles.length) {
          this.startRefreshArticlesPolling();
        }

        let totalVolume = 0;
        let totalWeight = 0;
        this.articles.forEach(fullOrderArticle => {
          totalVolume +=
            (+fullOrderArticle.orderArticle.volume || 0) * +fullOrderArticle.orderArticle.quantity +
            this.getChildrenTotal('volume', fullOrderArticle.orderArticle.children);

          totalWeight +=
            (+fullOrderArticle.orderArticle.weight || 0) * +fullOrderArticle.orderArticle.quantity +
            this.getChildrenTotal('weight', fullOrderArticle.orderArticle.children);
        });

        this.totalOrderVolume = Number(totalVolume.toFixed(3));
        this.totalOrderWeight = Number(totalWeight.toFixed(3));
      })
    );

    this.subscriptions.add(
      this.extraItems$.subscribe(extraItems => {
        this.extraItems = extraItems;
      })
    );

    this.subscriptions.add(
      this.loading$.subscribe(state => {
        this.loading = state;
      })
    );

    this.subscriptions.add(
      this.refreshArticles$
        .pipe(
          switchMap(period => interval(period)),
          pause(this.pauseRefreshArticles$),
          withLatestFrom(this.refreshArticles$)
        )
        .subscribe(([count, time]) => {
          if (
            this.reloading ||
            this.loading ||
            this.importState === OrderImportState.IMPORTING ||
            OrderMigrationState.MIGRATING === this.order.migrationState
          ) {
            return;
          }
          this.refreshArticlesData();
          if (time * POLL_STEP_MULTIPLIER < MAX_POLL_INTERVAL_TIME) {
            this.refreshArticles$.next(time * POLL_STEP_MULTIPLIER);
          } else {
            this.refreshArticles$.next(MAX_POLL_INTERVAL_TIME);
          }
        })
    );
    this.subscriptions.add(
      this.refreshOrderImportStatus$
        .pipe(
          switchMap(period => interval(period)),
          pause(this.destroyRefreshOrderImportStatus$),
          withLatestFrom(this.refreshOrderImportStatus$)
        )
        .subscribe(([count, time]) => {
          this.refreshOrderImportStatus();
          this.refreshOrderImportStatus$.next(STATUS_CHECKING_POLL_INTERVAL);
        })
    );
    this.subscriptions.add(
      this.ordersService.refreshOrderImportStatusAsObservable().subscribe(result => {
        if (result) {
          this.load(this.orderId, this.saleMode);
          this.ordersService.refreshOrderImportStatus$.next(false);
        }
      })
    );
    this.subscriptions.add(
      this.refreshOrderMigrationStatus$
        .pipe(
          switchMap(period => interval(period)),
          pause(this.destroyRefreshOrderMigrationStatus$),
          withLatestFrom(this.refreshOrderMigrationStatus$)
        )
        .subscribe(([count, time]) => {
          this.refreshOrderMigrationStatus();
          this.refreshOrderMigrationStatus$.next(STATUS_CHECKING_POLL_INTERVAL);
        })
    );
    this.fullScreenModeService.enabledAsObservable().subscribe((enabled: boolean) => {
      this.fullScreenModeEnabled = enabled;
    });
    /**
     * After first article reload we need to create outdated items list
     * Then on every outdated articles observer change we will be able to compare and take some actions
     */
    this.subscriptions.add(
      this.ordersService.reloadingArticlesAsObservable().subscribe(status => {
        this.reloading = status;
        if (!this.articles) {
          return;
        }
        if (status) {
          this.doOutdateCheck = true;
        }

        if (!status && this.doOutdateCheck) {
          if (!this.outdatedDiffers.length) {
            this.outdatedDiffers = this.makeIndexByVersionOutdated(this.articles);
          }
        }
      })
    );
    /**
     * Compare outdatedArticlesAsObservable with previously stored list
     */
    this.subscriptions.add(
      this.ordersService.reloadedArticlesAsObservable().subscribe(freshArticles => {
        if (
          this.doOutdateCheck &&
          this.outdatedDiffers &&
          JSON.stringify(this.outdatedDiffers) !== JSON.stringify(this.makeIndexByVersionOutdated(freshArticles)) &&
          !this.reloading
        ) {
          // reset currently selected rows
          this.selectedRowsService.resetSelectedRows();

          // this.load(this.orderId, this.saleMode);
          this.articles = freshArticles;
          this.outdatedDiffers = this.makeIndexByVersionOutdated(this.articles);
        }
      })
    );

    this.subscriptions.add(
      this.ordersService.reloadedOrderAsObservable().subscribe(reloadedOrder => {
        // check if order status has changed and do some redirect if so
        if (this.order?.state !== reloadedOrder?.state && reloadedOrder.state === OrderState.AX_CONFIRMED) {
          this.router.navigate([`${OrderRoutePath.ROOT}/${OrderRoutePath.CONFIRMED}/`, reloadedOrder.id]);
        }
        this.order = reloadedOrder;
      })
    );

    this.subscriptions.add(
      this.ordersService.reloadUpdatedOrderAsObservable().subscribe(isReloaded => {
        if (isReloaded) {
          // setting this flag to false ensures that on the next articles fetch reload
          // won't be triggered thus preventing infinite reload loop
          this.checkArticleMigrationVersions = false;
          this.load(this.orderId, this.saleMode);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.cancelInFlightArticlesRequest();
    if (this.refreshMessageTimer) {
      this.refreshMessageTimer.unsubscribe();
    }
    this.stopRefreshArticlesPolling();
    this.stopRefreshOrderImportStatusPolling();
    this.stopRefreshOrderMigrationStatusPolling();
    this.fullScreenModeService.changeModeStatus(false);
  }

  protected load(id: number = this.order.id, saleMode?: SaleMode) {
    this.orderLoadTrigger$.next();
  }

  getChildrenTotal(key: string, children: OrderArticleInterface[]): number {
    if (!children.length) {
      return 0;
    }

    let summed = 0;
    children.forEach(child => {
      if (child[key] !== undefined) {
        summed += +child[key] * child.quantity;
      }

      if (child.children.length) {
        summed += this.getChildrenTotal(key, child.children);
      }
    });

    return summed;
  }

  protected validateOrderArticles(articles?: FullOrderArticleInterface[]) {
    const matched = articles.find(
      item =>
        item.orderArticle.type === OrderArticleType.CUSTOM_ITEM_TYPE &&
        (!this.isValidFullCode(item.orderArticle.fullCode) ||
          !this.isValidFullCode(item.orderArticle.code) ||
          !item.orderArticle.customPrice)
    );

    if (matched) {
      this.invalidArticlesExist = true;
      return;
    }

    this.invalidArticlesExist = false;
  }

  protected isValidFullCode(fullCode: string) {
    return fullCode && fullCode.indexOf('*') === -1;
  }

  protected refreshMessagesData() {
    this.ordersService
      .getOne(this.orderId)
      .noCache()
      .subscribe(order => {
        this.order.messagesCount = order.messagesCount;
      });
  }

  startRefreshMessageTimer() {
    return timer(10000, 20000).subscribe(() => {
      this.refreshMessagesData();
    });
  }

  runRefreshMessage() {
    if (this.refreshMessageTimer) {
      this.refreshMessageTimer.unsubscribe();
    }
    this.refreshMessageTimer = this.startRefreshMessageTimer();
  }

  public refreshArticlesData() {
    if (this.reloading) {
      return;
    }

    // we want to cancel all "in flight" request when leaving the page
    this.getArticlesRequestSubscription = this.ordersService
      .getArticles(this.orderId)
      .noCache()
      .subscribe(articles => {
        // updating by reference, so angular does not do full update on this
        // other fields might be needed to update to, for now it's only img
        // const notFoundFullOrderArticles: FullOrderArticleInterface[] = [];
        this.articles.forEach(article => {
          const foundArticle = articles.find(filterableArticle => filterableArticle.orderArticle.id === article.orderArticle.id);
          if (foundArticle) {
            article.orderArticle.img = foundArticle.orderArticle.img;
            article.orderArticle.imageGenerated = foundArticle.orderArticle.imageGenerated;
          }
        });

        const innerCount = calculateArticlesWithoutGeneratedImages(this.articles);

        if (!innerCount && !this.reloading) {
          this.stopRefreshArticlesPolling();
        }
      });
  }

  isImportInProgress(order: OrderInterface) {
    return OrderImportState.IMPORTING === order?.importState;
  }

  isMigrationInProgress(order: OrderInterface) {
    return OrderMigrationState.MIGRATING === order?.migrationState;
  }

  protected refreshOrderImportStatus() {
    if (this.reloading) {
      return;
    }
    // we want to cancel all "in flight" request when leaving the page
    this.subscriptions.add(
      this.ordersService
        .getImportStatus(this.orderId)
        .noCache()
        .subscribe(result => {
          if (!result || !Object.keys(result).length || result.status === this.importStates.ERROR) {
            this.importState = this.importStates.STUCK;
            this.stopRefreshOrderImportStatusPolling();
          } else if (result.status === this.importStates.IMPORTED) {
            this.importTotal = result.total;
            this.imported = result.imported + result.errors;

            this.ordersService.importInProgress$.next(false);
            this.importState = this.importStates.IMPORTED;
            this.stopRefreshOrderImportStatusPolling();
            this.load(this.orderId);
          } else {
            if (result.status === this.importStates.IMPORTING && result.total === result.errors) {
              // still importing but all of items are marked with errors
              this.importState = this.importStates.ERROR;
              this.ordersService.importInProgress$.next(false);
              this.stopRefreshOrderImportStatusPolling();
            }

            this.importTotal = result.total;
            this.imported = result.imported + result.errors;
          }
        })
    );
  }

  protected refreshOrderMigrationStatus() {
    if (this.reloading) {
      return;
    }
    // we want to cancel all "in flight" request when leaving the page
    this.subscriptions.add(
      this.ordersService
        .getMigrationStatus(this.orderId)
        .noCache()
        .subscribe(result => {
          if (result?.state
            && Object.values(OrderMigrationState).includes(result.state as OrderMigrationState)
          ) {
            this.order.migrationState = result.state;

            if (result.state === OrderMigrationState.MIGRATING) {
              this.migrateTotal = result.total;
              this.migratedPercent = result.migrated;

              return;
            }
            if (result.state === OrderMigrationState.MIGRATED) {
              this.migrateTotal = result.total;
              this.migratedPercent = result.migrated;
              this.load(this.orderId);
            }
          }

          this.stopRefreshOrderMigrationStatusPolling();
        })
    );
  }

  public cancelInFlightArticlesRequest() {
    if (this.getArticlesRequestSubscription) {
      this.getArticlesRequestSubscription.unsubscribe();

      // prevent form starting new request
      this.reloading = true;

      return true;
    }

    return false;
  }

  private startRefreshArticlesPolling() {
    if (!calculateArticlesWithoutGeneratedImages(this.articles) || this.reloading) {
      return;
    }

    this.pauseRefreshArticles$.next(false);
    this.refreshArticles$.next(MIN_POLL_INTERVAL_TIME);
  }

  private stopRefreshArticlesPolling() {
    this.pauseRefreshArticles$.next(true);
  }

  public startRefreshOrderImportStatusPolling() {
    this.destroyRefreshOrderImportStatus$.next(false);
    this.refreshOrderImportStatus$.next(STATUS_CHECKING_POLL_INTERVAL);
    this.refreshOrderImportStatus();
  }

  public startRefreshOrderMigrationStatusPolling() {
    this.destroyRefreshOrderMigrationStatus$.next(false);
    this.refreshOrderMigrationStatus$.next(STATUS_CHECKING_POLL_INTERVAL);
    this.refreshOrderMigrationStatus();
  }

  private stopRefreshOrderImportStatusPolling() {
    this.destroyRefreshOrderImportStatus$.next(true);
  }

  private stopRefreshOrderMigrationStatusPolling() {
    this.destroyRefreshOrderMigrationStatus$.next(true);
  }


  // Defined default function changeGlobalPricelist for those components which doesnt use ChangePricelistDecorator
  protected changeGlobalPricelist(order: OrderInterface) {}

  /**
   * Helper for tracking version outdate and migration changes
   */
  protected makeIndexByVersionOutdated(articles: FullOrderArticleInterface[]) {
    if (!articles) {
      return [];
    }

    return articles.map(item => ({
      item: `${item.orderArticle.id}--`
        + `${item.orderArticle.versionOutdated}--`
        + `${item.orderArticle.version ? item.orderArticle.version.id : 0}--`
        + `${item.orderArticle.orderArticleMigration ? item.orderArticle.orderArticleMigration.id : 0}`,
    }));
  }

  private redirectIfOrderIsNotAccessibleByUser(roleName: string, routeIdFromConfig: number, order: OrderInterface): boolean {
    if (!shouldAllowAccessOrderInRouteByRoleAndState(roleName, routeIdFromConfig, order)) {
      const navbarElement = NavbarElements.find(({ id }) => id === RouteTypeEnums.ORDERS);

      // check if user would be able to access the order on different route
      const possibleRoute = routeTypeByRoleAndOrderState(roleName, order);
      if (possibleRoute) {
        const secondElement = navbarElement.children.find(({path}) => path === possibleRoute.path);
        this.treeService.open(navbarElement, secondElement);
        this.router.navigate([`${OrderRoutePath.ROOT}/${possibleRoute.path}/`, order.id]);

        return false;
      }

      const secondElement = navbarElement.children.find(({id}) => id === OrderListRouteType.ORDERS);
      this.treeService.open(navbarElement, secondElement, true);

      return false;
    }

    return true;
  }

  shouldDisplayInaccurateWeightAndVolumeInfoText(): boolean {
    const containsCustomMadeItem = this.containsCustomMadeItems();
    const existsStandardWithoutWeightOrVolume = this.articles.some((article) => {
      const orderArticle = article.orderArticle;
      const isStandard = !orderArticle.type;
      const { weight, volume } = orderArticle;
      const weightOrVolumeIsNotKnown = !weight || +weight === 0 || !volume || volume === '0';

      return isStandard && weightOrVolumeIsNotKnown;
    });

    return containsCustomMadeItem || existsStandardWithoutWeightOrVolume;
  }

  containsCustomMadeItems(): boolean {
    return this.articles.some((article) =>
      [
        OrderArticleType.CUSTOM_ITEM_TYPE,
        OrderArticleType.PM_NARBUTAS_CUSTOM_ITEM_TYPE,
        OrderArticleType.PRICE_REQUEST_ITEM,
      ].includes(article.orderArticle.type)
    );
  }
}

export function makePositionUpdateObjects(items) {
  return items.map(item => {
    const pageBreak = item[pageBreakKey(item)];
    return {
      id: item.id,
      position: item.position,
      pageBreak: pageBreak ? pageBreak.id : null,
    };
  });
}

export const calculateArticlesWithoutGeneratedImages = (articles: FullOrderArticleInterface[]): number => {
  return articles.reduce((carry: number, article: FullOrderArticleInterface) => {
    if (!article.orderArticle.imageGenerated) {
      carry++;
    }

    return carry;
  }, 0);
};
