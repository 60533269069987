import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkify-html';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string | null): string | null {
    if (!value) {
      return value;
    }

    return linkifyHtml(value, {
      target: '_blank',
    });
  }
}
