import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { CertificateListResponse } from '../../../core/models/certificate.model';
import { Subscription } from 'rxjs';

export interface CertificatesFilterInterface {
  page?: number;
  'tags[]'?: number[];
}

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {

  constructor(
    private api: ApiService,
  ) { }

  filterAll(filter: CertificatesFilterInterface): CacheableObservable<CertificateListResponse> {
    return this.api.get('certificates', filter) as CacheableObservable<CertificateListResponse>;
  }

  download(tagIds: number[]): Subscription {
    return this.api.download('certificates/download', 'certificates.zip', { 'tags[]': tagIds });
  }
}
