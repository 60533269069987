import { Injectable } from '@angular/core';
import { SearchResponseInterface } from '../../../core/tree/search/search-service.model';
import { ApiService } from '../../../api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, distinctUntilChanged, map, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParams } from '../../../core/enums/query-params.enum';
import { Search } from '../../../core/tree/search/search.enum';
import { QueryParamsService } from '../../../core/services/query-params/query-params.service';
import { SortInterface } from '../../../core/models/sort.model';
import { SortFactory } from '../../../core/factory/sort.factory';
import { InquiriesSearchableListParentSearchService } from '../../inquiries-searchable-list-parent-search.service';
import { PriceRequestInterface, PriceRequestStatusEnum } from '../../../core/models/price-request.model';
import { ListMetaDataInterface } from '../../../core/models/list-meta-data.model';

export interface PriceRequestsSearchFullResponse {
  data: PriceRequestInterface[];
  meta: ListMetaDataInterface;
}

@Injectable()
export class PriceRequestsSearchService extends InquiriesSearchableListParentSearchService {
  placeholder = 'INQUIRIES.PRICE_REQUESTS.LIST.SEARCH_PLACEHOLDER';
  protected endpointUrl = (term) => `custom-made-price-requests/`;

  term$ = this.activatedRoute.queryParams.pipe(
    map(params => params[QueryParams.TERM] ?? null),
    distinctUntilChanged()
  );
  searchTerm: string;
  searchActive = false;
  searchActive$ = this.term$.pipe(
    map(term => {
      this.searchTerm = term;
      return term && term.length > Search.MIN_TERM_LENGTH;
    }),
    distinctUntilChanged(),
    tap(isActive => this.searchActive = isActive)
  );

  private subscription = new Subscription();
  private allowedQueryParams: QueryParams[] = [QueryParams.TERM];

  constructor(
    protected api: ApiService,
    protected translator: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(api, translator);
    this.subscription.add(this.searchActive$.subscribe());
  }

  changeTerm(term: string | null): void {
    if (term && term.length > Search.MIN_TERM_LENGTH) {
      const currentParams = QueryParamsService.getAllowedQueryParams(this.activatedRoute, this.allowedQueryParams);
      this.router.navigate([], {
        queryParams: { ...currentParams, term },
        replaceUrl: this.isSearchActive(),
        relativeTo: this.activatedRoute
      });
    } else if (!term && this.isSearchActive()) {
      this.restorePreSearchNavState();
    }
  }

  get(term: string, sort?: SortInterface): Observable<SearchResponseInterface> {
    let params = { keyword: term};
    if (sort) {
      params['sort'] = SortFactory.getString(sort);
    }

    params = { ...params };
    return this.api.get(this.endpointUrl(term), params).noCache().pipe(
      map(({data}) => data),
      switchMap((data) => {
        return this.combineTranslationWithSearchResponse(term, data, this.translator);
      })
    );
  }

  getDataWithMeta(term: string, sort?: SortInterface, page?: number, statuses?: PriceRequestStatusEnum[]): Observable<PriceRequestsSearchFullResponse> {
    let params = { keyword: term };

    if (sort) {
      params['sort'] = SortFactory.getString(sort);
    }

    if (page) {
      params['page'] = page;
    }

    if (statuses) {
      params['statuses'] = statuses;
    }

    return this.api.get(this.endpointUrl(term), params).noCache();
  }

  restorePreSearchNavState() {
    this.router.navigate([], {queryParams: { [QueryParams.TERM]: null }});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
