import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CertificateTagCategoryInterface, CertificateTagInterface } from '../../../../core/models/certificate.model';
import { CertificatesFiltersService } from '../../services/certificates-filters.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrl: './category-card.component.scss'
})
export class CategoryCardComponent implements OnInit, OnDestroy {
  @Input() category: CertificateTagCategoryInterface;
  @Input() categoryTags: CertificateTagInterface[] = [];
  @Input() isCollapsed = false;
  @Output() onToggleCollapse = new EventEmitter();

  subscription = new Subscription();

  subcategories = new Map<CertificateTagCategoryInterface, CertificateTagCategoryInterface[]>();
  selectedIds: number[] = [];
  isSubcategoryCollapsed = new Map<CertificateTagCategoryInterface, boolean>();
  hasTagsSelectedInCategory = false;
  onlyOneSubcategory = false;

  constructor(
    private certificateFilterService: CertificatesFiltersService,
  ) { }

  ngOnInit(): void {
    this.subcategories = this.certificateFilterService.groupTagsByCategory(this.categoryTags, true);
    this.onlyOneSubcategory = this.subcategories.size === 1;
    this.isSubcategoryCollapsed = new Map([...this.subcategories.keys()].map(category => [category, false]));

    this.subscription.add(
      this.certificateFilterService.getSelectedTagsIdsAsObservable().subscribe(ids => {
        this.selectedIds = ids;
        this.hasTagsSelectedInCategory = this.categoryTags.some(tag => ids.includes(tag.id));
      })
    );
  }

  onToggleCollapseClick(): void {
    this.onToggleCollapse.emit();
  }

  onTagChange(tagId: number): void {
    this.certificateFilterService.toggleTag(tagId);
  }

  onClear(event: MouseEvent): void {
    event.stopPropagation();
    this.certificateFilterService.clearByCategory(this.category.id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
