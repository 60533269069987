import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { SaleCoefficientsComponent } from './sale-coefficients/sale-coefficients.component';
import { ProductsComponent } from './sale-coefficients/products/products.component';
import { ComponentsComponent } from './sale-coefficients/components/components.component';
import { SettingsComponent } from './settings/settings.component';
import { BasicsComponent } from './settings/basics/basics.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { DefaultsComponent } from './settings/defaults/defaults.component';
import { ShippingAddressesComponent } from './settings/shipping-addresses/shipping-addresses.component';
import { ClientsComponent } from './settings/clients/clients.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SaleCoefficientsService } from './sale-coefficients/sale-coefficients.service';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CountryService } from '../core/services/country/country.service';
import { UserNotificationService } from '../shared/services/user-notification/user-notification.service';
import { TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '../core/models/custom-missing-translation-handler.model';
import { EditModalComponent } from './settings/shipping-addresses/edit-modal/edit-modal.component';
import { DeleteModalComponent } from './settings/shipping-addresses/delete-modal/delete-modal.component';
import { AddressService } from '../core/services/address/address.service';
import { CheckMarkModule } from '../ui-elements/check-mark/check-mark.module';
import { TextFieldModule } from '../ui-elements/text-field/text-field.module';
import { ClientEditModalComponent } from './settings/clients/edit-modal/edit-modal.component';
import { ClientDeleteModalComponent } from './settings/clients/delete-modal/delete-modal.component';
import { AddressModule } from '../address/address.module';
import { ClientModule } from '../client/client.module';
import { ClientPipe } from '../shared/pipes/clients/clients.pipe';
import { GenericModalModule } from '../ui-elements/generic-modal/generic-modal.module';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { FileUploadModule } from '../ui-elements/file-upload/file-upload.module';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { RouteByRolesGuard } from '../core/guards/route-by-roles.guard';
import { PermissionsModule } from '../permissions/permissions.module';
import { SplitIntoGroupsOfPipe } from './sale-coefficients/split-into-groups-of.pipe';
import { PasswordModule } from '../password/password.module';
import { ModalProfileOpenerComponent } from './modal-profile-opener/modal-profile-opener.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { AddressItemModule } from '../address/address-item/address-item.module';
import { CustomMadeFurnitureComponent } from './sale-coefficients/custom-made-furniture/custom-made-furniture.component';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    CheckMarkModule,
    TextFieldModule,
    AddressModule,
    ClientModule,
    GenericModalModule,
    LoaderModule,
    FileUploadModule,
    PermissionsModule,
    PasswordModule,
    AddressItemModule,
  ],
  declarations: [
    SaleCoefficientsComponent,
    ProductsComponent,
    ComponentsComponent,
    SettingsComponent,
    BasicsComponent,
    ProfileComponent,
    NotificationsComponent,
    DefaultsComponent,
    ShippingAddressesComponent,
    ClientsComponent,
    ClientEditModalComponent,
    ClientDeleteModalComponent,
    EditModalComponent,
    DeleteModalComponent,
    ClientPipe,
    ProfileModalComponent,
    SplitIntoGroupsOfPipe,
    ProfileFormComponent,
    ModalProfileOpenerComponent,
    CustomMadeFurnitureComponent,
  ],
  providers: [SaleCoefficientsService, AddressService, CountryService, UserNotificationService, RouteByRolesGuard]
})
export class UserModule { }
