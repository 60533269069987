export interface FileData {
  id: number;
  name: string;
  url: string;
  filename?: string;
  originalName?: string;
  extension?: string;
}

export const imageFileExtension = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg'];
