import { ClaimStatus } from "../enums/claim-state.enum";
import { AddressInterface } from './address.model';
import { FileData } from './file-data.model';
import { UserInterface } from "./user.model";

export enum ClaimType {
  GENERAL_DELIVERY_FEEDBACK = 'general_delivery_feedback',
  GENERAL_ITEM_FEEDBACK = 'general_item_feedback',
  VISUAL_DAMAGE_DELIVERY = 'visual_damage_delivery',
  VISUAL_DAMAGE_UNPACK = 'visual_damage_unpacking',
  MISSING_ITEMS = 'missing_items',
  MALFUNCTION = 'malfunction',
  INCORRECT_ITEM = 'incorrect_item',
  ASSEMBLY_ISSUES = 'assembly_issues',
  REPLACEMENT = 'replacement',
}

export const ClaimTypeTranslationMap = {
  [ClaimType.GENERAL_DELIVERY_FEEDBACK]: 'INQUIRIES.CLAIMS.TYPE.GENERAL_DELIVERY_FEEDBACK',
  [ClaimType.GENERAL_ITEM_FEEDBACK]: 'INQUIRIES.CLAIMS.TYPE.GENERAL_ITEM_FEEDBACK',
  [ClaimType.VISUAL_DAMAGE_DELIVERY]: 'INQUIRIES.CLAIMS.TYPE.VISUAL_DAMAGE_DELIVERY',
  [ClaimType.VISUAL_DAMAGE_UNPACK]: 'INQUIRIES.CLAIMS.TYPE.VISUAL_DAMAGE_UNPACK',
  [ClaimType.MISSING_ITEMS]: 'INQUIRIES.CLAIMS.TYPE.MISSING_ITEMS',
  [ClaimType.MALFUNCTION]: 'INQUIRIES.CLAIMS.TYPE.MALFUNCTION',
  [ClaimType.INCORRECT_ITEM]: 'INQUIRIES.CLAIMS.TYPE.INCORRECT_ITEM',
  [ClaimType.ASSEMBLY_ISSUES]: 'INQUIRIES.CLAIMS.TYPE.ASSEMBLY_ISSUES',
  [ClaimType.REPLACEMENT]: 'INQUIRIES.CLAIMS.TYPE.REPLACEMENT',
}

export enum ClaimFileType {
  ATTACHMENT = 'attachment',
  PROBLEM_PICTURE = 'problem_picture',
  CMR_DOCUMENT = 'cmr_document',
  DAMAGE = 'damage',
  ITEM_PICTURE = 'item_picture',
  PRODUCT_LABEL_PICTURE = 'product_label_picture',
}

export const ClaimFileTypeTranslationMap = {
  [ClaimFileType.ATTACHMENT]: 'INQUIRIES.CLAIMS.NEW.ATTACHMENTS',
  [ClaimFileType.PROBLEM_PICTURE]: 'INQUIRIES.CLAIMS.NEW.ATTACHMENTS',
  [ClaimFileType.CMR_DOCUMENT]: 'INQUIRIES.CLAIMS.NEW.CMR_ATTACHMENTS',
  [ClaimFileType.DAMAGE]: 'INQUIRIES.CLAIMS.NEW.DAMAGE_ATTACHMENTS',
  [ClaimFileType.ITEM_PICTURE]: 'INQUIRIES.CLAIMS.NEW.ATTACHMENTS',
  [ClaimFileType.PRODUCT_LABEL_PICTURE]: 'INQUIRIES.CLAIMS.NEW.PRODUCT_LABEL_PICTURE_ATTACHMENTS',
}

export interface ClaimTypeFileData extends FileData {
  type: ClaimFileType;
}

export interface ClaimTypeFile {
  type: ClaimFileType;
  file: ClaimTypeFileData;
  inProgress?: boolean;
}

export interface ClaimTypeWithFiles {
  claimTypeFiles?: ClaimTypeFile[];
  attachments?: ClaimTypeFile[];
  secondaryAttachments?: ClaimTypeFile[];
}

// ASSEMBLY_ISSUES claim type
export interface AssemblyIssuesClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface AssemblyIssuesClaimTypeInterface {
  type: ClaimType.ASSEMBLY_ISSUES;
  claimTypeItems: AssemblyIssuesClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments: ClaimTypeFile[];
}

// MISSING_ITEMS claim type
export interface MissingItemsClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface MissingItemsClaimTypeInterface {
  type: ClaimType.MISSING_ITEMS;
  claimTypeItems: MissingItemsClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments: ClaimTypeFile[];
}

// GENERAL_DELIVERY_FEEDBACK claim type
export interface GeneralDeliveryFeedbackClaimTypeItemInterface {
  productCode?: string;
  description: string;
}

export interface GeneralDeliveryFeedbackClaimTypeInterface {
  type: ClaimType.GENERAL_DELIVERY_FEEDBACK;
  claimTypeItems: GeneralDeliveryFeedbackClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments?: ClaimTypeFile[];
  secondaryAttachments?: ClaimTypeFile[];
}

// VISUAL_DAMAGE_UNPACK claim type
export interface VisualDamageUnpackClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface VisualDamageUnpackClaimTypeInterface {
  type: ClaimType.VISUAL_DAMAGE_UNPACK;
  claimTypeItems: VisualDamageUnpackClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments: ClaimTypeFile[];
}


// GENERAL_ITEM_FEEDBACK claim type
export interface GeneralItemFeedbackClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface GeneralItemFeedbackClaimTypeInterface {
  type: ClaimType.GENERAL_ITEM_FEEDBACK;
  claimTypeItems: GeneralItemFeedbackClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments?: ClaimTypeFile[];
}

// VISUAL_DAMAGE claim type
export interface VisualDamageClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface VisualDamageClaimTypeInterface {
  type: ClaimType.VISUAL_DAMAGE_DELIVERY;
  claimTypeItems: VisualDamageClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments?: ClaimTypeFile[];
  secondaryAttachments?: ClaimTypeFile[];
}

export type ClaimTypeItemInterfaceType = AssemblyIssuesClaimTypeItemInterface | GeneralDeliveryFeedbackClaimTypeItemInterface | VisualDamageUnpackClaimTypeItemInterface | GeneralItemFeedbackClaimTypeItemInterface | VisualDamageClaimTypeItemInterface | IncorrectItemClaimTypeItemInterface | MalfunctionClaimTypeItemInterface | ReplacementClaimTypeItemInterface | MissingItemsClaimTypeItemInterface;

export type ClaimTypeItemType = AssemblyIssuesClaimTypeInterface | GeneralDeliveryFeedbackClaimTypeInterface | VisualDamageUnpackClaimTypeInterface | GeneralItemFeedbackClaimTypeInterface | VisualDamageClaimTypeInterface | IncorrectItemClaimTypeInterface | MalfunctionClaimTypeInterface | ReplacementClaimTypeInterface | MissingItemsClaimTypeInterface | GeneralDeliveryFeedbackClaimTypeInterface;

export interface ClaimInterface {
  id?: number | null;
  status?: ClaimStatus;
  title: string | null;
  orderNum?: string | null;
  purchaseOrderNum?: string | null;
  updatedAtTimestamp?: number;
  claimTypes?: ClaimTypeItemType[];
  address?: AddressInterface | null;
  jiraIssueKey?: string | null;
  claimUsersCount: number;
  userId: number;
}

export interface ClaimListItemInterface {
  id: number;
  status: ClaimStatus;
  dealer: UserInterface;
  title: string;
  orderNum: string | null;
  purchaseOrderNum: string | null;
  jiraIssueKey: string | null;
  updatedAtTimestamp: number;
}

export interface ClaimUpdateInterface extends Pick<ClaimInterface, 'title' | 'orderNum' | 'claimTypes' | 'purchaseOrderNum'> {
  address?: number | null;
};

// INCORRECT_ITEM claim type
export interface IncorrectItemClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface IncorrectItemClaimTypeInterface {
  type: ClaimType.INCORRECT_ITEM;
  claimTypeItems: IncorrectItemClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments: ClaimTypeFile[];
  secondaryAttachments?: ClaimTypeFile[];
}

// REPLACEMENT claim type
export interface ReplacementClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface ReplacementClaimTypeInterface {
  type: ClaimType.REPLACEMENT;
  claimTypeItems: ReplacementClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments?: ClaimTypeFile[];
}

// MALFUNCTION claim type
export interface MalfunctionClaimTypeItemInterface {
  productCode: string;
  quantity: number;
  description: string;
}

export interface MalfunctionClaimTypeInterface {
  type: ClaimType.MALFUNCTION;
  claimTypeItems: MalfunctionClaimTypeItemInterface[];
  claimTypeFiles?: ClaimTypeFile[];
  attachments?: ClaimTypeFile[];
}
