<app-generic-modal
  [closable]="true"
  [heading]="'CONFIGURATOR_MODAL.PRICE_DETAILS.HEADER' | translate"
  [style.width.rem]="63.5"
>
  <ng-template appModalBody>
    <app-loader #loader [cssClasses]="'loader--white'" [loading]="loading"></app-loader>

    <!-- Items -->
    <table class="table border-bottom mb-4">
      <thead class="border-bottom color-gray small">
      <tr>
        <th class="border-0 ps-0 pe-1 pt-2 pb-3">{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.ITEM' | translate }}</th>
        <th class="border-0 px-1 pt-2 pb-3">{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.CODE' | translate }}</th>
        <th class="border-0 px-1 pt-2 pb-3">{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.TEXTURE' | translate }}</th>
        <th class="border-0 text-end px-1 pt-2 pb-3">{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.QUANTITY' | translate }}</th>
        <th class="border-0 text-end px-1 pt-2 pb-3">{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.DISCOUNT' | translate }}</th>
        <th class="border-0 text-end ps-1 pe-0 pt-2 pb-3 text-nowrap">{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.TOTAL' | translate }}</th>
      </tr>
      </thead>
      <tbody class="color-black">
      <tr *ngFor="let item of items; let i = index">
        <td class="border-bottom-1 py-3 ps-0 pe-1">{{ item.longText }}</td>
        <td class="border-bottom-1 py-3 px-1">{{ item.code }}</td>
        <td class="border-bottom-1 py-3 px-1 text-nowrap">
                <span *ngFor="let material of item.orderArticleMaterials" class="me-1">
                  {{ material.code }}
                </span>
        </td>
        <td class="border-bottom-1 text-end py-3 px-1">{{ item.quantity }}</td>
        <td class="border-bottom-1 text-end py-3 px-1">{{ determinePrice(item) | priceFormat }}</td>
        <td class="border-bottom-1 text-end py-3 ps-1 pe-0">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ item.totalPrice | priceFormat: {zeroReplacement: '-'} }}
          </visibility-by-variables>
        </td>
      </tr>

      </tbody>
    </table>

    <!-- Totals with discount applied -->
    <div class="d-flex justify-content-end text-end" *ngIf="details?.discount; else noDiscountPrice">
      <div>
        <div>{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.DETAILS.INITIAL_PRICE' | translate }}</div>
        <div>{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.DETAILS.DISCOUNT' | translate }}</div>
        <div>{{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.DETAILS.TOTAL_PRICE_WITH_DISCOUNT' | translate }}</div>
      </div>
      <div [style.width.rem]="8.75">
        <div>
          {{
          details?.price
            | priceFormat
            | pricelistCurrencySymbol
            | visibilityByVariables: {propertyKeys: 'hidePrices', replacement: '-'}
          }}
        </div>
        <div>
          {{
          details?.discount
            | priceFormat
            | pricelistCurrencySymbol
            | visibilityByVariables: {propertyKeys: 'hidePrices', replacement: '-'}
          }}
        </div>
        <div>
          {{
          details?.totalPrice
            | priceFormat
            | pricelistCurrencySymbol
            | visibilityByVariables: {propertyKeys: 'hidePrices', replacement: '-'}
          }}
        </div>
      </div>
    </div>

    <!-- Total, no discount -->
    <ng-template #noDiscountPrice>
      <div class="d-flex justify-content-end text-end">
        <div>
          {{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.DETAILS.TOTAL_PRICE' | translate }}
        </div>
        <div [style.width.rem]="8.75">
            {{
            details?.price
              | priceFormat
              | pricelistCurrencySymbol
              | visibilityByVariables: {propertyKeys: 'hidePrices', replacement: '-'}
            }}
        </div>
      </div>
    </ng-template>
  </ng-template>
</app-generic-modal>
