<div
  *ngIf="imageFile; else noImage"
  class="row-image ratio ratio-1x1 border position-relative"
  [ngClass]="backgroundColorClass"
  [style.background-image]="'url(' + ((imageFile.url | resizeImage : imageSizes.SIZE_140x140 | uncache | async) ?? '') + ')'"
>
  <a *ngIf="downloadable" [href]="imageFile.url" appClickStopPropagation download>
    <i
      [title]="'GALLERY.DOWNLOAD' | translate"
      class="bottom-0 color-white deep-hover hover-color-gray-500 icon ni-file-down position-absolute right-0 pb-0125r"
    ></i>
  </a>
</div>

<ng-template #noImage>
  <div class="ratio ratio-1x1 border position-relative" [ngClass]="[iconColorClass, backgroundColorClass]">
    <span class="icon icon-xlarge d-flex align-items-center justify-content-center" [ngClass]="defaultIcon"></span>
  </div>
</ng-template>