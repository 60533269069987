<ng-template #rt let-r="result" let-t="term">
	<app-search-highlight
    *ngIf="r.id; else noResults"
    [text]="r.title"
    [searchString]="t"
    highlightClass="fw-bold"
  ></app-search-highlight>
</ng-template>

<div class="position-relative">
  <input
    #input
    #instance="ngbTypeahead"
    type="text"
    class="form-control bg-white py-2 search-input"
    popupClass="w-100 tags-search-typeahead-dropdown overflow-auto shadow-lg"
    [class.is-invalid]="isInvalid"
    [name]="name || ''"
    [placeholder]="placeholder || ''"
    [ngbTypeahead]="search"
    [disabled]="isDisabled"
    [resultTemplate]="rt"
    [ngModel]="model"
    (ngModelChange)="modelChange($event)"
    [inputFormatter]="formatter"
    (selectItem)="onSelect($event)"
    (blur)="onTouchedCallback()"
  />
  <div class="position-absolute right-0 top-0 bottom-0 d-flex align-items-center p-2">
    <button
      *ngIf="!input.value; else clearButton"
      class="btn btn-default p-0 border-0 d-flex"
      (click)="input.focus()"
    >
      <i class="icon ni-search"></i>
    </button>
  </div>
</div>

<ng-template #clearButton>
  <button class="btn btn-default p-0 border-0 d-flex" (click)="onClear($event)">
    <i class="icon ni-cancel-fill"></i>
  </button>
</ng-template>

<ng-template #noResults>
  <div class="p-3 text-center no-results-item">
    {{ 'CERTIFICATES.FILTERS.NO_RESULTS' | translate }}
  </div>
</ng-template>
