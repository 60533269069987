import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OrderInterface } from '../core/models/order.model';
import { OrdersService } from '../orders/orders.service';
import { SelectionMenuService } from './selection-menu.service';
import { PermissionsDecisionMakerServiceInterface } from '../permissions/decision-maker.service';
import { CAN_EDIT_OFFERS, PermissionActions } from '../permissions.config';
import { ExtraListElementInterface } from '../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { APISortOrderConverted } from '../core/enums/sort-order.enum';
import { CreateNewItemEventInterface, NewItemType } from './new-item/new-item.component';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PERMISSIONS_DECISION_MAKER_SERVICE } from '../permissions/injection-tokens';
import { LoaderService } from '../ui-elements/loader/loader.service';
import { LoaderComponent } from '../ui-elements/loader/loader.component';
import { UserService } from '../core/services/user/user.service';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { UserRole } from '../core/enums/user-role.enum';
import { OrderState } from '../core/enums/order.state.enum';
import { UserInterface } from '../core/models/user.model';
import { PriceRequestsService } from '../inquiries/custom-made-price-requests/services/price-requests.service';
import { PriceRequestInterface, PriceRequestStatusEnum } from '../core/models/price-request.model';
import { PriceRequestsSearchService } from '../inquiries/custom-made-price-requests/services/price-requests-search.service';

export interface OrderSelectOnChangeEventInterface {
  order: OrderInterface;
  group?: ExtraListElementInterface;
}

export enum TabTypes {
  OFFERS = 1,
  ORDERS = 2,
  PRICE_REQUESTS = 3,
  SEARCH = 4,
}

export interface SelectionMenuPaginationInterface {
  page: number;
  totalPages: number;
}

export interface SearchInterface {
  term$: Subject<string>;
  term: string;
}

@Component({
  selector: 'app-selection-menu',
  templateUrl: './selection-menu.component.html',
  styleUrls: ['./selection-menu.component.scss'],
  providers: [OrdersService, PriceRequestsSearchService],
})
export class SelectionMenuComponent implements OnInit, OnDestroy {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  @Output('click-outside') clickOutside: EventEmitter<any> = new EventEmitter<any>();

  selectedOrder: OrderInterface;
  selectedPriceRequest: PriceRequestInterface;
  selectedGroup;

  orderState = OrderState;
  permissionActions = PermissionActions;
  orders: OrderInterface[] = [];
  priceRequests: PriceRequestInterface[] = [];
  groupsListVisible = false;
  previousTab: TabTypes = null;
  activeTab: TabTypes;
  userRole = UserRole;
  pagination: SelectionMenuPaginationInterface = {
    page: 1,
    totalPages: null,
  };

  search: SearchInterface = {
    term$: new Subject(),
    term: '',
  };

  tabTypes = TabTypes;
  isFormActive = false;
  newItemTypes = NewItemType;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private loaderService: LoaderService,
    private ordersService: OrdersService,
    public selectionMenuService: SelectionMenuService,
    public userService: UserService,
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE)
    private permissionsDecisionMakerService: PermissionsDecisionMakerServiceInterface,
    private priceRequestsService: PriceRequestsService,
    private priceRequestsSearchService: PriceRequestsSearchService
  ) {}

  ngOnInit() {
    if (this.activeTab === this.tabTypes.PRICE_REQUESTS) {
      this.subscriptions.add(this.fetchPriceRequests().subscribe());
      this.subscriptions.add(
        this.selectionMenuService.selectedPriceRequestAsObservable().subscribe((selectedPriceRequest) => {
          this.selectedPriceRequest = selectedPriceRequest;
        })
      );
    } else {
      this.subscriptions.add(this.fetchOrders().subscribe());
    }

    this.subscriptions.add(
      this.selectionMenuService.selectedOrderAsObservable().subscribe((selectedOrder) => {
        this.selectedOrder = selectedOrder;
            if (selectedOrder && [TabTypes.OFFERS, TabTypes.ORDERS].includes(this.activeTab)) {
            this.groupsListVisible = true;
          }
      })
    );
    this.subscriptions.add(
      this.selectionMenuService.selectedGroupAsObservable().subscribe((selectedGroup) => (this.selectedGroup = selectedGroup))
    );
 

    this.subscriptions.add(
      this.search.term$
        .asObservable()
        .pipe(
          distinctUntilChanged(),
          debounceTime(500),
          switchMap((value: string) => {
            this.search.term = value;
            this.pagination.page = 1;

            if (this.previousTab === this.tabTypes.PRICE_REQUESTS) {
              this.priceRequests = [];
              return this.fetchPriceRequests();
            }

            this.orders = [];
            return this.fetchOrders(this.activeTab);
          })
        )
        .subscribe()
    );
  }

  fetchPriceRequests(append = false) {
    return this.loaderService
      .load(
        this.priceRequestsSearchService.getDataWithMeta(
          this.search.term,
          { prop: 'updatedAt', dir: APISortOrderConverted.DESC },
          this.pagination.page,
          [PriceRequestStatusEnum.DRAFT]
        ),
        this.loader
      )
      .pipe(
        tap(({ data, meta }) => {
          if (append) {
            this.priceRequests.push(...data);
          } else {
            this.priceRequests = data;
          }

          this.pagination.totalPages = meta['total-pages'];
        })
      );
  }

  fetchOrders(tab?: TabTypes, append = false) {
    return this.getStatesByTab(tab).pipe(
      switchMap((states) => {
        return this.loaderService
          .load(
            this.ordersService
              .filterAll(
                {
                  states,
                  title: this.search.term,
                  page: this.pagination.page,
                },
                { prop: 'updatedAt', dir: APISortOrderConverted.DESC }
              )
              .noCache(),
            this.loader
          )
          .pipe(
            tap(({ data, meta }) => {
              if (append) {
                this.orders.push(...data);
              } else {
                this.orders = data;
              }

              this.pagination.totalPages = meta['total-pages'];
            })
          );
      })
    );
  }

  onPriceRequestSelect(priceRequest: PriceRequestInterface) {
    this.selectionMenuService.selectPriceRequest(priceRequest);
    this.selectionMenuService.close();
  }

  onRowClick(order: OrderInterface) {
    if (order.isSendingToAX) {
      return;
    }

    this.selectionMenuService.openOrder(order);
    this.groupsListVisible = true;
  }

  onCreateNewOrder(order: CreateNewItemEventInterface) {
    this.isFormActive = false;
    this.loaderService.load(this.ordersService.create(order), this.loader).subscribe((newOrder) => {
      this.orders = [newOrder, ...this.orders];
    });
  }

  onCreateNewPriceRequest(priceRequest: CreateNewItemEventInterface) {
    this.isFormActive = false;
    this.loaderService.load(this.priceRequestsService.create(priceRequest), this.loader).subscribe(({ data }) => {
      this.priceRequests = [data, ...this.priceRequests];
    });
  }

  onSearch(event) {
    const { value } = event.target;
    this.search.term$.next(value);
  }

  onTabBack(tab: TabTypes) {
    this.selectedGroup = null;
    this.onTabChange(tab);
  }

  onTabChange(tab: TabTypes) {
    this.groupsListVisible = false;
    this.previousTab = this.activeTab;
    this.activeTab = tab;
    this.isFormActive = false;

    switch (tab) {
      case TabTypes.SEARCH:
        setTimeout(() => this.searchInput.nativeElement.focus(), 0);
        break;
      case TabTypes.PRICE_REQUESTS:
        this.search.term = '';
        this.pagination.page = 1;
        this.priceRequests = [];
        this.fetchPriceRequests().subscribe();
        break;
      default:
        this.search.term = '';
        this.pagination.page = 1;
        this.orders = [];
        this.groupsListVisible = false;
        this.fetchOrders(tab).subscribe(({ data }) => {
          if (this.selectedOrder && this.selectedGroup) {
            this.onRowClick(this.selectedOrder);
          }
        });
    }
  }

  onFormToggle() {
    this.isFormActive = !this.isFormActive;
  }

  isTabActive(tab) {
    return tab === this.activeTab;
  }

  private getStatesByTab(tab: TabTypes): Observable<OrderState[]> {
    const decideStates = (innerTab: TabTypes, user: UserInterface) => {
      let states = [];
      this.activeTab = innerTab;
      switch (innerTab) {
        case TabTypes.OFFERS:
          states = [OrderState.DRAFT];
          break;
        case TabTypes.ORDERS:
          if (user && user.role.name === UserRole.ROLE_PM_NARBUTAS) {
            states = [OrderState.DRAFT, OrderState.WAITING];
          } else {
            states = [OrderState.WAITING];
          }
          break;
        case TabTypes.SEARCH:
          switch (user.role.name) {
            case UserRole.ROLE_DEALER:
              states = [OrderState.DRAFT];
              break;
            case UserRole.ROLE_PM_NARBUTAS:
              states = [OrderState.DRAFT, OrderState.WAITING];
              break;
            case UserRole.ROLE_PM:
            case UserRole.ROLE_PM_RU:
              states = [OrderState.WAITING];
              break;
            default:
              states = [OrderState.DRAFT, OrderState.WAITING];
          }
          break;
      }
      return states;
    };

    return combineLatest(this.userService.fromStorage(), this.permissionsDecisionMakerService.shouldAllow([CAN_EDIT_OFFERS])).pipe(
      switchMap(([user, shouldAllow]) => {
        let statuses = [];

        if (!tab) {
          if (shouldAllow) {
            statuses = decideStates(TabTypes.OFFERS, user);
          } else {
            statuses = decideStates(TabTypes.ORDERS, user);
          }
        } else {
          statuses = decideStates(tab, user);
        }
        return of(statuses);
      })
    );
  }

  onScroll() {
    this.pagination.page++;

    if (this.activeTab === TabTypes.PRICE_REQUESTS) {
      this.fetchPriceRequests(true).subscribe();
    } else {
      this.fetchOrders(this.activeTab, true).subscribe();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
