import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { PriceRequestItemInterface, PriceRequestItemStatusEnum } from '../../../core/models/price-request-item.model';
import { PriceRequestItemsService } from '../../../inquiries/custom-made-price-requests/services/price-request-items.service';
import { ClarificationModalComponent } from '../../../inquiries/custom-made-price-requests/modals/clarification-modal/clarification-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-price-request-item-status-icon',
  templateUrl: './price-request-item-status-icon.component.html',
  styleUrls: ['./price-request-item-status-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PriceRequestItemStatusIconComponent implements OnDestroy {
  @Input() item: PriceRequestItemInterface;
  @Input() locatedInsideOffer? = false;
  @Output() clarificationCanceled = new EventEmitter<boolean>();
  @Output() clarificationSubmitted = new EventEmitter<void>();

  private subscriptions = new Subscription();

  constructor(private priceRequestItemsService: PriceRequestItemsService) {}

  shouldDisplayReadyToOrderPopover(): boolean {
    return !this.item.clarificationQuestion || (this.item.clarificationQuestion && !!this.item.clarificationAnswer);
  }

  isCanceled(): boolean {
    return this.item.status === PriceRequestItemStatusEnum.CANCELED;
  }

  openClarification(): void {
    const modalRef = this.priceRequestItemsService.openPriceRequestItemClarificationModal([this.item]);

    const instance = modalRef.componentInstance as ClarificationModalComponent;
    instance.openedFromOffer = this.locatedInsideOffer;

    this.subscriptions.add(
      instance.closed.subscribe((shouldRefresh) => {
        this.clarificationCanceled.emit(shouldRefresh);
      })
    );

    this.subscriptions.add(
      instance.submitted.subscribe(() => {
        this.clarificationSubmitted.emit();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
