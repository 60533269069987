<div
  *ngIf="!isDisabled"
  class="droppable-area d-flex justify-content-center align-items-center position-relative"
  [ngClass]="{
    'dragging-over': draggingOverDropzone,
    'bg-gray-200': !draggingOverDropzone,
    'upload-error': fileUploadError !== FileUploadErrors.NONE
  }"
  (click)="resetError()"
  (clickOutside)="resetError()"
>
  <div
    *ngIf="config"
    class="w-100 h-100 position-absolute"
    [class.disabled]="!priceRequestId"
    [dropzone]="config"
    (success)="onUploadSuccess($event)"
    (dragEnter)="onDraggingOverDropzoneStart()"
    (dragLeave)="onDraggingOverDropzoneEnd()"
    (drop)="onDraggingOverDropzoneEnd()"
  ></div>

  <div class="droppable-area-info d-flex flex-column justify-content-center align-items-center small">
    <svg-icon src="/assets/images/drag-drop-indicator/drag-drop-indicator.svg" class="color-gray-500"></svg-icon>

    <div class="mt-3 mb-2">
      <ng-container [ngSwitch]="fileUploadError">
        <span *ngSwitchCase="FileUploadErrors.FILE_TOO_LARGE" class="fw-bold color-danger">{{
          'DRAG_DROP.FORMS.ERRORS.TOO_LARGE' | translate
        }}</span>
        <span *ngSwitchCase="FileUploadErrors.UNSUPPORTED_FILE_TYPE" class="fw-bold color-danger">{{
          'DRAG_DROP.FORMS.ERRORS.UNSUPPORTED_TYPE' | translate
        }}</span>
        <span *ngSwitchCase="FileUploadErrors.MAX_NUMBER_OF_PARALLEL_UPLOADS_EXCEEDED" class="fw-bold color-danger">{{
          'DRAG_DROP.FORMS.ERRORS.MAX_NUMBER_OF_PARALLEL_UPLOADS_EXCEEDED' | translate : { count: maxNumberOfParallelUploads }
        }}</span>
      </ng-container>

      <div [ngClass]="{ 'd-none': fileUploadError !== FileUploadErrors.NONE }">
        <button type="button" class="btn btn-link fw-bold no-outline align-baseline p-0" [ngClass]="'dropzone-button-' + dropZoneName">
          {{ 'DRAG_DROP.FORMS.CLICKABLE_INFO_TEXT' | translate }}
        </button>
        <span class="color-gray-700">{{ 'DRAG_DROP.FORMS.NON_CLICKABLE_INFO_TEXT' | translate }}</span>
      </div>
    </div>

    <span *ngIf="[FileUploadErrors.NONE, FileUploadErrors.FILE_TOO_LARGE].includes(fileUploadError)" class="color-gray-500">{{
      'DRAG_DROP.FORMS.MAX_SIZE_INFO_GB' | translate : { size: maxFileSizeInGB }
    }}</span>
  </div>
</div>

<div class="d-flex flex-wrap gap-1 mt-2">
  <app-file-preview
    *ngFor="let file of this.model; trackBy: trackById; let i = index"
    [file]="file.file"
    [removable]="!isDisabled"
    (remove)="onRemoveFile(file)"
    [inProgress]="file.inProgress"
  ></app-file-preview>
</div>
