<div class="d-flex flex-column gap-4 sticky">
  <form [formGroup]="form">
    <app-tags-search-input
      formControlName="search"
      [tags]="tags"
      (tagSelected)="onTagSelected($event)"
      [placeholder]="'CERTIFICATES.FILTERS.SEARCH' | translate"
    ></app-tags-search-input>
  </form>

  <app-category-card
    *ngFor="let categoryGroup of categories | keyvalue"
    [category]="categoryGroup.key"
    [categoryTags]="categoryGroup.value"
    [isCollapsed]="expandedCategory?.id !== categoryGroup.key.id"
    (onToggleCollapse)="onToggleCategoryCollapse(categoryGroup.key)"
  ></app-category-card>
</div>
