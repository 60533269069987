import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { CertificateTagInterface, CertificateTagCategoryInterface } from '../../../core/models/certificate.model';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CertificatesFiltersService {
  private tags: CertificateTagInterface[] = [];
  private tags$ = new BehaviorSubject<CertificateTagInterface[]>([]);
  private selectedTags$ = new BehaviorSubject<CertificateTagInterface[]>([]);

  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  fetchTags(): CacheableObservable<CertificateTagInterface[]> {
    return this.api.get('certificate-tags').pipe(
      map(({ data }) => data)
    ) as CacheableObservable<CertificateTagInterface[]>;
  }

  processTags(tags: CertificateTagInterface[]): CertificateTagInterface[] {
    const subcategoriesCount = this.getSubcategoriesCount(tags);

    return tags.map(tag => ({ 
      ...tag, 
      belongsToOnlySubcategory: subcategoriesCount.get(tag.category.id) === 1
    }));
  }

  getSubcategoriesCount(tags: CertificateTagInterface[]): Map<number, number> {
    const groupedTags = this.groupTagsByCategory(tags);

    const subcategoriesCount = new Map<number, number>();
    groupedTags.forEach((tags, category) => {
      const subcategories = new Set(tags.map(({ subcategory }) => subcategory.id));
      subcategoriesCount.set(category.id, subcategories.size);
    });

    return subcategoriesCount;
  }
    

  getTagsAsObservable(): Observable<CertificateTagInterface[]> {
    if (!this.tags.length) {
      this.fetchTags().subscribe(tags => {
        const processedTags = this.processTags(tags);
        this.tags = processedTags;
        this.tags$.next(processedTags);

        const queryParams = this.activatedRoute.snapshot.queryParams;
        const tagsInParams = queryParams.tags;

        if(tagsInParams) {
          const selectedTagsIds = tagsInParams.split(',').map(Number);
          const selectedTags = this.tags.filter(({ id }) => selectedTagsIds.includes(id));
          this.selectedTags$.next(selectedTags);
        }
      });
    }

    return this.tags$.asObservable();
  }

  groupTagsByCategory(tags: CertificateTagInterface[], groupBySubcategory = false): Map<CertificateTagCategoryInterface, CertificateTagInterface[]> {
    const tagsMap = new Map<CertificateTagCategoryInterface, CertificateTagInterface[]>();
    tags.forEach(tag => {
      const key = groupBySubcategory ? tag.subcategory : tag.category;

      if (key.isVisibleInFilters === false) {
        return;
      }

      const category = [...tagsMap.keys()].find(category => category.id === key.id);

      if (category) {
        tagsMap.get(category).push(tag);
      } else {
        tagsMap.set(key, [tag]);
      }
    });
    return tagsMap;
  }

  toggleTag(tagId: number): void {
    const selectedTags = this.selectedTags$.getValue();
    const tag = this.tags.find(({ id }) => id === tagId);

    if (selectedTags.includes(tag)) {
      this.selectedTags$.next(selectedTags.filter(({ id }) => id !== tagId));
    } else {
      this.selectedTags$.next([...selectedTags, tag]);
    }
    this.updateUrlParams();
  }

  selectTag(tagId: number): void {
    const selectedTags = this.selectedTags$.getValue();
    const tag = this.tags.find(({ id }) => id === tagId);

    if(selectedTags.includes(tag)) {
      return;
    }

    this.selectedTags$.next([...this.selectedTags$.getValue(), tag]);
    this.updateUrlParams();
  }

  unselectTag(tagId: number): void {
    const selectedTags = this.selectedTags$.getValue();
    this.selectedTags$.next(selectedTags.filter(({ id }) => id !== tagId));
    this.updateUrlParams();
  }

  getSelectedTagsAsObservable(): Observable<CertificateTagInterface[]> {
    return this.selectedTags$.asObservable();
  }

  getSelectedTagsIdsAsObservable(): Observable<number[]> {
    return this.activatedRoute.queryParams.pipe(
      map(({ tags }) => {
        if(tags) {
          return tags.split(',').map(Number);
        }
        return [];
      })
    );
  }

  clearByCategory(categoryId: number): void {
    const selectedTags = this.selectedTags$.getValue();
    this.selectedTags$.next(selectedTags.filter(({ category }) => category.id !== categoryId));
    this.updateUrlParams();
  }

  updateUrlParams(): void {
    const selectedTagsIds = this.selectedTags$.getValue().map(({ id }) => id);
    const joinedTags = selectedTagsIds.join(',');
    
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        tags: joinedTags ? joinedTags : null
      },
      queryParamsHandling: 'merge'
    });
  }
}
