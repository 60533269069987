import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PriceRequestItemFileInterface, PriceRequestItemInterface, PriceRequestItemStatusEnum } from '../../../../core/models/price-request-item.model';
import * as CustomValidators from '../../../../shared/class/custom-validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceRequestItemsService } from '../../services/price-request-items.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../ui-elements/toast/toast.service';
import { Subscription, debounceTime, distinctUntilChanged, filter, map, retry, skip, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-based-on-standard-price-request-item-form-modal',
  templateUrl: './based-on-standard-price-request-item-form-modal.component.html',
  styleUrls: ['./based-on-standard-price-request-item-form-modal.component.scss'],
})
export class BasedOnStandardPriceRequestItemFormModalComponent implements OnInit, OnDestroy {
  @Input() item: PriceRequestItemInterface;
  @Input() priceRequestId: number;
  @Input() loading: boolean = false;
  @Output() saved = new EventEmitter<void>();
  @Output() discarded = new EventEmitter<void>();
  
  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  form: FormGroup = null;
  isUploadingFiles = false;
  isSaving = false;
  isDraft = false;
  private subscriptions = new Subscription();

  constructor(
    private priceRequestItemsService: PriceRequestItemsService,
    private activeModal: NgbActiveModal,
    private translator: TranslateService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (!this.form && !this.loading) {
      this.constructForm();
      this.initializeSubscriptions();
    }
  }

  constructForm(fields?: PriceRequestItemInterface): void {
    this.isDraft = fields?.status === PriceRequestItemStatusEnum.DRAFT;
    
    this.form = new FormGroup({
      title: new FormControl<string>(fields?.title ?? '', Validators.required),
      quantity: new FormControl<number>(fields?.quantity, [Validators.required, Validators.min(1), CustomValidators.numbers]),
      description: new FormControl<string>(fields?.description ?? '', Validators.required),
      dimensions: new FormControl<string>(fields?.dimensions ?? '', Validators.maxLength(255)),
      finishes: new FormControl<String>(fields?.finishes, [Validators.required, CustomValidators.notEmpty('finishes')]),
      customMadePriceRequestItemFiles: new FormControl<PriceRequestItemFileInterface[]>(fields?.customMadePriceRequestItemFiles ?? []),
    });
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(
      this.form.valueChanges.subscribe((formValue: PriceRequestItemInterface) => {
        this.isUploadingFiles = !!formValue.customMadePriceRequestItemFiles.find(({ inProgress }) => inProgress);
      })
    );

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          debounceTime(2000),
          startWith(this.form.getRawValue()),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skip(1),
          filter(() => {
            // autosaves only if all touched fields are valid and item is not being saved
            return (
              Object.values(this.form.controls).every((control) => !control.touched || (control.touched && control.valid)) && !this.isSaving
            );
          }),
          switchMap((formValue) => {
            const itemCompleted = this.form.valid;
            return this.priceRequestItemsService
              .update(this.priceRequestId, this.item.id, formValue, this.form.valid)
              .pipe(map(() => itemCompleted));
          }),
          retry(1)
        )
        .subscribe((itemCompleted) => {
          let suffix;

          if (itemCompleted) {
            this.isDraft = false;
            suffix = 'SUCCESSFULLY_SAVED';
          } else {
            suffix = 'SUCCESSFULLY_SAVED_DRAFT';
          }

          this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`).subscribe((translation: string) => {
            this.toastService.success(translation);
          });
        })
    );
  }

  onSave(): void {
    this.isSaving = true;

    this.priceRequestItemsService.update(this.priceRequestId, this.item.id, this.form.getRawValue(), true).subscribe({
      next: () => {
        this.translator
        .get('INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.SUCCESSFULLY_SAVED')
        .subscribe((translation: string) => {
          this.toastService.success(translation);
          this.saved.emit();
          this.activeModal.dismiss();
        });
        
      },
      error: () => {
        this.isSaving = false;
      },
    });
  }

  onClose(): void {
    if (this.isSaving || this.isUploadingFiles) {
      return;
    }
    
    if (!this.isDraft) {
      this.saved.emit();
      this.activeModal.dismiss();
      return;
    }

    this.onDiscard();
  }

  onDiscard(): void {
    if (this.isSaving || this.isUploadingFiles) {
      return;
    }

    const suffix = this.isDraft ? 'DRAFT_DISCARDED' : 'DISCARDED';

    this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`).subscribe((translation: string) => {
      this.priceRequestItemsService.delete(this.priceRequestId, this.item.id).subscribe(() => {
        this.toastService.success(translation);
        this.discarded.emit();
        this.activeModal.dismiss();
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
