import {EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {OrderInterface} from '../../core/models/order.model';
import { SelectionMenuService } from '../../selection-menu/selection-menu.service';
import { OrderSelectOnChangeEventInterface } from '../../selection-menu/selection-menu.component';
import {BehaviorSubject, Observable, of, Subscription, throwError} from 'rxjs';
import {UserRole} from '../../core/enums/user-role.enum';
import {
  OrderArticleFormItemType,
  OrderArticleInterface,
  orderArticleTypeToFormValue,
  OtherSupplierOrderArticleInterface,
} from '../../core/models/order-article.model';
import {OrderArticleService} from '../../core/services/order-article/order-article.service';
import {LoaderService} from '../../ui-elements/loader/loader.service';
import {UserService} from '../../core/services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {FileUploadService} from '../../ui-elements/file-upload/file-upload.service';
import {UserInterface} from '../../core/models/user.model';
import {catchError, debounceTime, distinctUntilChanged, first, switchMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TextFieldThemeTypes} from "../../ui-elements/text-field/text-field/theme-types.enum";
import {QuantitySpinnerChangeEvent} from "../../ui-elements/quantity-spinner/quantity-spinner.component";
import {ucsMaxLength} from "../../shared/class/custom-validators";
import {ToastService} from '../../ui-elements/toast/toast.service';
import {FileSizeEnum} from '../../api.service';
import {ListModeSwitchService} from "../../shared/components/list-mode-switch/list-mode-switch.service";
import {SaleMode} from "../../shared/components/list-mode-switch/sale-mode.types";
import {OrderState} from "../../core/enums/order.state.enum";
import { PageBreakInterface } from '../../core/models/page-break.model';

export interface CustomOrderArticleItemChangesInterface {
  item: OtherSupplierOrderArticleInterface;
  valid: boolean;
}

@Injectable()
export abstract class CustomArticleModalModel implements OnInit, OnDestroy {
  @ViewChild('content') content: TemplateRef<any>;

  @Input() selectedOrder?: OrderInterface;
  @Input() selectedGroup?;
  @Input() position?: number;
  @Input() id: number;

  @Output() orderSelect: EventEmitter<OrderSelectOnChangeEventInterface> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() saveToOtherOrder: EventEmitter<any> = new EventEmitter();

  item: OtherSupplierOrderArticleInterface = {
    quantity: 1,
    img: null,
    longText: '',
    category: '',
  };
  userRole = UserRole;
  fileUrl: string = null;
  user?: UserInterface;
  calculatedPrice = 0;
  initialised = false;
  textFieldThemeTypes = TextFieldThemeTypes;
  fileTooBig = false;

  inputLongText = new UntypedFormControl(null, [
    ucsMaxLength(1000),
  ]);

  get canOrder(): boolean {
    return Object.keys(this.isValidState)
      .map(key => this.isValidState[key])
      .filter(state => !state)
      .length === 0
  }

  protected isValidState = {
    formGroup: false,
    orderSelected: false,
    quantity: true,
  };

  protected abstract itemType: OrderArticleFormItemType;
  protected modalRef?: NgbModalRef;

  loading = new BehaviorSubject(true);
  protected subscriptions: Subscription = new Subscription();

  protected constructor(
    protected fb: UntypedFormBuilder,
    protected selectionMenuService: SelectionMenuService,
    protected orderArticleService: OrderArticleService,
    protected loaderService: LoaderService,
    protected userService: UserService,
    protected translateService: TranslateService,
    protected fileUploadService: FileUploadService,
    protected toastService: ToastService,
    protected modalService: NgbModal,
    protected listModeSwitchService: ListModeSwitchService,
  ) {
  }

  ngOnInit() {
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.user = user;
    });

    if (this.id) {
      this.orderArticleService
        .getOne(this.id, this.listModeSwitchService.getSaleMode())
        .noCache()
        .pipe(
          first(),
          tap(item => {
            if (!this.selectedGroup && item.pageBreak) {
              this.selectedGroup = item.pageBreak;
            }
            this.item = {
              ...this.item,
              ...item,
              pageBreak: (item.pageBreak as PageBreakInterface)?.id,
            };

            this.initialiseItem();
            this.loading.next(false);
          })
        )
        .subscribe();
    } else {
      this.initialiseItem();
      this.initialised = true;
      this.loading.next(false);
    }
  }

  addInputLongText() {
    this.inputLongText.setValue(this.item.longText?.replace(/\r\n/g, '\n'));
    this.inputLongText.updateValueAndValidity();

    this.changeValidState({inputLongText: this.inputLongText.valid});

    this.subscriptions.add(
      this.inputLongText.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          this.item.longText = value.trim();
          this.changeValidState({inputLongText: this.inputLongText.valid});
        })
    );
  }

  changeValidState(partialState: {}) {
    this.isValidState = {...this.isValidState, ...partialState};
  }

  onFileChange(event: { file?: File; fileUrl?: string }) {
    this.item.img = event.file;
    this.fileTooBig = event.file && event.file.size > FileSizeEnum.SIZE_10MB;

    this.fileUrl = event.fileUrl;
    if (this.item.id) {
      this.orderArticleService
        .updateCustomByItemType(this.item.id, {img: this.item.img}, this.itemType, false)
        .pipe(first())
        .subscribe();
    }
  }

  onQuantityChange(event: QuantitySpinnerChangeEvent) {
    this.item.quantity = event.quantity;
    this.isValidState.quantity = event.valid;
    this.recalculatePrice(this.item).pipe(first()).subscribe();
  }

  onOrderSelect(event: OrderSelectOnChangeEventInterface) {
    this.orderSelect.emit(event);
    this.item.order = event.order?.id;
    this.changeValidState({orderSelected: Boolean(this.item.order ?? false)})
    if (event.group && event.group.id) {
      this.item.pageBreak = event.group.id;
    }
  }

  onFormGroupItemChange(event: CustomOrderArticleItemChangesInterface) {
    const previousPrice = this.item.pricelistPrice;
    const nextPrice = event.item.pricelistPrice;
    const previousCustomPrice = this.item.customPrice;
    const nextCustomPrice = event.item.customPrice;
    const previousCode = this.item.code;
    const nextCode = event.item.code;

    // Do not use spreader operator as it will create a new object
    this.item = Object.assign(this.item, event.item);

    if (1
      && this.initialised
      && (previousPrice !== nextPrice || previousCode !== nextCode || previousCustomPrice !== nextCustomPrice)
    ) {
      this.recalculatePrice(this.item).pipe(first()).subscribe();
    }

    this.changeValidState({formGroup: event.valid});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSave(): void {
    if (this.canOrder) {
      let observable: Observable<any>;

      // for dealer - we mark code with * to indicate thar PM will have to review this code
      if (
        this.selectedOrder.state === OrderState.DRAFT &&
        this.itemType === OrderArticleFormItemType.CUSTOM_NON_STANDARD_ITEM &&
        this.user.role.name === this.userRole.ROLE_DEALER
      ) {
        if (!this.item.code) {
          this.item.code = `NS-${moment().format('YYMMDD-HHmmss')}`;
        }
        this.item.code += '*';
      }

      const item = {
        longText: this.item.longText,
        shortText: this.item.shortText,
        quantity: this.item.quantity,
        code: this.item.code,
        system: this.item.system,
        category: this.item.category,
        details: this.item.details ? this.item.details : null,
        customPrice: this.item.customPrice,
        pricelistPrice: this.item.pricelistPrice,
        typeInformation: this.item.typeInformation,
        order: this.item.order,
        pageBreak: this.item.pageBreak ? this.item.pageBreak : null,
        weight: this.item.weight,
        volume: this.item.volume,
        height: this.item.height,
        width: this.item.width,
        depth: this.item.depth,
      };

      this.loading.next(true);

      if (this.item.id) {
        observable = this.orderArticleService.updateCustomByItemType(this.item.id, item, this.itemType);
      } else {
        observable = this.orderArticleService.createCustomByItemType({...item, img: this.item.img as File}, this.itemType);
      }

      observable
        .pipe(
          catchError((res) => {
            const {error} = res;

            if (res.status === 413) {
              this.fileTooBig = true;
              return throwError(() => res);
            }

            if (!(error && error.data)) {
              return throwError(error);
            }
            const data = error.data;
            const {price, isCustomPriceApplied} = data;
            const translationKey =
              price && isCustomPriceApplied
                ? 'ORDER_ARTICLES.PRICES_DIFFER.WITH_CUSTOM_PRICE'
                : 'ORDER_ARTICLES.PRICES_DIFFER.NO_CUSTOM_PRICE';
            return this.translateService.get(translationKey, {price, isCustomPriceApplied}).pipe(
              switchMap(translation => {
                this.toastService.danger(translation);
                return throwError(error);
              })
            );
          }),
        )
        .subscribe({
          next: (res: any) => {
            if (res.error === 'ER_100103#001') {
              const data = res.data;
              const {price, isCustomPriceApplied} = data;
              const translationKey =
                price && isCustomPriceApplied
                  ? 'ORDER_ARTICLES.PRICES_DIFFER.WITH_CUSTOM_PRICE'
                  : 'ORDER_ARTICLES.PRICES_DIFFER.NO_CUSTOM_PRICE';
              this.translateService.get(translationKey, {price, isCustomPriceApplied}).subscribe(translation => {
                this.toastService.danger(translation);

                return throwError({error: res.error});
              });

              return;
            }

            if (this.item.order !== this.selectedOrder.id) {
              this.saveToOtherOrder.emit();
              return;
            }
            this.save.emit();
          },
          error: () => {
            this.loading.next(false);
          }
        });
    }
  }

  abstract getWarningMessage(): string;

  abstract getButtonMessage(): Observable<string>;

  recalculatePrice(item: OtherSupplierOrderArticleInterface): Observable<OrderArticleInterface> {
    const {
      customPrice,
      pricelistPrice,
      quantity,
      order,
      id,
      type,
    } = item;
    const isPm = [
      UserRole.ROLE_PM,
      UserRole.ROLE_PM_RU,
    ].includes(this.user.role.name);
    const isPmNarbutas = UserRole.ROLE_PM_NARBUTAS === this.user.role.name;

    if (!id) {
      if (isPm) {
        // PM enters custom purchase price
        this.calculatedPrice = customPrice * quantity;
      } else if (isPmNarbutas) {
        // Narbutas PM enters sale/purchase price
        this.calculatedPrice = pricelistPrice * quantity;
      } else {
        // In Dealer case total price have to reflect list mode
        this.calculatedPrice = (
          SaleMode.SALE === this.listModeSwitchService.getSaleMode()
            ? pricelistPrice
            : customPrice
        ) * quantity;
      }

      return of(item as OrderArticleInterface);
    }

    return this.orderArticleService
      .updatePrice(
        id,
        {
          customPrice: Number(customPrice) || 0,
          pricelistPrice: Number(pricelistPrice) || 0,
          quantity,
          order,
          itemType: orderArticleTypeToFormValue(type),
        },
        this.listModeSwitchService.getSaleMode(),
      )
      .pipe(
        tap((response: OrderArticleInterface) => {
          this.calculatedPrice = parseFloat(response.formattedTotalConfigurationPrice);
        })
      );
  }

  private initialiseItem() {
    if (this.selectedOrder) {
      this.item.order = this.selectedOrder.id;
      this.fileUrl = this.item.img as string;
    }
    if (this.selectedGroup) {
      this.item.pageBreak = this.selectedGroup.id;
    }

    if (typeof this.position === 'number') {
      this.item.position = this.position;
    }

    // for dealer - we mark code with * to indicate thar PM will have to review this code
    // and on loading item for editing we remove this * symbol from code to not confuse dealer
    if (
      this.selectedOrder.state === OrderState.DRAFT &&
      this.itemType === OrderArticleFormItemType.CUSTOM_NON_STANDARD_ITEM &&
      this.user.role.name === this.userRole.ROLE_DEALER &&
      this.item.code &&
      this.item.code.indexOf('*') !== -1
    ) {
      this.item.code = this.item.code.replace('*', '');
    }

    this.changeValidState({orderSelected: this.item.order ?? false});
    this.addInputLongText();

    this.calculatedPrice = parseFloat(this.item.formattedTotalConfigurationPrice);
    this.initialised = true;
  }

  open() {
    this.modalService.dismissAll();

    this.modalRef = this.modalService.open(this.content, {
      backdrop: 'static',
      windowClass: 'custom-article-modal',
      size: 'cs',
      scrollable: true,
      keyboard: false,
    });
  }

  close() {
    this.modalRef?.close();
  }
}
