<div
  class="d-flex flex-wrap gap-2"
  [ngClass]="{ 'mb-4 pb-2': selectedTags.length > 0 }"
>
  <div *ngFor="let tag of selectedTags" class="d-flex align-items-center gap-2 bg-white rounded p-1 ps-2 mb-1">
    <span>{{ (tag.belongsToOnlySubcategory ? tag.category.title : tag.subcategory.title) + ':' + tag.title }}</span>
    <button class="btn btn-white p-0" (click)="onTagRemove(tag.id)">
      <i class="icon ni-close d-flex"></i>
    </button>
  </div>
</div>
