import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateCardComponent } from './card/card.component';
import { CertificatesListComponent } from './list/list.component';
import { LoaderModule } from '../../ui-elements/loader/loader.module';
import { SharedModule } from '../../shared/shared.module';
import { UncacheableResourceModule } from '../../uncacheable-resource/uncacheable-resource.module';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '../../core/models/custom-missing-translation-handler.model';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CertificateFiltersComponent } from './filters/filters.component';
import { CategoryCardComponent } from './filters/category-card/category-card.component';
import { NgbCollapseModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TagsSearchInputComponent } from './filters/tags-search-input/tags-search-input.component';
import { SelectedTagsListComponent } from './filters/selected-tags-list/selected-tags-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CatalogueModule } from '../../catalogue/catalogue.module';

@NgModule({
  declarations: [
    CertificateCardComponent,
    CertificatesListComponent,
    CertificateFiltersComponent,
    CategoryCardComponent,
    TagsSearchInputComponent,
    SelectedTagsListComponent,
  ],
  imports: [
    CommonModule,
    LoaderModule,
    SharedModule,
    UncacheableResourceModule,
    TranslateModule.forChild({
        missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    RouterModule,
    InfiniteScrollModule,
    NgbCollapseModule,
    NgbTypeaheadModule, 
    ReactiveFormsModule,
    FormsModule,
    CatalogueModule,
  ],
  exports: [
    CertificateCardComponent,
    CertificatesListComponent,
    CertificateFiltersComponent,
    CategoryCardComponent,
    TagsSearchInputComponent,
    SelectedTagsListComponent,
  ]
})
export class CertificatesModule { }
