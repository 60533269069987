<div class="container-xxl">
  <div class="row">
    <div class="col">
      <h1 class="mt-4 mb-3">{{ 'SALE_COEFFICIENTS.SALE_COEFFICIENTS' | translate }}</h1>
      <div class="card rounded-0 border-0 shadow-sm mb-3">
        <div class="card-header p-0">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a routerLink="products" routerLinkActive="active" class="nav-link">
                {{ 'SALE_COEFFICIENTS.PRODUCTS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="components" routerLinkActive="active" class="nav-link">
                {{ 'SALE_COEFFICIENTS.COMPONENTS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="custom-made-furniture" routerLinkActive="active" class="nav-link">
                {{ 'SALE_COEFFICIENTS.CUSTOM_MADE_FURNITURE' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
