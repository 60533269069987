import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { ConversationMessageType } from '../core/enums/messages-type.enum';
import { FileData } from '../core/models/file-data.model';
import { MessagesInterface } from '../core/models/messages.model';
import { UserInterface } from '../core/models/user.model';
import { UserService } from '../core/services/user/user.service';
import { LinkifyPipe } from '../shared/pipes/linkify/linkify.pipe';
import { SharedModule } from '../shared/shared.module';
import { FilesListComponent } from '../ui-elements/communication-tab/files-list/files-list.component';
import { LoaderComponent } from '../ui-elements/loader/loader.component';
import { MessageDetailsValueFormatPipe } from './message-details-value-format.pipe';

@Component({
    selector: 'app-messages-window',
    templateUrl: './messages-window.component.html',
    styleUrls: ['./messages-window.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule, LoaderComponent, FilesListComponent, LinkifyPipe, MessageDetailsValueFormatPipe]
})
export class MessagesWindowComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() messages: MessagesInterface[] = [];
  @Input() loading: boolean;
  @Output() markAsSeen: EventEmitter<any> = new EventEmitter<any>();

  conversationMessageType = ConversationMessageType;
  user: UserInterface;
  initLoad = true;

  private subscription = new Subscription();

  constructor(
    private userService: UserService,
    private el: ElementRef
  ) {}

  ngAfterViewChecked(): void {
    const hostEl = this.el.nativeElement;
    if (
      this.messages.length &&
      hostEl.scrollHeight >= hostEl.clientHeight &&
      this.initLoad
    ) {
      this.markAsSeen.emit(); // emit event if div doesn't have scrollbar
      this.initLoad = false;
      this.el.nativeElement.scrollTo({
        top: hostEl.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  ngOnInit() {
    this.userService.fromStorage().subscribe(user => {
      this.user = user;
    });

    this.subscription.add(
      fromEvent(this.el.nativeElement, 'scroll').subscribe(() => {
        this.detectScrollToBottom()
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  trackByMessageId(index, item: MessagesInterface) {
    return item.id;
  }

  detectScrollToBottom() {
    const el = this.el.nativeElement;
    if (el.scrollHeight - el.scrollTop === el.clientHeight) {
      this.markAsSeen.emit();
    }
  }

  getMessageFiles(files): FileData[] {
    return files.map(file => ({
      id: file.id,
      name: file.name,
      filename: file.filename,
      url: file.url,
      extension: file.extension,
    }));
  }
}
