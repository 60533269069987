<div class="container-xxl">
  <div class="row">
    <div class="col">
      <h1 class="my-4">{{ 'CERTIFICATES.TITLE' | translate }}</h1>
    </div>
    <div class="col" *ngIf="tagIds.length > 0 && certificates.length > 0">
      <div class="d-flex justify-content-end align-items-center h-100">
        <button
          class="btn btn-light d-flex gap-2 no-outline"
          (click)="onDownloadAll()"
        >
          <span>{{ 'CERTIFICATES.DOWNLOAD_ALL' | translate }}</span>
          <i class="icon ni-download"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row gap-4">
    <!-- Filters -->
    <div
      class="flex-shrink-0 pe-sm-2"
      [style.width.px]="320"
    >
      <app-certificate-filters></app-certificate-filters>
    </div>

    <!-- Content -->
     <div class="flex-grow-1">
      <app-selected-tags-list></app-selected-tags-list>

      <ng-container *ngIf="pagination.totalPages !== null; else skeleton">
        <ng-container *ngIf="certificates.length > 0; else noCertificates">
          <div 
            class="row row-cols-1 row-cols-sm-3"
            infiniteScroll
            [infiniteScrollDistance]="0.5"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
            [infiniteScrollDisabled]="isLoading || (pagination.totalPages && pagination.page >= pagination.totalPages)"
          >
            <div
              *ngFor="let certificate of certificates; let i = index; trackBy: trackByFn"
              class="col mb-4 pb-2"
            >
              <app-certificate-card [certificate]="certificate"></app-certificate-card>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <div class="row row-cols-1 row-cols-sm-3 flex-grow-1">
    <div
      *ngFor="let i of [1, 2, 3, 4, 5, 6]"
      class="col mb-4 pb-2"
    >
      <app-certificate-card></app-certificate-card>
    </div>
  </div>
</ng-template>

<ng-template #noCertificates>
  <div class="w-100 text-center py-5 my-3">
    {{ 'CERTIFICATES.NO_DATA' | translate }}
  </div>
</ng-template>
