import { Component, Input } from '@angular/core';
import { CertificateInterface } from '../../../core/models/certificate.model';

@Component({
  selector: 'app-certificate-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CertificateCardComponent {
  @Input() certificate: CertificateInterface = null;
}
