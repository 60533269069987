import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({ selector: '[appScrollTo]' })
export class ScrollToDirective implements OnChanges {
  /**
   * Used only to scroll on any event from outside
   */
  @Input('appScrollTo') trigger: any;
  @Input('appScrollToDirection') direction? = 'bottom';

  constructor(private elRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trigger.currentValue && changes.trigger.previousValue) {
      setTimeout(() => {
        const height = this.elRef.nativeElement.scrollHeight;
        try {
          this.elRef.nativeElement.scrollTo({
            top: height,
            behavior: 'smooth'
          });
          if (this.isMainScrollBarVisible()) {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            });
          }
        } catch (error) {
          /**
           * For IE11 support
           */
          this.elRef.nativeElement.scrollTop = this.elRef.nativeElement.scrollHeight;
          if (this.isMainScrollBarVisible()) {
            document.body.scrollTop = document.body.scrollHeight;
          }
        }
      });
    }
  }

  private isMainScrollBarVisible(): boolean {
    return document.body.scrollHeight > document.body.clientHeight;
  }


}
