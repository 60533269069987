import { ClientInterface } from '../../core/models/client.model';
import { CurrencyInterface } from '../../core/models/currency.model';

export module DocumentTemplate {
  export enum HeadlineBackgroundType {
    DARK,
    LIGHT,
  }

  export enum CustomerDataType {
    OPTIONAL,
    SELECT_CUSTOMER,
    FROM_CUSTOMER,
  }

  export enum Type {
    PROPOSAL,
    INVOICE,
  }

  export enum ListType {
    DETAILED = 'detailed',
    CONFIGURATION = 'configuration',
  }

  export enum ImageType {
    SMALL,
    LARGE,
    HIDDEN,
  }

  export enum MaterialType {
    IMAGE = 'image',
    CODE = 'code',
    TITLE = 'title',
  }

  export enum CurrencyDisplayType {
    NAME = 'name',
    SYMBOL = 'symbol',
  }

  export interface Data {
    templates: Template[];
    order: Order;
    client: Client;
    language: Language;
    invoiceNumber?: any;
    invoiceDateTimestamp?: number | string;
    orderPayments: OrderPayment[];
    clientDataType?: CustomerDataType;
    selectedTemplate?: Template;
    currencies?: CurrencyInterface[];
    currency?: CurrencyInterface;
    currencyDisplayType?: string;
    exchangeRate?: number;
  }

  export interface Dealer {
    address: string;
    iban: string;
    bank: string;
    bankCode: string;
    swift: string;
    companyCode: string;
    companyName: string;
    vatCode: string;
    vatRate: string | number | null;
    logo: Logo;
  }

  export interface Manager {
    fullName: string;
    email: string;
    phone: string;
  }

  export interface Logo {
    url?: string;
    file?: File;
  }

  export interface Template {
    photoType: ImageType;
    isSystem: boolean | number;
    isCategory: boolean | number;
    isDescription: boolean | number;
    materialTypes: MaterialType[];
    isDimensions: boolean | number;
    isDiscount: boolean | number;
    isVolumeAndWeight: boolean | number;
    isVat: boolean | number;
    isFullCode: boolean | number;
    backgroundType: HeadlineBackgroundType;
    dealer: Dealer;
    manager: Manager;
    title: string;
    id: number;
    type: Type;
    currency: CurrencyInterface;
    currencyDisplayType: string;
    exchangeRate: number;
    listType: ListType.CONFIGURATION | ListType.DETAILED;
  }

  export interface Currency {
    id: number;
    name: string;
  }

  export interface Order {
    id: number;
    currency: Currency;
    client: ClientInterface | null;
    hasDiscounts: boolean;
  }

  export interface Client {
    clientCompanyName: string;
    clientCompanyCode: string;
    clientVatCode: string;
    clientAddress: string;
    clientProposalTitle: string;
    clientLogo: Logo;
    clientProposalDateTimestamp: number | string;
  }

  export interface Language {
    id: number;
    language: string;
    short: string;
    translated?: string;
  }

  export interface OrderPayment {
    id?: number;
    title?: string;
    percentage?: number;
    paymentDueDateTimestamp?: number | string;
    price?: any;
  }

  // update
  export module Update {
    export interface Dealer {
      address: string;
      iban: string;
      bank: string;
      bankCode: string;
      swift: string;
      companyName: string;
      companyCode: string;
      vatCode: string;
      vatRate: string | number | null;
      logo?: File;
    }

    export interface Manager {
      fullName: string;
      email: string;
      phone: string;
    }

    export interface OrderPayment {
      title: string;
      percentage: number;
      paymentDueDate: number | string;
    }

    export interface Client {
      clientCompanyName: string;
      clientAddress: string;
      clientCompanyCode: string;
      clientVatCode: string;
      clientProposalTitle: string;
      clientProposalDate: string;
      clientLogo?: File;
    }

    export interface Template {
      language: number;
      order: number;
      photoType: ImageType;
      isSystem: boolean | number;
      isCategory: boolean | number;
      isDescription: boolean | number;
      materialTypes: MaterialType[];
      isDimensions: boolean | number;
      isDiscount: boolean | number;
      isVolumeAndWeight: boolean | number;
      isVat: boolean | number;
      isFullCode: boolean | number;
      backgroundType: number;
      clientDataType: number;
      dealer: Dealer;
      manager: Manager;
      orderPayments: OrderPayment[];
      invoiceNumber: string;
      invoiceDate: string;
      client: Client;
      currency: number;
      currencyDisplayType: string;
      exchangeRate: number;
    }

    export type TemplatePartial = Partial<DocumentTemplate.Update.Template>;
  }
}
