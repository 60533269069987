import {NEVER, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';

export interface SearchResponseInterface {
  data: any[];
  message: string;
  query: string;
}

@Injectable()
export abstract class AbstractSearchService {
  placeholder = 'SEARCH.PLACEHOLDER';
  protected endpointUrl: (term) => string;

  term$: Observable<string | null> = NEVER;
  searchActive = false;
  searchActive$: Observable<boolean> = NEVER;

  protected constructor(
    protected api: ApiService,
    protected translator: TranslateService
  ) {
  }

  changeTerm(term: string | null): void {}

  combineTranslationWithSearchResponse(value: string, data: any, translateService: TranslateService): Observable<SearchResponseInterface> {
    return new Observable(observer => {
      const message = data.length > 0 
        ? 'SEARCH.RESULTS.FOUND_FOR_QUERY' 
        : 'SEARCH.RESULTS.NOT_FOUND_FOR_QUERY';
      observer.next({ data, message, query: value });
      observer.complete();
    });
  }

  isSearchActive(): boolean {
    return this.searchActive;
  }

}
