
import { Component, Input } from '@angular/core';
import { FileData } from '../../../core/models/file-data.model';
import { ImageSizeEnum } from '../../pipes/resize-image/resize-image.pipe';

@Component({
  selector: 'app-row-image',
  templateUrl: './row-image.component.html',
  styleUrls: ['./row-image.component.scss']
})
export class RowImageComponent {
  @Input() imageFile: FileData = null;
  @Input() defaultIcon: string = '';
  @Input() iconColorClass: string = 'gray-500';
  @Input() backgroundColorClass: string = 'bg-gray-300';
  @Input() downloadable: boolean = false;

  imageSizes = ImageSizeEnum;
}
