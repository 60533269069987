import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, forwardRef, input } from '@angular/core';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CertificateTagInterface } from '../../../../core/models/certificate.model';

export const TAGS_SEARCH_INPUT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsSearchInputComponent),
  multi: true,
};

export interface SelectEventInterface {
  item: CertificateTagInterface | null;
  preventDefault?: () => void;
}

@Component({
  selector: 'app-tags-search-input',
  templateUrl: './tags-search-input.component.html',
  styleUrls: ['./tags-search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TAGS_SEARCH_INPUT_ACCESSOR],
})
export class TagsSearchInputComponent implements ControlValueAccessor {
	public model: CertificateTagInterface = null;

  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() tags: CertificateTagInterface[] = [];
  @Input() set disabled(disabled: boolean | undefined) {
    if (disabled !== undefined) {
      this.isDisabled = disabled;
    }
  };
  @Input() isInvalid = false;
  @Output() onChange = new EventEmitter<CertificateTagInterface>();
  @Output() tagSelected = new EventEmitter<CertificateTagInterface>();

  @ViewChild('input') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('instance', { static: true }) ngbInstance: NgbTypeahead;

  isDisabled = false;

  private onChangedCallback = (value: CertificateTagInterface) => { };
  onTouchedCallback = () => { };

  constructor() { }

  writeValue(newValue: CertificateTagInterface | null): void {
    this.model = newValue;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouchedCallback = fn;
  }

  registerOnChange(fn: (value: CertificateTagInterface) => {}): void {
    this.onChangedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  search: OperatorFunction<string, CertificateTagInterface[]> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((query) => {
        if (!query || query.length < 3) {
          return of([] as CertificateTagInterface[]);
        }

        const results = this.tags.filter(tag => tag.title.toLowerCase().includes(query.toLowerCase()));

        return of(results.length ? results : [{ title: 'NO_RESULTS' } as CertificateTagInterface]);
      }),
    );
  }

  formatter = (x: CertificateTagInterface | string) => {
    return typeof x === 'string' ? x : x.title;
  };

  onSelect(event: SelectEventInterface) {
    event.preventDefault?.();

    if (event.item.title === 'NO_RESULTS') {
      return;
    }

    this.tagSelected.emit(event.item);
    this.modelChange(null);
  }

  onClear(event: MouseEvent) {
    event.stopPropagation();
    this.modelChange(null);
    this.searchInput.nativeElement.focus();
  }

  modelChange(event: CertificateTagInterface) {
    this.model = event;
    this.onChange.emit(event);
    this.onChangedCallback(event);
  }
}
