import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { OrderItemsClipboardService } from '../../orders/order-items-clipboard/order-items-clipboard.service';
import { PriceRequestItemsClipboardService } from '../../inquiries/custom-made-price-requests/services/price-request-items-clipboard.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private authService: AuthService, 
    private orderItemsClipboardService: OrderItemsClipboardService,
    private priceRequestItemsClipboardService: PriceRequestItemsClipboardService,
  ) {}

  ngOnInit() {
    this.orderItemsClipboardService.clearClipboard();
    this.priceRequestItemsClipboardService.clearClipboard();
    this.authService.logout();
  }
}
