<!-- Whole loader -->
<app-loader #loaderOfWholeModal cssClasses="loader--white"></app-loader>

<div class="d-flex flex-column flex-grow-1 overflow-hidden">
  <!-- Header -->
  <div class="p-3 border-bottom border-color-gray-400">
    <div class="d-flex">
      <div class="w-100 d-flex align-items-center">
        <h2 class="p-0 pe-3 m-0 mx-2 fw-bold">{{ system | uppercase }}</h2>
      </div>
      <div class="flex-shrink-1">
        <button
          class="btn btn-default p-0 m-1 d-flex position-relative"
          [class.button-on-load-failed]="failedToLoad"
        >
          <i class="icon ni-close color-black" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Body -->
  <div class="modal-body ps-3 pe-0 py-0 overflow-hidden">
    <!-- Model viewer -->
    <app-configurator-modal-model-viewer
      class="position-relative"
      [server]="transformedResponse?.additional?.configuratorUrl"
      [session]="transformedResponse?.additional?.sessionId"
      [item]="transformedResponse?.additional?.rootId"
      [viewType]="transformedResponse?.resolvedMediaType"
      [updated]="updated"
    ></app-configurator-modal-model-viewer>

    <!-- Properties -->
    <div class="properties d-flex flex-column">
      <app-loader #loaderOfPropertiesConfiguration [cssClasses]="{ 'loader--white': true, 'no-icon': isDisabled }"></app-loader>
      <div #componentConfigurationsInner class="properties-inner">
        <div *ngIf="configurationArticles.length" class="border-bottom">
          <app-article-selector
            class="border-bottom"
            [currentArticleId]="currentArticleId || additional.rootId"
            [articles]="configurationArticles"
            [widthReferenceEl]="componentConfigurationsInner"
            (selectArticle)="selectArticle($event)"
            [configurationArticlesWithMigration]="configurationArticlesWithMigration"
            [currentArticlePrice]="currentArticlePrice"
          ></app-article-selector>
        </div>
        <ng-container *ngFor="let property of transformedResponse?.controllingProperties; let i = index">
          <app-generic-property
            *ngIf="!!property.configuratorId"
            [attr.selenium_test]="property.originalName"
            [property]="property"
            [isRootControllingProperty]="true"
            (propertyClassChange)="onPropertyClassChange($event, i)"
          ></app-generic-property>
        </ng-container>
        <div #propertiesConfigurator></div>

        <div
          class="mb-3"
          *ngIf="
            selectionMenuEntity !== selectionMenuEntities.PRICE_REQUEST
            && articleLoadCompleted
            && FeatureFlags.oldNonStandardsRestrictionsEnabled
            && !isPmNarbutas
          " 
        >
          <h4 class="border-bottom border-color-gray-400 fw-bold color-gray pt-3 pb-2 m-0">{{
            'CONFIGURATOR_MODAL.AD_BLOCK.NEED_SOMETHING_UNIQUE' | translate }}</h4>
          <div class="px-3 pt-3 small">
            <span>{{ 'CONFIGURATOR_MODAL.AD_BLOCK.INFO_QUESTION' | translate }}</span>
            <button class="btn btn-light d-flex fw-bold py-2 mt-2 w-100 no-outline justify-content-between"
              (click)="onAdvertisementBlockClick()" data-price-requests-selection-menu-opener>
              {{ 'CONFIGURATOR_MODAL.AD_BLOCK.PRICE_REQUEST' | translate }}
              <i class="icon ni-widgets-fill"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<app-configurator-modal-footer
  [lastUpdate]="updated"
  [item]="transformedResponse?.additional?.rootId"
  [quantity]="quantity"
  (createOrder)="onCreateOrder($event)"
  (updateOrder)="onUpdateOrder($event)"
  (addToPriceRequestClicked)="onAddToPriceRequest($event)"
  (openMigrationLog)="onOpenMigrationLog()"
  [action]="action"
  [price]="articleVariant?.price || articleRow?.totalConfigurationPrice"
  [orderArticle]="orderArticle"
  [hasMigrationIssues]="hasMigrationIssues"
  [selectedEntity]="selectionMenuEntity"
  [configurationLoaded]="configurationLoaded"
></app-configurator-modal-footer>

<ng-template #toastAfterUpdate>
  <div class="d-flex">
    <i class="icon ni-check me-2"></i>
    <span class="configurator-updated-toast color-white fw-bold">
      {{ toastContent }}
      <br />
      <a [href]="toastUrl" class="btn btn-sm border py-01875r text-nowrap rounded-0 btn-outline-white mt-2">
        {{ toastButtonText }}
      </a>
    </span>
  </div>
</ng-template>
