<div class="property-values-selector-wrapper">
  <div
    #triggerElement
    class="px-3 py-2 w-100 d-flex align-items-center justify-content-between text-start border-top gap-1"
    [class.hover-bg-gray-100]="property.editable || property.removable"
    [ngClass]="{'btn btn-link': property.editable || property.removable}"
    (click)="open()"
  >
    <div 
      *ngIf="(property.shouldDisplayPropertyImages && !isEmptyValueSelected) || property.controlledItemID"
      class="border me-1"
    >
      <div
        class="thumbnail"
        [style.width.px]="40"
        [style.height.px]="40"
        [style.background-image]="selectedPropertyValue?.img && 'url(' + ((selectedPropertyValue?.img | uncache | async) ?? '') + ')'"
        [style.background-size]="'cover'"
        (click)="openOrSelectArticle($event)"
      >
        <div
          *ngIf="property.controlledItemID"
          class="w-100 h-100 align-items-center justify-content-center"
          [class.thumbnail-icon]="selectedPropertyValue?.img"
          [class.d-flex]="!selectedPropertyValue?.img"
          [class.hover-bg-gray-200]="property.editable || (property.removable && !isRootControllingProperty) || property.controlledItemID"
          [class.text-black]="property.editable || property.removable || !isRootControllingProperty"
          [class.text-gray]="!property.editable && !property.removable"
        >
          <span class="icon ni-settings-1"></span>
        </div>
      </div>
    </div>

    <div
      class="property-text w-100 small flex-grow-1 d-flex flex-column align-items-start overflow-hidden"
      [class.flex-wrap]="!property.editable"
    >
      <span class="color-gray me-2">{{ name || property.name || property.originalName }}</span>
      <span
        *ngIf="(!isEmptyValueSelected && (selectedPropertyValue || property.added)) || property.mandatory"
        class="fw-bold w-100"
        [class.color-black]="property.editable"
        [class.color-gray]="!property.editable"
      >
        <ng-container *ngIf="showTranslatedSelected; else untranslatedSelectedValue">
          <ng-container *ngIf="selectedPropertyValue.title && showOriginalSelected; else onlyOriginalSelected">
            {{ selectedPropertyValue.title }}
          </ng-container>
          <ng-template #onlyOriginalSelected>
            {{ (selectedPropertyValue.valueFrom | configuratorPropertyValueTranslate) || selectedPropertyValue.title }}
          </ng-template>
        </ng-container>
        <ng-template #untranslatedSelectedValue>
          {{ selectedPropertyValue.valueFrom }}
        </ng-template>
      </span>
    </div>

    <i *ngIf="!property.editable" class="icon ni-lock-outline text-gray"></i>

    <ng-container *ngTemplateOutlet="actionButtonTemplate"></ng-container>
  </div>

  <app-migration-issues-tooltip
      [property]="property"
      [hideMigrationIssues]="hideMigrationIssues"
      [additionalData]="additionalData"
    ></app-migration-issues-tooltip>
</div>

<ng-template #popupTemplate>
  <div class="overflow-auto bg-white shadow rounded-1 w-100 d-flex flex-column">
    <div class="color-gray border-bottom px-3 py-2 fw-bold">
      <span class="py-1">{{ property.name }}</span>
    </div>
    <div class="overflow-auto w-100 flex-grow-1">
      <ng-container *ngIf="!property.shouldDisplayPropertyImages; else propertyValueGridView">
        <div
          *ngFor="let value of property.articlePropertyValues; let i = index, let last = last"
          class="align-top"
          [class.d-inline-block]="property.shouldDisplayPropertyImages && propertyValueSelectorThemes.IMAGES_LIST !== theme"
          [class.border-bottom]="!last"
          [ngbTooltip]="value.title"
          [disableTooltip]="propertyValueSelectorThemes.IMAGES_SMALL !== theme"
          container="body"
          placement="bottom"
        >
          <ng-container
            *ngTemplateOutlet="radioButton; context: {$implicit: {value, property, theme, selected: property.currentValue.valueFrom}}"
          ></ng-container>
        </div>
      </ng-container>

      <ng-template #propertyValueGridView>
        <div class="container-fluid">
          <div class="row">
            <div class="col-6 p-3"
                  [class.pe-2]="!odd"
                  [class.ps-2]="odd"
                  *ngFor="let value of property.articlePropertyValues; let odd = odd"
            >
              <ng-container
                *ngTemplateOutlet="radioButton; context: {$implicit: {value, property, theme, selected: property.currentValue.valueFrom}}"
              ></ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #radioButton let-item>
  <app-radio-button
    [title]="item.value.title"
    [cssClasses]="item.theme"
    [image]="item.property.shouldDisplayPropertyImages ? (item.value?.img | uncache | async) : null"
    [displayImage]="item.property.shouldDisplayPropertyImages"
    [radioGroup]="item.property.name"
    [value]="item.value.valueFrom"
    [checked]="item.selected === item.value.valueFrom"
    (onChange)="onPropertyChange(item.property.originalName, item.value.valueFrom, item.property.configuratorId, item.property.propClass)"
  ></app-radio-button>
</ng-template>
