import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageStatus } from '../../core/enums/message-status.enum';
import { MessageObjectType, MessagesInterface } from '../../core/models/messages.model';
import { ERROR_STATUSES } from '../../header-message/server-errors.interceptor';
import { MessagesWindowComponent } from '../../messages-window/messages-window.component';
import { ScrollToDirective } from '../../shared/directives/scroll-to/scroll-to.directive';
import { ChatHelperService } from '../../shared/services/chat-helper/chat-helper.service';
import { MessageCodeTranslationService } from '../../shared/services/message-code-translation/message-code-translation.service';
import { CloseButtonTheme } from '../../ui-elements/close-button/close-button-theme.enum';
import { CloseButtonComponent } from '../../ui-elements/close-button/close-button.component';
import { MessageFieldComponent } from '../../ui-elements/communication-tab/message-field/message-field.component';
import { MessagesService } from '../messages.service';

@Component({
    selector: 'app-conversation-modal',
    templateUrl: './conversation-modal.component.html',
    styleUrls: ['./conversation-modal.component.scss'],
    providers: [ChatHelperService],
    imports: [CloseButtonComponent, MessagesWindowComponent, ScrollToDirective, MessageFieldComponent, TranslatePipe]
})
export class ConversationModalComponent implements OnInit {
  @ViewChild('scroller', { read: ElementRef, static: true }) public scroller: ElementRef<any>;
  @Input() orderId: number;
  @Input() object;
  @Input() forceMarkAsSeen: boolean;
  @Input() newMessageCount?: number;

  @Output() markMessagesAsSeen: EventEmitter<any> = new EventEmitter<any>();

  messages: MessagesInterface[] = [];
  files: File[];
  text: string;
  closeButtonTheme = CloseButtonTheme;
  loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private messagesService: MessagesService,
    private messageCodeTranslationService: MessageCodeTranslationService,
    private chatHelperService: ChatHelperService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.onRefresh();
  }

  onRefresh() {
    let observable = null;
    switch (this.object.type) {
      case MessageObjectType.ORDER:
        observable = this.messagesService.getByOrder(this.orderId).noCache();
        break;
    }
    if (observable) {
      observable.subscribe(messages => {
        messages.forEach(message => {
          this.messageCodeTranslationService.getMessageCodeTranslation(message).subscribe(translation => (message.text = translation));
        });
        this.messages = messages;
        this.loading = false;
        if (this.forceMarkAsSeen) {
          this.onMarkMessagesAsSeen();
          this.forceMarkAsSeen = false;
        }
      });
    }
  }

  onMessageAdd({ text, files }: Partial<MessagesInterface>) {
    let observable = null;

    this.chatHelperService.setIsSending(true);

    switch (this.object.type) {
      case MessageObjectType.ORDER:
        observable = this.messagesService.createByOrder(this.orderId, { text }, files);
        break;
    }

    if (!observable) {
      this.chatHelperService.setIsSending(false);
      throw new Error('Undefined type');
    }

    observable.subscribe({
      next: () => {
        this.forceMarkAsSeen = true;
        this.onRefresh();

        this.chatHelperService.clearValidationErrors();
        this.chatHelperService.setIsSending(false);
      },
      error: (error) => {
        if (error.error?.code === ERROR_STATUSES.BAD_REQUEST) {
          this.chatHelperService.parseValidationErrors(error);
        }

        this.chatHelperService.setIsSending(false);
      }
    });
  }

  onClose() {
    this.activeModal.dismiss();
  }

  onMarkMessagesAsSeen() {
    if (this.messages && this.messages.length) {
      this.newMessageCount = 0;
      const unreadMessages = this.messages.filter(message => message.status === MessageStatus.NEW);
      if (unreadMessages && unreadMessages.length) {
        this.messagesService.markMessagesAsSeen(unreadMessages).subscribe(() => {
          this.markMessagesAsSeen.emit();
        });
      }
    }
  }
}
