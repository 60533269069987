import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FileData } from '../../../../core/models/file-data.model';
import { ListModeSwitchService } from '../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { SaleMode } from '../../../../shared/components/list-mode-switch/sale-mode.types';
import { ImageSizeEnum } from '../../../../shared/pipes/resize-image/resize-image.pipe';
import { CloseButtonTheme } from '../../../../ui-elements/close-button/close-button-theme.enum';
import { ArticlePropertyClassInterface, ArticlePropertyProperty, ArticlePropertyValueInterface, CatalogueSystemsInterface, ProductArticleVariant, ProductsArticleInterface } from '../../product.model';
import { ConfiguratorOpenerService } from '../../products-configurator-opener.service';
import { PropertyValueGroupsService, SelectedValuesGroupDataInterface } from '../property-values-group/property-value-groups.service';

interface ColumnInterface {
  id: number;
  title: string;
  visible: boolean;
}

@Component({
  selector: 'app-products-block',
  templateUrl: './products-block.component.html',
  styleUrls: ['./products-block.component.scss'],
  providers: [PropertyValueGroupsService],
})
export class ProductsBlockComponent implements OnChanges, OnInit, OnDestroy {
  @Input() searchQuery: string;
  @Input() blockIndex: number;
  @Input() articlePropertyClass: ArticlePropertyClassInterface;
  @Input() system: CatalogueSystemsInterface;
  @Input() descriptionColumnVisible: boolean = true;
  @Input() codeColumnVisible: boolean = true;

  activeComponentsDropdown = null;
  visibleArticle: ProductsArticleInterface;
  closeButtonTheme = CloseButtonTheme;
  galleryImage: FileData;

  saleMode: SaleMode;
  saleModeTemplate = SaleMode;
  imageSizes = ImageSizeEnum;

  selectedValueGroups: SelectedValuesGroupDataInterface[] = [];

  readonly translationPrefix = 'CATALOGUE.ARTICLE_LIST.COLUMNS.';
  readonly descriptionColumnID = 1;
  readonly codeColumnID = 5;
  columns: ColumnInterface[] = [
    { id: 0, title: null, visible: true },
    { id: this.descriptionColumnID, title: `${this.translationPrefix}DESCRIPTION`, visible: true },
    { id: 2, title: `${this.translationPrefix}WIDTH`, visible: true },
    { id: 3, title: `${this.translationPrefix}DEPTH`, visible: true },
    { id: 4, title: `${this.translationPrefix}HEIGHT`, visible: true },
    { id: this.codeColumnID, title: `${this.translationPrefix}CODE`, visible: true },
    { id: 6, title: `${this.translationPrefix}PRICE`, visible: true },
  ];

  private subscriptions = new Subscription();

  constructor(
    private modalService: NgbModal,
    private listModeSwitchService: ListModeSwitchService,
    private configuratorOpener: ConfiguratorOpenerService,
    private propertyValueGroupsService: PropertyValueGroupsService
  ) {}

  ngOnChanges(): void {
    this.columns.find(column => column.id === this.descriptionColumnID).visible = this.descriptionColumnVisible;
    this.columns.find(column => column.id === this.codeColumnID).visible = this.codeColumnVisible;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode) => {
        this.saleMode = +saleMode;
      })
    );

    this.subscriptions.add(
      this.propertyValueGroupsService.getSelectedValueGroupsDataAsObservable().subscribe((selectedValueGroups) => {
        this.selectedValueGroups = selectedValueGroups;
      })
    );
  }

  openConfiguratorModal(system: CatalogueSystemsInterface, article: ProductsArticleInterface, articleVariant?: ProductArticleVariant) {
    this.configuratorOpener.openConfiguratorModal(system, article, articleVariant);
  }

  openImagePreviewModal(content: TemplateRef<string>, article: ProductsArticleInterface) {
    this.visibleArticle = article;
    // building regular app-gallery acceptable data structure for image
    this.galleryImage = {
      id: 1,
      filename: `${article.longText}-${article.code}`,
      name: 'Image',
      url: article.articlePhoto,
    };

    this.modalService.open(content, {
      size: 'sm',
      centered: true,
      scrollable: true,
      container: '.view-wrapper',
    });
  }

  openComponentsDropdown(id: string) {
    this.activeComponentsDropdown = this.activeComponentsDropdown === id ? null : id;
  }

  isComponentsDropdownActive(id: string): boolean {
    return this.activeComponentsDropdown === id;
  }

  firstAvailableArticleWithPhoto(articles: ProductsArticleInterface[]): ProductsArticleInterface | null | undefined {
    return articles.find((article) => !!article.articlePhoto);
  }

  getPriceBackgroundColor(articleVariantNames: ProductArticleVariant['names']): string[] {
    let bgClasses = this.saleModeActive ? ['hover-bg-warning'] : ['hover-bg-primary'];

    if (this.shouldPriceBeHighlighted(articleVariantNames)) {
      bgClasses.push(this.saleModeActive ? 'bg-warning-200' : 'bg-primary-200');

      return bgClasses;
    }

    bgClasses.push(this.saleModeActive ? 'bg-warning-100' : 'bg-primary-100');

    return bgClasses;
  }

  shouldPriceBeHighlighted(articleVariantNames: ProductArticleVariant['names']): boolean {
    if (typeof this.selectedValueGroups[0]?.articleVariantNames[0] === 'number') {
      return true;
    }

    const namesAsStringArray = articleVariantNames as string[];

    return this.selectedValueGroups.some((valuesGroup) =>
      valuesGroup.articleVariantNames.some((name) => namesAsStringArray.includes(name))
    );
  }

  propertyHasSingleValuesColumn(property: ArticlePropertyProperty): boolean {
    return property.articlePropertyValues.length === 1;
  }

  propertyColumnContainsMultipleGroups(propertyColumn: ArticlePropertyValueInterface): boolean {
    return propertyColumn.valuesGroupedByMaterialFamily.length > 1;
  }

  get saleModeActive(): boolean {
    return this.saleMode === SaleMode.SALE;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
