<form [formGroup]="form" class="h-100">
  <app-generic-modal [closable]="false" [cssClasses]="'h-100 d-flex flex-column'">
    <ng-template appModalHeader>
      <h2 class="m-0 me-4">
        {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.TITLE_CUSTOM_MADE' | translate }}
      </h2>
      <app-ui-elements-close-button class="ms-auto" (click)="onClose()"></app-ui-elements-close-button>
    </ng-template>

    <ng-template appModalBody>
      <div class="row mb-4 flex-grow-1">
        <div class="form-group col-6">
          <input
            id="custom-made"
            formControlName="title"
            name="title"
            class="form-control fw-bold"
            [class.is-invalid]="form.controls.title.touched && form.controls.title.invalid"
            type="text"
            [placeholder]="('INQUIRIES.PRICE_REQUESTS.NEW_ITEM.TITLE_PLACEHOLDER' | translate) + ' *'"
          />
          <div class="invalid-feedback">
            {{ form.controls.title.errors | translateValidationErrors }}
          </div>
        </div>
        <div class="form-group col-6">
          <div class="d-flex gap-2">
            <label class="form-label m-0 mt-2" for="quantity">
              {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.QUANTITY' | translate }} *
            </label>
            <div>
              <input
                id="quantity"
                formControlName="quantity"
                name="quantity"
                class="form-control w-6r"
                [class.is-invalid]="form.controls.quantity.touched && form.controls.quantity.invalid"
                type="number"
                [placeholder]="'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.QUANTITY_PLACEHOLDER' | translate"
                min="1"
              />
              <div class="invalid-feedback">
                {{ form.controls.quantity.errors | translateValidationErrors }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 form-group mb-4">
        <label class="form-label" for="description">
          {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DESCRIPTION' | translate }}
        </label>
        <textarea
          id="description"
          formControlName="description"
          name="description"
          class="form-control h-12.5r"
          [class.is-invalid]="form.controls.description.touched && form.controls.description.invalid"
          [placeholder]="'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.CUSTOM_MADE_DESCRIPTION_PLACEHOLDER' | translate"
        ></textarea>
        <div class="invalid-feedback">
          {{ form.controls.description.errors | translateValidationErrors }}
        </div>
        <div class="mt-1 small color-gray">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DESCRIPTION_INFO_TEXT' | translate }}</div>
      </div>

      <div class="row mb-4">
        <div class="form-group col-6">
          <label class="form-label" for="dimensions">
            {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DIMENSIONS' | translate }}
          </label>
          <textarea
            id="dimensions"
            data-testid="dimensionsField"
            formControlName="dimensions"
            name="dimensions"
            class="form-control h-5r"
            [class.is-invalid]="form.controls.dimensions.touched && form.controls.dimensions.invalid"
            [placeholder]="'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DIMENSIONS_PLACEHOLDER' | translate"
          ></textarea>
          <div class="invalid-feedback">
            {{ form.controls.dimensions.errors | translateValidationErrors }}
          </div>
        </div>
        <div class="col-6">
          <app-order-article-material-input
            id="finishes"
            formControlName="finishes"
            name="finishes"
            [class.is-invalid]="form.controls.dimensions.touched && form.controls.dimensions.invalid"
            [placeholder]="'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.FINISHES_PLACEHOLDER' | translate"
            [text]="('INQUIRIES.PRICE_REQUESTS.NEW_ITEM.FINISHES' | translate) + ' *'"
          />
        </div>
      </div>

      <div class="col-12">
        <label class="form-label" for="customMadePriceRequestItemFiles">
          {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.ATTACHMENTS' | translate }}
        </label>
        <app-price-request-files-input
          [priceRequestId]="priceRequestId"
          [priceRequestItemId]="priceRequestItemId"
          id="customMadePriceRequestItemFiles"
          formControlName="customMadePriceRequestItemFiles"
          name="customMadePriceRequestItemFiles"
          acceptedFiles=".jpg,.jpeg,.png,.pdf,.dwg,.eps,.xlsx,.xls,.ods,.numbers,.pptx,.ppt,.cdr"
        ></app-price-request-files-input>
      </div>
    </ng-template>

    <ng-template appModalFooter>
      <button
        type="button"
        class="btn btn-outline btn-lg w-11.875r d-flex align-items-center justify-content-center h-100 no-outline"
        (click)="onDiscard()"
      >
        {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DISCARD' | translate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary btn-lg w-11.875r d-flex align-items-center justify-content-center h-100 no-outline"
        [disabled]="!form.valid || isUploadingFiles || isSubmitting"
        (click)="onSubmit()"
      >
        {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.SAVE' | translate }}
      </button>
    </ng-template>
  </app-generic-modal>
</form>
