import { Injectable } from '@angular/core';
import { NotificationInterface } from '../../core/models/notification.model';
import { Router } from '@angular/router';
import { InquiriesRoutePath } from '../../core/enums/route-types.enum';

@Injectable({
  providedIn: 'root',
})
export class PriceRequestNotificationOpenerService {
  constructor(private router: Router) {}

  open(notification: NotificationInterface) {
    const path = ['/', InquiriesRoutePath.ROOT, InquiriesRoutePath.CUSTOM_MADE_PRICE_REQUESTS, notification.objectId];
    const isStatusChange = notification.details?.status;
    this.router.navigate(isStatusChange ? path : [...path, 'communication']);
  }
}
