<app-generic-modal
  [closable]="false"
  headerClasses="d-flex flex-column align-items-start"
  cssClasses="h-100 d-flex flex-column"
  bodyClasses="p-0"
>
  <ng-template appModalHeader>
    <div class="d-flex w-100 justify-content-between align-items-center">
      <h2 class="m-0 me-4">
        {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.TITLE' | translate }}
      </h2>
      <app-ui-elements-close-button (click)="onClose()"></app-ui-elements-close-button>
    </div>
    <p class="m-0 mt-2 pe-4 color-gray-600">
      {{
        (openedFromOffer
          ? 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.OPENED_FROM_OFFER_SUBTITLE'
          : 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.OPENED_FROM_PRICE_REQUEST_SUBTITLE'
        ) | translate
      }}
    </p>
  </ng-template>

  <ng-template appModalBody>
    <form [formGroup]="form">
      <div formArrayName="items">
        <ng-container *ngFor="let priceRequestItem of priceRequestItems; let i = index; let first = first">
          <div [formGroupName]="i" [class.mt-5]="!first">
            <div class="d-flex bg-gray-200 px-4 py-2">
              <div class="row-img me-2">
                <app-row-image [imageFile]="imageFiles[i]" [defaultIcon]="getItemIcon(priceRequestItem)"></app-row-image>
              </div>

              <div class="d-flex flex-column flex-grow-1">
                <h4 class="color-black fw-bold">{{ priceRequestItem.title }}</h4>

                <p class="small color-gray-600 fw-bold mb-2">
                  {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.CUSTOM_MADE_FURNITURE' | translate }}
                </p>

                <label class="mb-1 small color-gray-500" for="item-description-{{ i }}">{{
                  'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.DESCRIPTION' | translate | uppercase
                }}</label>
                <p class="mb-2 small text-break--all color-gray-700" id="item-description-{{ i }}">
                  {{ priceRequestItem.description }}
                </p>

                <label class="mb-1 small color-gray-500" for="item-finishes">{{
                  'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.MATERIALS' | translate | uppercase
                }}</label>
                <app-order-article-material-input
                  class="mb-1"
                  id="item-finishes"
                  name="finishes"
                  [value]="priceRequestItem.finishes"
                  [listOnly]="true"
                />

                <div *ngIf="priceRequestItem.dimensions">
                  <label class="mb-1 small color-gray-500" for="item-dimensions-{{ i }}">{{
                    'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.DETAILS' | translate | uppercase
                  }}</label>
                  <p class="mb-1 small text-break--all color-gray-700" id="item-dimensions-{{ i }}">
                    {{ priceRequestItem.dimensions }}
                  </p>
                </div>

                <label class="mb-1 small color-gray-500" for="item-quantity-{{ i }}">{{
                  'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.QTY' | translate | uppercase
                }}</label>
                <p class="mb-1 small color-gray-900" id="item-quantity-{{ i }}">
                  {{ priceRequestItem.quantity }}
                </p>
              </div>

              <div class="actions-col px-3 py-3 flex-shrink-0">
                <div ngbDropdown [container]="'body'" [placement]="['bottom-end', 'top-end']" class="btn-group" appClickStopPropagation>
                  <button ngbDropdownToggle class="btn btn-light p-1 no-focus d-flex" [attr.id]="'actionsDropdownMenu-' + i">
                    <i class="icon ni-more-horiz"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu p-0 shadow" [attr.aria-labelledby]="'actionsDropdownMenu-' + i">
                    <button
                      ngbDropdownItem
                      class="d-flex align-items-center btn btn-secondary no-outline"
                      (click)="onReviewItem(priceRequestItem)"
                    >
                      <i class="icon me-2" [ngClass]="getItemIcon(priceRequestItem)"></i>
                      <span>{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.REVIEW_ITEM' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="px-4 pt-4">
              <p class="mb-2 color-gray-600">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.QUESTIONS_TO_CLARIFY' | translate }}</p>
              <p class="color-black">{{ priceRequestItem.clarificationQuestion }}</p>
              <div class="form-group mb-4">
                <label class="form-label color-gray-600" for="clarificationAnswer-{{ i }}">
                  {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.YOUR_CLARIFICATION' | translate }} *
                </label>
                <textarea
                  id="clarificationAnswer-{{ i }}"
                  formControlName="clarificationAnswer"
                  name="clarificationAnswer"
                  class="answer-textarea form-control"
                  [class.is-invalid]="
                    getItemFormGroup(i).controls.clarificationAnswer.touched && getItemFormGroup(i).controls.clarificationAnswer.invalid
                  "
                  [placeholder]="'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.ANSWER_PLACEHOLDER' | translate"
                ></textarea>
                <div class="invalid-feedback">
                  {{ getItemFormGroup(i).controls.clarificationAnswer.errors | translateValidationErrors }}
                </div>
              </div>

              <div class="form-group mb-2">
                <label class="form-label" for="clarification-files-{{ i }}">
                  {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.CLARIFICATION.ATTACHMENTS' | translate }}
                </label>
                <app-price-request-files-input
                  [priceRequestId]="priceRequestItem.customMadePriceRequestId"
                  [priceRequestItemId]="priceRequestItem.id"
                  [fileType]="fileTypes.CLARIFICATION"
                  id="clarification-files-{{ i }}"
                  formControlName="attachments"
                  name="attachments"
                  dropZoneName="attachments-{{ i }}"
                  acceptedFiles=".jpg,.jpeg,.png,.pdf,.dwg,.eps,.xlsx,.xls,.ods,.numbers,.pptx,.ppt,.cdr"
                ></app-price-request-files-input>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </ng-template>

  <ng-template appModalFooter>
    <button
      type="button"
      class="action-button btn btn-outline btn-lg d-flex align-items-center justify-content-center h-100 no-outline"
      (click)="onClose()"
    >
      {{ 'ACTIONS.CANCEL' | translate }}
    </button>
    <button
      type="submit"
      class="action-button btn btn-primary btn-lg d-flex align-items-center justify-content-center h-100 no-outline"
      [disabled]="!form.valid || isUploadingFiles || isSubmitting || isClosing"
      (click)="onSubmit()"
    >
      {{ 'ACTIONS.SUBMIT' | translate }}
    </button>
  </ng-template>
</app-generic-modal>
