<app-loader #loader></app-loader>
<ng-container *ngIf="!loader.loading">
  <div class="container-fluid px-4">
    <div class="row mt-3">
      <div class="col-7 d-flex align-items-center">
        {{ getDescriptionKey() | translate }}
      </div>
      <div class="col-5 d-flex align-items-center justify-content-end">
        <div class="fw-bold text-nowrap color-black">
          {{ 'SALE_COEFFICIENTS.TABLE.GLOBAL_COEFFICIENT' | translate }}
        </div>
        <button
          *ngIf="currentSaleCoefficient?.categories?.length"
          class="btn btn-primary text-nowrap ms-3"
          (click)="onApplyGlobal()"
        >
          {{ 'SALE_COEFFICIENTS.TABLE.APPLY_TO_ALL' | translate }}
        </button>
        <input
          class="form-control sale-coefficients-global-input ms-3 ps-2 pe-0"
          type="number"
          [min]="saleCoefficientsRange.MIN"
          [max]="saleCoefficientsRange.MAX"
          [step]="saleCoefficientsRange.STEP"
          [appSaleCoefficientFormatValue]="getDefaultSaleCoefficient()"
          (appSaleCoefficientFormat)="setDefaultSaleCoefficient($event)"
          [value]="
            getDefaultSaleCoefficient() | saleCoefficientFormat: { min: saleCoefficientsRange.MIN, max: saleCoefficientsRange.MAX }
          "
        />
      </div>
    </div>

    <ng-container *ngIf="currentSaleCoefficient?.categories">
      <ng-container *ngFor="let categoryGroup of (currentSaleCoefficient.categories | splitIntoGroupsOf: 3)">
        <div class="row">
          <div class="col">
            <hr class="mb-0">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 align-items-start" *ngFor="let category of categoryGroup">
            <div class="d-flex align-items-center justify-content-end border-bottom sale-coefficients-category">
              <div class="fw-bold color-black text-end">{{ category.name }}</div>
              <button class="btn btn-primary ms-3" (click)="onApply(category)">
                {{ 'SALE_COEFFICIENTS.TABLE.APPLY' | translate }}
              </button>
              <input
                class="form-control sale-coefficients-input ms-3 ps-2 pe-0"
                (appSaleCoefficientFormat)="apply(category, $event)"
                type="number"
                [min]="saleCoefficientsRange.MIN"
                [max]="saleCoefficientsRange.MAX"
                [step]="saleCoefficientsRange.STEP"
                [appSaleCoefficientFormatValue]="category.saleCoefficient.saleCoefficient"
                [value]="
                  category.saleCoefficient.saleCoefficient
                    | saleCoefficientFormat: { min: saleCoefficientsRange.MIN, max: saleCoefficientsRange.MAX }
                "
              />
            </div>
            <div class="mt-3" *ngFor="let child of category.children">
              <div class="d-flex align-items-center justify-content-end">
                <div class="color-black">{{ child.name }}</div>
                <input
                  class="form-control sale-coefficients-input ms-3 ps-2 pe-0"
                  (appSaleCoefficientFormat)="apply(child, $event)"
                  [appSaleCoefficientFormatValue]="child.saleCoefficient.saleCoefficient"
                  type="number"
                  [min]="saleCoefficientsRange.MIN"
                  [max]="saleCoefficientsRange.MAX"
                  [step]="saleCoefficientsRange.STEP"
                  [value]="
                    child.saleCoefficient.saleCoefficient
                      | saleCoefficientFormat: { min: saleCoefficientsRange.MIN, max: saleCoefficientsRange.MAX }
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="card-footer text-end p-0 mt-3">
    <button class="btn btn-secondary btn-lg rounded-0 mw-11r" (click)="onCancel()">
      {{ 'SALE_COEFFICIENTS.TABLE.CANCEL' | translate }}
    </button>
    <button class="btn btn-primary btn-lg rounded-0 mw-11r" (click)="onSave()">
      {{ 'SALE_COEFFICIENTS.TABLE.SAVE' | translate }}
    </button>
  </div>
</ng-container>
