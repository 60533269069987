<div class="row">
  <div class="col-6" *ngIf="selectedTemplate.type == templateTypes.PROPOSAL">
    <app-ui-elements-form-drop-down
      [values]="photoTypes"
      [selectedValue]="selectedTemplate.photoType"
      valueField="id"
      labelField="title"
      [title]="'CREATE_DOCUMENT.TEMPLATE.FORMAT' | translate"
      (onChange)="onSelectPhotoType($event)"
    ></app-ui-elements-form-drop-down>
  </div>
  <div class="col-6">
    <app-ui-elements-form-drop-down
      *ngIf="
        (selectedTemplate.photoType === templateImageType.SMALL || selectedTemplate.photoType === templateImageType.HIDDEN) &&
        filteredMaterialTypes.length > 0
      "
      [values]="filteredMaterialTypes"
      [title]="'CREATE_DOCUMENT.TEMPLATE.MATERIAL' | translate"
      valueField="id"
      labelField="title"
      [selectedValue]="selectedTemplate.materialType"
      (onChange)="onSelectMaterialType($event)"
    ></app-ui-elements-form-drop-down>
  </div>
</div>
<div class="row g-0 border-top mb-3 pb-2">
  <div class="col"></div>
</div>
<div class="row px-3" *ngIf="selectedTemplate.photoType === templateImageType.LARGE; else smallPhotoPropertyList">
  <div class="col-4" *ngIf="selectedTemplate.type == templateTypes.PROPOSAL">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DESCRIPTION', property: 'isDescription' }"
    ></ng-container>
  </div>
  <div class="col-4">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DIMENSIONS', property: 'isDimensions' }"
    ></ng-container>
  </div>
  <div class="col-4">
    <ng-container
      *ngTemplateOutlet="
        templateFieldRef;
        context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.VOLUME_WEIGHT', property: 'isVolumeAndWeight' }
      "
    ></ng-container>
  </div>
  <div class="col-4">
    <ng-container
      *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DISCOUNT', property: 'isDiscount' }"
    ></ng-container>
  </div>
  <div class="col-4">
    <ng-container
      *ngTemplateOutlet="
        templateFieldRef;
        context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.CALCULATE_VAT', property: calculateVatPropertyName }
      "
    ></ng-container>
  </div>
</div>

<ng-template #smallPhotoPropertyList>
  <div class="row px-3">
    <div class="col-4" *ngIf="selectedTemplate.type == templateTypes.PROPOSAL">
      <ng-container
        *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DESCRIPTION', property: 'isDescription' }"
      ></ng-container>
    </div>
    <div class="col-4">
      <ng-container
        *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.SYSTEM', property: 'isSystem' }"
      ></ng-container>
    </div>
    <div class="col-4">
      <ng-container
        *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.CATEGORY', property: 'isCategory' }"
      ></ng-container>
    </div>
    <div class="col-4">
      <ng-container
        *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.FULL_CODE', property: 'isFullCode' }"
      ></ng-container>
    </div>
    <div class="col-4">
      <ng-container
        *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DIMENSIONS', property: 'isDimensions' }"
      ></ng-container>
    </div>
    <div class="col-4" *ngIf="selectedTemplate.type == templateTypes.PROPOSAL">
      <ng-container
        *ngTemplateOutlet="
          templateFieldRef;
          context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.VOLUME_WEIGHT', property: 'isVolumeAndWeight' }
        "
      ></ng-container>
    </div>
    <div class="col-4">
      <ng-container
        *ngTemplateOutlet="templateFieldRef; context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.DISCOUNT', property: 'isDiscount' }"
      ></ng-container>
    </div>
    <div class="col-4">
      <ng-container
        *ngTemplateOutlet="
          templateFieldRef;
          context: { title: 'CREATE_DOCUMENT.TEMPLATE.FIELDS.CALCULATE_VAT', property: calculateVatPropertyName }
        "
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #templateFieldRef let-property="property" let-title="title" let-disabled="disabled">
  <app-check-mark
    [value]="selectedTemplate[property]"
    (changed)="onTemplateFieldChange($event, property)"
    [name]="'document-template-field' + property"
    [text]="(title.toUpperCase() | translate) || title"
    [disabled]="disabled"
    [cssClasses]="'mb-3'"
  ></app-check-mark>
</ng-template>
