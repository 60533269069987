import { ChangeDetectorRef, Injectable, Input, OnChanges, OnDestroy, OnInit, ViewChild, Directive, inject } from '@angular/core';
import { ExtraListElementRowInterface, UpdateExtraListElementInterface } from '../components/extra-row/extra-items.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExtraListElementsTypes } from '../components/extra-row/extra-row-types.enum';
import { ExtraItemsService } from '../components/extra-row/extra-items.service';
import { SelectedRowsService } from '../../services/selected-rows/selected-rows.service';
import { SelectableRowInterface } from './selectable-row.interface';
import { UserService } from '../../../../core/services/user/user.service';
import { OrderArticlesListColumns, OrderArticlesListRow, RowTypes } from '../../order-articles-list.interface';
import { Subscription } from 'rxjs';
import { SaleMode } from '../../../../shared/components/list-mode-switch/sale-mode.types';
import { OrderArticlesRowsService } from '../../services/order-articles-rows/order-articles-rows.service';
import { ItemsCountPlural } from '../../../../core/constants/items-count-plural.constants';
import { COLUMNS } from '../order-articles-list-columns.constants';
import { ListModeSwitchService } from '../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { OrderArticleRowsFilteringService } from '../../services/order-article-rows-filtering/order-article-rows-filtering.service';

@Directive()
@Injectable()
export class ExtraRowComponentModel implements OnInit, OnDestroy, OnChanges, SelectableRowInterface {
  @ViewChild('input') titleInput;
  @Input() selectedRows: OrderArticlesListRow[];
  @Input() row: ExtraListElementRowInterface;
  @Input() limitedView?: boolean;
  @Input() columns: OrderArticlesListColumns = COLUMNS;

  protected subscriptions = new Subscription();

  form: UntypedFormGroup;
  singularPluralMap: { [k: string]: string } = ItemsCountPlural;
  types = ExtraListElementsTypes;
  selectedSaleMode: SaleMode = SaleMode.ORDER;
  saleMode = SaleMode;
  selected: boolean;
  isFilterEnabled = false;
  protected orderArticleRowsFilteringService = inject(OrderArticleRowsFilteringService);

  constructor(
    protected fb: UntypedFormBuilder,
    protected extraListElementService: ExtraItemsService,
    protected selectedRowsService: SelectedRowsService,
    protected listModeSwitchService: ListModeSwitchService,
    protected orderArticlesRowsService: OrderArticlesRowsService,
    protected cdr: ChangeDetectorRef,
    protected userService?: UserService
  ) {}

  ngOnInit() {
    this.createForm();
    this.setSelected();

    this.subscriptions.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode: SaleMode) => {
        this.selectedSaleMode = +saleMode;
      })
    );

    this.subscriptions.add(
      this.orderArticleRowsFilteringService.getIsFilterEnabledObservable().subscribe((isEnabled) => {
        this.isFilterEnabled = isEnabled;
      })
    );
  }

  ngOnChanges(): void {
    this.setSelected();
  }

  onSelect(selected: boolean) {
    this.selectedRowsService.onSelect(this.row, selected);
  }

  setSelected(): void {
    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
  }

  private createForm() {
    if (
      [ExtraListElementsTypes.TEXT, ExtraListElementsTypes.GROUP, ExtraListElementsTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP].includes(
        this.row.type
      )
    ) {
      this.form = this.fb.group({
        title: new UntypedFormControl(
          { value: this.row.title, disabled: true },
          {
            validators: [Validators.maxLength(120)],
          }
        ),
      });
      if (this.row.justCreated) {
        setTimeout(() => {
          this.enableEdit();
        }, 0);
      }
    }
  }

  private updateTitle(title: string) {
    if (this.row.title !== title) {
      const body: UpdateExtraListElementInterface = {
        title: (title ?? this.titleInput?.nativeElement.placeholder ?? '').trim(),
      };
      this.extraListElementService.update(this.row.id, body).subscribe(
        ({ data }) => {
          this.row.title = data.title;
          if (this.row.justCreated) {
            data.justCreated = false;
          }
          this.form.controls['title'].patchValue(this.row.title);
          this.orderArticlesRowsService.updateExtraRow.next({ ...this.row, ...data });
          this.updateLastModifiedGroup();
        },
        (error) => {
          this.onUndoChanges();
          console.log(error);
        }
      );
    }
  }

  onEnterPress(event) {
    event.preventDefault();
    this.titleInput?.nativeElement.blur();
  }

  onUndoChanges() {
    this.form.reset({
      title: this.row.item,
    });
    this.disableEdit();
  }

  onDelete() {
    this.orderArticlesRowsService.removeExtraRow.next([{ id: this.row.id, rowType: this.row.rowType }]);
    this.updateLastModifiedGroup();
  }

  enableEdit() {
    this.form.controls['title'].enable();
    this.titleInput?.nativeElement.focus();
    const title = this.form.controls['title'].value;
    if (title && title.length) {
      const cursorPosition = title.length;
      this.titleInput?.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    }
  }

  private disableEdit() {
    this.form.controls['title'].disable();
  }

  onBlur({ target }) {
    this.disableEdit();
    this.updateTitle(target.value.length ? target.value : target.placeholder);
  }

  /**
   * Update locally stored last modified group
   */
  private updateLastModifiedGroup() {
    if (this.row.rowType === RowTypes.GROUP || this.row.rowType === RowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP) {
      this.userService.fromStorage().subscribe();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
