import { Component, Input } from '@angular/core';
import { PriceRequestItemInterface, PriceRequestItemTypeEnum } from '../../../../core/models/price-request-item.model';

@Component({
  selector: 'app-price-request-item-modal',
  templateUrl: './price-request-item-modal.component.html',
  styleUrls: ['./price-request-item-modal.component.scss']
})
export class PriceRequestItemModalComponent {
  @Input() item: PriceRequestItemInterface;

  priceRequestItemTypes = PriceRequestItemTypeEnum;
}
