import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PropertyImageSizes } from '../../../core/enums/property-image-sizes.enum';
import { MaterialsHelper } from '../../../core/util/materials-helper/materials.helper';
import { ImageSizeEnum } from '../../../shared/pipes/resize-image/resize-image.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { OrderArticleMaterialInterface } from '../../order-article-material-input/order-article-material-interface';

@Component({
    selector: 'app-materials-list-item',
    templateUrl: './materials-list-item.component.html',
    styleUrl: './materials-list-item.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule]
})
export class MaterialsListItemComponent implements OnInit {
  @Input() material: OrderArticleMaterialInterface
  @Input() showMaterialCode = false;
  @Input() showMaterialTitle = true;
  @Input() itemClass = 'd-flex';
  
  imageSizes = ImageSizeEnum;
  materialText: string;
  popoverImgSrc: string;
  MaterialsHelper = MaterialsHelper;

  constructor() { }

  ngOnInit(): void {
    this.materialText = this.getMaterialDetailsText();
    this.popoverImgSrc = this.getUncachedImageBackground(PropertyImageSizes.LARGE);
  }
  
  getUncachedImageBackground(size?: PropertyImageSizes): string {
    const { img_thumbnails, img } = this.material;
    const value =
      (img_thumbnails &&
        ((size && img_thumbnails[size]) ||
          img_thumbnails[PropertyImageSizes.EXTRA_LARGE] ||
          img_thumbnails[PropertyImageSizes.LARGE] ||
          img_thumbnails[PropertyImageSizes.MEDIUM] ||
          img_thumbnails[PropertyImageSizes.SMALL])) ||
      img;

    // trying to update image URL to get larger file by replacing _32x32. to _240x240.
    // (full file name https://....cloudfront.net/files/SOME_CODE_32x32.jpg?version=***)
    if (value?.indexOf(`_${PropertyImageSizes.SMALL}.`) !== -1) {
      return value?.replace(`_${PropertyImageSizes.SMALL}.`, `_${PropertyImageSizes.EXTRA_LARGE}.`);
    }

    return value;
  }

  getMaterialDetailsText(): string {
    if(this.showMaterialCode) {
      return Array.from(new Set([this.material.code, this.material.translation])).join(' - ');
    }

    return this.material.translation || this.material.code;
  }
}
