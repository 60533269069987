import { Component, Input } from '@angular/core';
import { PriceRequestItemArticleInterface } from '../../../core/models/price-request-item.model';

@Component({
  selector: 'app-price-request-item-articles-table',
  templateUrl: './price-request-item-articles-table.component.html',
})
export class PriceRequestItemArticlesTableComponent {
  @Input() articles: PriceRequestItemArticleInterface[];

  formatArticleDimensions(articleDimensions: PriceRequestItemArticleInterface['dimensions']): string {
    const { millimeters, inches } = articleDimensions;
    const dimensions = millimeters || inches;

    if (!dimensions) {
      return '-';
    }

    const dimensionsSegments = [
      dimensions.width ? `W=${dimensions.width}` : '',
      dimensions.depth ? `D=${dimensions.depth}` : '',
      dimensions.height ? `H=${dimensions.height}` : ''
    ].filter(segment => segment);

    return dimensionsSegments.join(' ');
  }
}
