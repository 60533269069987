import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserInterface } from '../../../../core/models/user.model';
import { UserService } from '../../../../core/services/user/user.service';
import { SharedModule } from '../../../../shared/shared.module';
import { CheckMarkComponent } from '../../../../ui-elements/check-mark/check-mark/check-mark.component';
import { FormDropDownComponent } from '../../../../ui-elements/form-drop-down/form-drop-down.component';
import { DocumentTemplateService } from '../../../services/document-template/document-template.service';
import { DocumentTemplate } from '../../document-template.interface';

export const ImageTypes = [
  { title: 'CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES.LARGE', id: DocumentTemplate.ImageType.LARGE },
  { title: 'CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES.SMALL', id: DocumentTemplate.ImageType.SMALL },
  { title: 'CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES.NO_PHOTO', id: DocumentTemplate.ImageType.HIDDEN },
];

export const MaterialTypes = [
  { title: 'CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES.IMG_CODE_TITLE', id: DocumentTemplate.MaterialType.IMG_CODE_TITLE, templateTypes: [
    DocumentTemplate.Type.PROPOSAL
  ] },
  { title: 'CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES.CODE', id: DocumentTemplate.MaterialType.CODE, templateTypes: [
    DocumentTemplate.Type.PROPOSAL, DocumentTemplate.Type.INVOICE
  ] },
  { title: 'CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES.CODE_TITLE', id: DocumentTemplate.MaterialType.CODE_TITLE, templateTypes: [
    DocumentTemplate.Type.PROPOSAL, DocumentTemplate.Type.INVOICE
  ] },
  { title: 'CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES.IMG', id: DocumentTemplate.MaterialType.IMG, templateTypes: [
    DocumentTemplate.Type.PROPOSAL
  ] },
  { title: 'CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES.IMG_CODE', id: DocumentTemplate.MaterialType.IMG_CODE, templateTypes: [
    DocumentTemplate.Type.PROPOSAL
  ] },
  { title: 'CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES.HIDDEN', id: DocumentTemplate.MaterialType.HIDDEN, templateTypes: [
    DocumentTemplate.Type.PROPOSAL, DocumentTemplate.Type.INVOICE
  ] },
];

const MATERIAL_TYPE_POSITIONS_BY_TYPE = {
  [DocumentTemplate.Type.PROPOSAL]: [
    DocumentTemplate.MaterialType.IMG_CODE_TITLE,
    DocumentTemplate.MaterialType.CODE,
    DocumentTemplate.MaterialType.CODE_TITLE,
    DocumentTemplate.MaterialType.IMG,
    DocumentTemplate.MaterialType.IMG_CODE,
    DocumentTemplate.MaterialType.HIDDEN
  ],
  [DocumentTemplate.Type.INVOICE]: [
    DocumentTemplate.MaterialType.CODE,
    DocumentTemplate.MaterialType.CODE_TITLE,
    DocumentTemplate.MaterialType.HIDDEN,
  ]
}

@Component({
    selector: 'app-template-product-list',
    templateUrl: './template-product-list.component.html',
    imports: [SharedModule, FormDropDownComponent, CheckMarkComponent]
})
export class TemplateProductListComponent implements OnInit, OnChanges {
  @Input() selectedTemplate: DocumentTemplate.Template;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  user: UserInterface;
  photoTypes = ImageTypes;
  materialTypes = MaterialTypes;
  filteredMaterialTypes = this.materialTypes;
  templateImageType = DocumentTemplate.ImageType;
  templateTypes = DocumentTemplate.Type;
  calculateVatPropertyName = 'isVat';

  constructor(
    private translator: TranslateService,
    private documentTemplateService: DocumentTemplateService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.setPhotoAndMaterialValuesTranslations();
    this.userService.getUser().subscribe(user => (this.user = user));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTemplate &&
      changes.selectedTemplate.currentValue &&
      ((changes.selectedTemplate.previousValue &&
        changes.selectedTemplate.previousValue.type !== changes.selectedTemplate.currentValue.type) ||
        !changes.previousValue)) {
      // @TODO: would be cool to sort things in a different way.
      // This one is a bit too complicated?
      this.filteredMaterialTypes = this.materialTypes
        .filter(type => type.templateTypes.includes(this.selectedTemplate.type))
        .reduce((carry, type) => {
          const index = MATERIAL_TYPE_POSITIONS_BY_TYPE[this.selectedTemplate.type].findIndex(materialTypeId => materialTypeId === type.id);
          if (index > -1) {
            carry[index] = type;
          }

          return carry;
        }, []);
    }
  }

  onSelectPhotoType(value) {
    this.selectedTemplate.photoType = value.id;
    this.updateTemplate();
  }

  onSelectMaterialType(value) {
    this.selectedTemplate.materialType = value.id;
    this.updateTemplate();
  }

  onTemplateFieldChange(status: boolean, templateFieldProperty: string) {
    this.selectedTemplate[templateFieldProperty] = status;
    this.updateTemplate();

    if (templateFieldProperty === this.calculateVatPropertyName) {
      this.documentTemplateService.setCalculateVatEnabled(status);
    }
  }

  private updateTemplate() {
    this.documentTemplateService.updateSelectedTemplate(this.selectedTemplate);
  }

  private setPhotoAndMaterialValuesTranslations() {
    zip(
      this.translator.get('CREATE_DOCUMENT.TEMPLATE.MATERIAL_TYPES'),
      this.translator.get('CREATE_DOCUMENT.TEMPLATE.PHOTO_SIZES')
    ).subscribe(([materialTypesTranslations, photoSizeTranslations]) => {
      this.materialTypes = this.materialTypes.map(type => {
        switch (type.id) {
          case DocumentTemplate.MaterialType.HIDDEN:
            type.title = materialTypesTranslations['HIDDEN'];
            break;
          case DocumentTemplate.MaterialType.CODE:
            type.title = materialTypesTranslations['CODE'];
            break;
          case DocumentTemplate.MaterialType.CODE_TITLE:
            type.title = materialTypesTranslations['CODE_TITLE'];
            break;
          case DocumentTemplate.MaterialType.IMG:
            type.title = materialTypesTranslations['IMG'];
            break;
          case DocumentTemplate.MaterialType.IMG_CODE:
            type.title = materialTypesTranslations['IMG_CODE'];
            break;
          case DocumentTemplate.MaterialType.IMG_CODE_TITLE:
            type.title = materialTypesTranslations['IMG_CODE_TITLE'];
            break;
        }

        return type;
      });

      this.photoTypes.map(type => {
        switch (type.id) {
          case DocumentTemplate.ImageType.HIDDEN:
            type.title = photoSizeTranslations['NO_PHOTO'];
            break;
          case DocumentTemplate.ImageType.LARGE:
            type.title = photoSizeTranslations['LARGE'];
            break;
          case DocumentTemplate.ImageType.SMALL:
            type.title = photoSizeTranslations['SMALL'];
            break;
        }
      });
    });
  }
}
