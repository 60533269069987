import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DocumentTemplate } from '../../create-document-modal/document-template.interface';
import { ApiService } from '../../../api.service';
import { OrdersService } from '../../orders.service';
import { DocumentFileFormat } from '../../../core/enums/document-file-formats.enum';
import { SelectClientModalComponent } from '../../../ui-elements/select-client-modal/select-client-modal.component';
import { GenericModalTypes } from '../../../ui-elements/generic-modal/generic-modal-types';
import { ClientInterface } from '../../../core/models/client.model';
import { CreateDocumentModalComponent } from '../../create-document-modal/create-document-modal.component';
import { CurrencyInterface } from '../../../core/models/currency.model';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplateService {
  private data: BehaviorSubject<DocumentTemplate.Data> = new BehaviorSubject<DocumentTemplate.Data>(null);
  private headlineFormValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private detailsFormValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private calculateVatEnabled$: Subject<boolean> = new Subject<boolean>();
  private disabledPreviewClicked$: Subject<void> = new Subject<void>();

  constructor(
    private api: ApiService,
    private listModeSwitchService: ListModeSwitchService,
    private translator: TranslateService,
    private modalService: NgbModal,
    private ordersService: OrdersService
  ) {}

  getDocumentByTemplate(order: number, template: number, format: DocumentFileFormat) {
    const saleMode = this.listModeSwitchService.getSaleMode();
    const params = {
      'sale-mode': saleMode,
    };

    return this.api.get(
      `orders/${order}/generate/${template}/${format}`, params, 'text', this.getRequestHeadersByCurrentTemplate()
    ).noCache();
  }

  downloadDocument(order: number, template: number, format: DocumentFileFormat, fileName: string) {
    const saleMode = this.listModeSwitchService.getSaleMode();
    const params = {
      'sale-mode': saleMode,
    };

    return this.api.download(`orders/${order}/generate/${template}/${format}`, fileName, params, this.getRequestHeadersByCurrentTemplate());
  }

  private getRequestHeadersByCurrentTemplate() {
    const currentData = this.getCurrentDataState();
    const headers = new HttpHeaders({
      // language header is required for the back-end to understand which unit system conversions should be applied
      'Accept-Language': currentData.language.short,
    });

    return headers;
  }

  getTemplatesByOrder(orderId: number): Observable<DocumentTemplate.Data> {
    const saleMode = this.listModeSwitchService.getSaleMode();
    return zip(
      this.translator.get('CREATE_DOCUMENT.TEMPLATE.TYPE'),
      this.api.get(`templates/${orderId}/templates`, { 'sale-mode': saleMode }).noCache()
    ).pipe(
      map(([titleTranslations, { data }]) => {
        data.templates.map((template) => {
          switch (template.type) {
            case DocumentTemplate.Type.INVOICE:
              template.title = titleTranslations['INVOICE'];
              break;
            case DocumentTemplate.Type.PROPOSAL:
              template.title = titleTranslations['PROPOSAL'];
              break;
            default:
              throw new Error(`Invalid document template type ${template.type}`);
          }
          return template;
        });
        this.formatResponseTimestamps(data);
        this.data.next(data);
        return data;
      })
    );
  }

  selectTemplate(template: DocumentTemplate.Template) {
    this.data.next({ ...this.getCurrentDataState(), selectedTemplate: template });
  }

  updateTemplateIsVat(isVat: boolean) {
    const selected = this.getCurrentDataState().selectedTemplate;
    const updatedTemplate = { ...selected, isVat: isVat };

    this.updateData({ selectedTemplate: { ...updatedTemplate } });
  }

  updateTemplateCurrency(currency: CurrencyInterface, currencyDisplayType?: string) {
    const selected = this.getCurrentDataState().selectedTemplate;
    const updatedTemplate = { ...selected, currency, currencyDisplayType: currencyDisplayType };

    this.updateData({ selectedTemplate: { ...updatedTemplate } });
  }

  updateTemplateExchangeRate(exchangeRate: number) {
    const selected = this.getCurrentDataState().selectedTemplate;
    const updatedTemplate = { ...selected, exchangeRate: exchangeRate };

    this.updateData({ selectedTemplate: { ...updatedTemplate } });
  }

  updateData(value: Partial<DocumentTemplate.Data>) {
    this.update({ ...this.getCurrentDataState(), ...value }).subscribe((data) => {
      const templates = this.getCurrentDataState().templates;
      const others = templates.filter((t) => t.id !== data.id);
      const template = templates.find((t) => t.id === data.id);
      const selected = this.getCurrentDataState().selectedTemplate;

      const updatedTemplates = [...others, { ...template, ...data }];
      const updatedSelectedTemplate = {
        ...selected,
        ...data,
      };
      const next = {
        ...this.getCurrentDataState(),
        templates: updatedTemplates,
        selectedTemplate: updatedSelectedTemplate,
        ...data,
      };
      this.data.next(next);
    });
  }

  updateSelectedTemplate(value: Partial<DocumentTemplate.Template>) {
    const data = this.getCurrentDataState();
    this.updateData({ selectedTemplate: { ...data.selectedTemplate, ...value } });
  }

  private getCurrentDataState() {
    return this.data.getValue();
  }

  getDataAsObservable() {
    return this.data.asObservable();
  }

  private formatDate(timestamp): string {
    if (!timestamp) {
      return '';
    }
    return moment(timestamp).format('YYYY-MM-DD');
  }

  update(data: DocumentTemplate.Data): Observable<any> {
    const { order, language, selectedTemplate, orderPayments, invoiceDateTimestamp, invoiceNumber, clientDataType, client } = data;

    const { clientProposalDateTimestamp, clientLogo, ...restClientData } = client;

    const clientUpdated: DocumentTemplate.Update.Client = {
      ...restClientData,
      clientProposalDate: this.formatDate(clientProposalDateTimestamp),
    };

    if (clientLogo && typeof clientLogo.file !== 'undefined') {
      clientUpdated.clientLogo = clientLogo.file;
    }

    const { dealer, ...restTemplate } = selectedTemplate;

    const { logo: dealerLogo, ...restDealer } = dealer;

    const dealerUpdated: DocumentTemplate.Update.Dealer = {
      ...restDealer,
    };

    if (dealerLogo && typeof dealerLogo.file !== 'undefined') {
      dealerUpdated.logo = dealerLogo.file;
    }

    const { isCategory, isDescription, isDimensions, isDiscount, isFullCode, isSystem, isVat, isVolumeAndWeight } = restTemplate;
    const templateFieldsUpdated = Object.entries({
      isCategory,
      isDescription,
      isDimensions,
      isDiscount,
      isFullCode,
      isSystem,
      isVat,
      isVolumeAndWeight,
    }).reduce((acc, [key, value]) => {
      acc[key] = +value;
      return acc;
    }, {});

    const body: DocumentTemplate.Update.TemplatePartial = {
      ...restTemplate,
      ...templateFieldsUpdated,
      dealer: dealerUpdated,
      language: language.id,
      order: order.id,
      clientDataType,
      invoiceNumber,
      invoiceDate: this.formatDate(invoiceDateTimestamp),
      orderPayments: orderPayments.map(({ id, title, percentage, paymentDueDateTimestamp }) => {
        return { id, title, percentage, paymentDueDate: this.formatDate(paymentDueDateTimestamp) };
      }),
      client: clientUpdated,
      currency: selectedTemplate.currency ? selectedTemplate.currency.id : 1,
      currencyDisplayType: selectedTemplate.currencyDisplayType || DocumentTemplate.CurrencyDisplayType.NAME,
      exchangeRate: selectedTemplate.exchangeRate,
    };

    const saleMode = this.listModeSwitchService.getSaleMode();

    return this.api.post(`templates/${selectedTemplate.id}`, body, { 'sale-mode': saleMode }).pipe(
      map(({ data }) => data),
      map(this.formatResponseTimestamps)
    );
  }

  openSelectClientModal() {
    const data = this.getCurrentDataState();
    const { order, selectedTemplate } = data;
    const modalRef = this.modalService.open(SelectClientModalComponent, {
      windowClass: `${GenericModalTypes.GREEN}  create-document`,
      size: 'lg',
    });
    const componentInstance: SelectClientModalComponent = modalRef.componentInstance;
    componentInstance.order = { id: order.id };
    modalRef.result.then().catch(() => this.openLastDocumentTemplate());
    componentInstance.clientSelect
      .pipe(
        switchMap((selectedClient: ClientInterface) => {
          const client = selectedClient && selectedClient.id ? selectedClient.id : null;
          data.clientDataType = DocumentTemplate.CustomerDataType.FROM_CUSTOMER;
          this.updateData(data);
          return this.ordersService.update(order.id, { client });
        })
      )
      .subscribe((reponseOrder) => {
        modalRef.close();
        order.client = reponseOrder.client;
        const modalRefDocumentModal = this.openLastDocumentTemplate();
        const documentModalInstance: CreateDocumentModalComponent = modalRefDocumentModal.componentInstance;
        documentModalInstance.order = reponseOrder;
        documentModalInstance.data = data;
        documentModalInstance.selectedTemplate = selectedTemplate;
      });
  }

  private formatResponseTimestamps(data): DocumentTemplate.Data {
    if (data.client.clientProposalDateTimestamp) {
      data.client.clientProposalDateTimestamp *= 1000;
    }
    if (data.invoiceDateTimestamp) {
      data.invoiceDateTimestamp *= 1000;
    }
    data.orderPayments.map((payment) => {
      payment.paymentDueDateTimestamp *= 1000;
      return payment;
    });
    return data;
  }

  private openLastDocumentTemplate(): NgbModalRef {
    const { selectedTemplate, order } = this.getCurrentDataState();
    const modalRef = this.modalService.open(CreateDocumentModalComponent, {
      windowClass: `${GenericModalTypes.GREY} create-document`,
      size: 'xl',
      scrollable: true
    });
    const componentInstance: CreateDocumentModalComponent = modalRef.componentInstance
    componentInstance.selectedTemplate = selectedTemplate;
    componentInstance.order = { id: order.id };
    return modalRef;
  }

  getCalculateVatEnabledAsObservable(): Observable<boolean> {
    return this.calculateVatEnabled$.asObservable();
  }

  getDisabledPreviewClickedAsObservable(): Observable<void> {
    return this.disabledPreviewClicked$.asObservable();
  }

  setCalculateVatEnabled(value: boolean): void {
    this.calculateVatEnabled$.next(value);
    this.updateTemplateIsVat(value);
  }

  disabledPreviewClicked(): void {
    this.disabledPreviewClicked$.next();
  }

  setHeadlineFormValidity(valid: boolean): void {
    this.headlineFormValid$.next(valid);
  }

  setDetailsFormValidity(valid: boolean): void {
    this.detailsFormValid$.next(valid);
  }

  isHeadlineFormValid(): boolean {
    return this.headlineFormValid$.getValue();
  }

  isDetailsFormValid(): boolean {
    return this.detailsFormValid$.getValue();
  }
}
