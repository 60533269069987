import { Component, OnDestroy, OnInit } from '@angular/core';
import { CertificatesService } from '../services/certificates.service';
import { Subscription, switchMap, tap } from 'rxjs';
import { CertificateInterface } from '../../../core/models/certificate.model';
import { CertificatesFiltersService } from '../services/certificates-filters.service';

export interface PaginationInterface {
  page: number;
  totalPages: number | null;
}

@Component({
  selector: 'app-certificates-list',
  templateUrl: './list.component.html'
})
export class CertificatesListComponent implements OnInit, OnDestroy {
  tagIds: number[] = [];
  pagination: PaginationInterface = {
    page: 1,
    totalPages: null,
  };
  certificates: CertificateInterface[] = [];
  isLoading = true;
  
  subscriptions: Subscription = new Subscription();

  constructor(
    private certificatesService: CertificatesService,
    private certificatesFiltersService: CertificatesFiltersService,
  ) {}

  ngOnInit(): void {
    this.certificatesFiltersService.getSelectedTagsIdsAsObservable()
    .pipe(
      switchMap(tagIds => {
        this.pagination.totalPages = null;
        this.tagIds = tagIds;
        this.pagination.page = 1;
        
        return this.fetchCertificates();
      })
    ).subscribe();
  }
  
  fetchCertificates(append = false) {
    this.isLoading = true;

    return this.certificatesService
      .filterAll({ page: this.pagination.page, 'tags[]': this.tagIds })
      .noCache()
      .pipe(
        tap(({ data, meta }) => {
          if (append) {
            this.certificates.push(...data);
          } else {
            this.certificates = data;
          }

          this.pagination.totalPages = meta['total-pages'];
          this.isLoading = false;
        })
      );
  }

  trackByFn(index: number, row: CertificateInterface) {
    return row.id;
  }

  onScroll() {
    this.pagination.page++;
    this.fetchCertificates(true).subscribe();
  }

  onDownloadAll() {
    this.certificatesService.download(this.tagIds);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
