import { NotificationStatus } from '../enums/notification-status.enum';
import { NotificationType } from '../enums/notification-type.enum';
import { PaginationInterface } from './pagination.model';

export enum NotificationObjectType {
  ORDER = 'ORDER',
  CLAIM = 'CLAIM',
  PROJECT_RESERVATION = 'PROJECT_RESERVATION',
  CUSTOM_MADE_PRICE_REQUEST = 'CUSTOM_MADE_PRICE_REQUEST',
}

export interface NotificationInterface {
  id: number;
  message: string;
  status: NotificationStatus;
  timestamp: number;
  title: string;
  type: NotificationType;
  object: NotificationObjectType;
  objectId: number;
  details: NotificationDetails;
  detailsAfterChanges: NotificationDetail[];
  detailsBeforeChanges: NotificationDetail[];
  senderFirstname: string;
  senderEmail: string;
  time?: any;
  name?: any;
}

export interface NotificationDetail {
  name: string;
  value: string;
}

export interface NotificationDetails {
  count?: number;
  importDate?: string;
  importCount?: number;
  articleCodes?: string;
  itemCode?: string;
  item?: string;

  status?: string;
  previousStatus?: string;
}

export interface NotificationsListResponse {
  data: NotificationInterface[];
  meta: PaginationInterface;
}
