import { forwardRef, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap, retryWhen, delay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../ui-elements/toast/toast.service';
import { EXCLUDED_ENDPOINTS } from './server-errors-interceptor.constants';

enum forbiddenErrorCode {
  ORDER_ALREADY_SENT_TO_AX = 1,
  UNDEFINED_STATUS = 2,
  SENT_TO_AX_RETRY_TIME_INTERVAL = 3,
}

export enum API_ERROR_CODES {
  DEFAULT_MAINTENANCE = 'ER_100001#005',
  DEFAULT_ACCESS_DENIED = 'ER_100001#004',
  DEFAULT_PAGE_NOT_FOUND = 'ER_100001#003',
}

export enum ERROR_STATUSES {
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  ACCESS_DENIED = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  MAINTENANCE = 503,
}

export const SERVER_ERRORS_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => ServerErrorsInterceptor),
  multi: true,
};

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private translator: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const isExcluded = EXCLUDED_ENDPOINTS.some((endpoint) => endpoint.method === req.method && endpoint.pattern.test(req.url));

    if (isExcluded) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      retryWhen(result =>
        result.pipe(
          tap(errorResponse => {
            // will try again in 1s
            if (errorResponse.status !== ERROR_STATUSES.NOT_MODIFIED) {
              throw errorResponse;
            }
          }),
          delay(1000)
        )
      ),
      tap(
        () => {},
        (errorResponse: any) => {
          if (errorResponse instanceof HttpErrorResponse) {
            const { error: data } = errorResponse;
            let translationObservable: Observable<string>;
            if (
              [ERROR_STATUSES.BAD_REQUEST, ERROR_STATUSES.PAYMENT_REQUIRED, ERROR_STATUSES.INTERNAL_SERVER_ERROR].includes(
                errorResponse.status
              )
            ) {
              translationObservable = of('Error! Please try again later.');
            } else if (ERROR_STATUSES.NOT_FOUND === errorResponse.status) {
              translationObservable = this.translator.get(`ERRORS.PAGE_NOT_FOUND.BIG`);
            } else if (ERROR_STATUSES.ACCESS_DENIED === errorResponse.status) {
              let translationKey = 'ERRORS.FORBIDDEN';
              if (typeof errorResponse.error.errorCode === 'undefined' && typeof data.error === 'string') {
                translationKey = `ERRORS.BY_ERROR_CODE.${data.error}`;
              }

              translationObservable = this.translator.get(translationKey).pipe(
                switchMap(translation => {
                  let message;
                  if (typeof data.errorCode !== 'undefined') {
                    message = translation['DEFAULT'];
                    if (data.errorCode === forbiddenErrorCode.ORDER_ALREADY_SENT_TO_AX) {
                      message = translation['ORDER_ALREADY_SENT_TO_AX'];
                    } else if (data.errorCode === forbiddenErrorCode.UNDEFINED_STATUS) {
                      message = translation['UNDEFINED_STATUS'];
                    } else if (data.errorCode === forbiddenErrorCode.SENT_TO_AX_RETRY_TIME_INTERVAL) {
                      message = translation['SENT_TO_AX_RETRY_TIME_INTERVAL'];
                    }
                  } else {
                    message = translation;
                  }

                  return of(message);
                })
              );
            } else if (ERROR_STATUSES.MAINTENANCE === errorResponse.status) {
              if (errorResponse.error.error === API_ERROR_CODES.DEFAULT_MAINTENANCE) {
                this.router.navigate(['/maintenance']);
              }
            }

            if (translationObservable) {
              translationObservable
                .pipe(
                  tap(message => {
                    this.toastService.danger(message);
                  })
                )
                .subscribe();
            }
          }
        }
      )
    );
  }
}
