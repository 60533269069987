<div
  #groupContainer
  (click)="onGroupSelect()"
  class="cursor-pointer border-radius-0125r"
  [ngClass]="getBackgroundColor(group.materialFamily)"
  data-testid="groupContainer"
>
  <ng-container *ngIf="group.materialFamily; else valuesGroup">
  <div 
    class="material-family-name pt-1 px-2"
    #infoPopover="ngbPopover"
    [ngbPopover]="infoPopoverTemplate"
    container="body" 
    placement="left"
    triggers="manual"
    [autoClose]="false"
    [popperOptions]="infoPopperOptions"
    popoverClass="shadow border-radius-0125r z-index-below-navbar"  
  >
    <h5 class="m-0 fw-normal small text-start text-break--word">{{ group.materialFamily }}</h5>
  </div>

    <div #collapse="ngbCollapse" [ngbCollapse]="!isSelected">
      <ng-container *ngTemplateOutlet="valuesGroup"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #valuesGroup>
    <div class="group">
      <div class="position-relative fixed-height" *ngFor="let value of group.values">
        <ng-container *ngIf="value.thumbnail || value.valueFrom.length > 2; else withoutImg">
          <img
            [src]="value.thumbnail || '/assets/icons/icon-material-placeholder-small.svg'"
            #origin
            (mouseenter)="onOpenPopover(value, popover, origin)"
            (mouseleave)="onClosePopover()"
            class="image overflow-hidden cursor-pointer"
          />
        </ng-container>
        <ng-template #withoutImg>
          <div #origin (mouseenter)="onOpenPopover(value, popover, origin)" (mouseleave)="onClosePopover()" class="text">
            {{ value.valueFrom }}
          </div>
        </ng-template>
      </div>
  </div>
</ng-template>

<ng-template #popover let-img="img" let-value="value">
  <div class="preview-popover" [ngClass]="{ 'm-0': !img }">
    <ng-container>
      <svg
        width="110"
        height="110"
        class="preview-popover__material-image"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4h48.324L55.199 0l6.875 4.4H110V110H0V4.4z" />
        <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="110" height="110">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4h48.324L55.199 0l6.875 4.4H110V110H0V4.4z" fill="#fff" />
        </mask>
        <g mask="url(#a)">
          <path fill="url(#pattern0)" d="M-10-2.917h120v120h-120z" />
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0" transform="scale(.01)" />
          </pattern>
          <image
            id="image0"
            [attr.width]="img ? 110 : 120"
            height="110"
            [attr.xlink:href]="img || '../../../../../assets/icons/icon-material-placeholder-large.svg'"
          />
        </defs>
      </svg>
    </ng-container>
    <div class="preview-popover__value">
      {{ value }}
    </div>
  </div>
</ng-template>

<ng-template #infoPopoverTemplate>
    <h4 class="fw-bold mb-3">{{ 'CATALOGUE.COMPATIBILITY_INFO_POPUP.TITLE' | translate }}</h4>

    <p>{{ 'CATALOGUE.COMPATIBILITY_INFO_POPUP.INFO' | translate }}</p>

    <button class="w-100 btn btn-primary small py-1" (click)="onDismissInfoPopovers()">
      {{ 'CATALOGUE.COMPATIBILITY_INFO_POPUP.GOT_IT' | translate }}
    </button>
</ng-template>
