
<div
  *ngIf="isCanceled(); else readyToOrderIcon"
  class="me-1"
  [ngbPopover]="expiredOrCancelledPopover"
  placement="right"
  popoverClass="price-request-item-status-popover shadow"
  appClickStopPropagation
  container="body"
>
  <i class="icon ni-cancel-fill"></i>
</div>

<ng-template #readyToOrderIcon>
  <div
    *ngIf="shouldDisplayReadyToOrderPopover(); else needsClarificationIcon"
    class="me-1"
    [ngbPopover]="readyToOrderPopover"
    placement="right"
    popoverClass="price-request-item-status-popover shadow ready-to-order-popover"
    appClickStopPropagation
  >
    <i class="icon ni-tick-circle color-success"></i>
  </div>
</ng-template>

<ng-template #readyToOrderPopover>
  <h3 class="header px-3 py-2 m-0 color-gray-700 bg-gray-200" appClickStopPropagation>
    {{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.READY_TO_ORDER' | translate }}
  </h3>
</ng-template>

<ng-template #needsClarificationIcon>
  <div
    class="me-1"
    [ngbPopover]="needsClarificationPopover"
    placement="right"
    popoverClass="price-request-item-status-popover shadow needs-clarification-popover"
    appClickStopPropagation
  >
    <i class="icon ni-warning-circle-fill color-danger"></i>
  </div>
</ng-template>

<ng-template #needsClarificationPopover>
  <div class="p-0" appClickStopPropagation>
    <h3 class="header px-3 py-2 border-bottom border-gray-900 color-gray-700 bg-gray-200">
      {{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.NEEDS_CLARIFICATION' | translate }}
    </h3>

    <div class="px-3 pt-2 pb-3">
      <p class="mb-0 small">{{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.PROVIDE_MORE_INFO' | translate }}</p>
      <p class="my-2 small">“{{ item.clarificationQuestion }}”</p>
      <button class="btn btn-link p-0 no-outline small" (click)="openClarification()">
        {{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.CLARIFY_NOW' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #expiredOrCancelledPopover>
  <div class="p-0" appClickStopPropagation>
    <h3 class="header px-3 py-2 border-bottom border-gray-900 color-gray-700 bg-gray-200">
      {{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.ITEM_CANCELED' | translate }}
    </h3>

    <div class="px-3 pt-2 pb-3">
      <p class="mb-0 small">{{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.ITEM_CANCELED_DESCRIPTION' | translate }}</p>
    </div>
  </div>
</ng-template>
