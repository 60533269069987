import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';
import { ContentListComponent } from './content-list/content-list.component';
import { ContentEntryComponent } from './content-entry/content-entry.component';
import { ContentComponent } from './content.component';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { UncacheableResourceModule } from '../uncacheable-resource/uncacheable-resource.module';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ContentParentComponent } from './content-parent.component';
import { FinishesComponent } from './finishes/finishes.component';
import { CheckboxFilterModule } from '../ui-elements/checkbox-filter/checkbox-filter.module';
import { FinishesFamilyComponent } from './finishes-family/finishes-family.component';
import { CustomMissingTranslationHandler } from '../core/models/custom-missing-translation-handler.model';
import { CertificatesModule } from './certificates/certificates.module';

@NgModule({
  declarations: [
    ContentListComponent,
    ContentEntryComponent,
    ContentComponent,
    ContentParentComponent,
    FinishesComponent,
    FinishesFamilyComponent,
  ],
  imports: [
    CommonModule,
    ContentRoutingModule,
    LoaderModule,
    SharedModule,
    UncacheableResourceModule,
    TranslateModule.forChild({
        missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    RouterModule,
    CheckboxFilterModule,
    CertificatesModule,
  ]
})
export class ContentModule { }
