import {Injectable} from '@angular/core';
import {ApiService} from '../../../../../api.service';
import {
  CreateExtraListElementInterface, ExtraListElementInterface,
  UpdateExtraListElementInterface,
  UpdateExtraListElementPositionInterface
} from './extra-items.model';
import {CacheableObservable} from '../../../../../cacheable-observable/cacheable-observable.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

const apiPath = 'page-break/';

@Injectable()
export class ExtraItemsService {

  constructor(private api: ApiService) {
  }

  fetch(orderId: number): CacheableObservable<ExtraListElementInterface[]> {
    return this.api.get(`${apiPath}${orderId}`).pipe(map(({data}) => data)) as CacheableObservable<ExtraListElementInterface[]>;
  }

  create(extraElement: CreateExtraListElementInterface) {
    return this.api.post(`${apiPath}`, extraElement).pipe(map(({data}) => data));
  }

  update(id: number, extraElement: UpdateExtraListElementInterface) {
    return this.api.patch(`${apiPath}${id}`, extraElement);
  }

  updateMultiple(extraElements: UpdateExtraListElementPositionInterface[]) {
    return this.api.patch('page-break/multi/', extraElements);
  }

  deleteMultiple(items: {id: number}[]): Observable<any> {
    return this.api.patch(`page-break/multi/delete/`, items);
  }
}
