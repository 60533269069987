import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface BorderBoxSizeInterface {
  height: number;
  width: number;
}

export interface ObservedUIElementSizes {
  orderControlsBorderBox: BorderBoxSizeInterface | null;
  orderTableHeaderBorderBox: BorderBoxSizeInterface | null;
  ordersListBorderBox: BorderBoxSizeInterface | null;
  navbarBorderBox: BorderBoxSizeInterface | null;
  priceRequestControlsBorderBox: BorderBoxSizeInterface | null;
  priceRequestTableHeaderBorderBox: BorderBoxSizeInterface | null;
}

class ResizeObserverState {
  private sizes$ = new BehaviorSubject<ObservedUIElementSizes>({
    orderControlsBorderBox: null,
    orderTableHeaderBorderBox: null,
    ordersListBorderBox: null,
    navbarBorderBox: null,
    priceRequestControlsBorderBox: null,
    priceRequestTableHeaderBorderBox: null,
  });

  get(): Observable<ObservedUIElementSizes> {
    return this.sizes$.asObservable();
  }

  getCurrentValue(): ObservedUIElementSizes {
    return this.sizes$.getValue();
  }

  notify(resize: Partial<ObservedUIElementSizes>): void {
    const value = this.sizes$.value;
    const newValue = { ...value };

    for (const size in resize) {
      newValue[size] = resize[size];
    }

    this.sizes$.next(newValue);
  }
}

/** Stores and reports UI element sizes. */
export const ResizeNotifier = new ResizeObserverState();

@Injectable()
export class ResizeNotifierService extends ResizeObserverState {}
