import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {decimalNumbers, percentageValidator} from '../../shared/class/custom-validators';
import {TextFieldThemeTypes} from '../../ui-elements/text-field/text-field/theme-types.enum';
import {OrderArticleService} from '../../core/services/order-article/order-article.service';
import {
  BatchOrderArticlesInterface,
  ORDER_ARTICLE_DISCOUNT_TYPE,
  OrderArticleBatchUpdateInterface,
  OrderArticleInterface,
  orderArticleTypeToFormValue,
  OrderArticleType,
} from '../../core/models/order-article.model';
import {debounceTime, distinctUntilChanged, switchMap, tap, map} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {LoaderComponent} from '../../ui-elements/loader/loader.component';
import {PermissionActions} from '../../permissions.config';
import {UserRole} from '../../core/enums/user-role.enum';
import {UserService} from '../../core/services/user/user.service';
import {OrdersService} from "../orders.service";

export interface ModifiedOrderArticleInterface extends OrderArticleInterface {
  customPriceAllowed: boolean;
  customDiscountAllowed: boolean;
}

export interface OrderArticleFormGroupInterface {
  customPrice?: number;
  discount?: number;
  fullCode: string;
  itemType: number;
}

function customParseFloat(n?: string | number) {
  // not strict check. the only way to check if object is undefined or null
  if (n == null) {
    return null;
  }

  return typeof n === 'string' ? n.replace(',', '.') : n;
}

const PROPERTY_TYPES = {
  CUSTOM_PRICE_ALLOWED: 'customPriceAllowed',
  CUSTOM_DISCOUNT_ALLOWED: 'customDiscountAllowed',
};

@Component({
  selector: 'app-discount-modal',
  templateUrl: './discount-modal.component.html',
  styleUrls: ['./discount-modal.component.scss'],
})
export class DiscountModalComponent implements OnInit, OnDestroy {
  @ViewChild('loader', {static: true}) loader: LoaderComponent;

  @Output() saved: EventEmitter<void> = new EventEmitter();

  items: ModifiedOrderArticleInterface[] = [];
  selectedItems: ModifiedOrderArticleInterface[] = [];
  ids: number[] = [];
  form: UntypedFormGroup;
  itemsFormArray: UntypedFormArray;
  discountForm: UntypedFormGroup;
  textFieldThemeTypes = TextFieldThemeTypes;
  totals: {
    discount: number;
    price: number;
    discountedPrice: number;
    vatPercentage: number;
    vat: number;
    priceWithVatApplied: number;
  } = {
    discount: 0,
    price: 0,
    discountedPrice: 0,
    vat: 0,
    vatPercentage: 0,
    priceWithVatApplied: 0,
  };
  subscriptions = new Subscription();
  propertyTypes = PROPERTY_TYPES;
  permissionActions = PermissionActions;
  userRole = UserRole;
  orderArticleTypes = OrderArticleType;
  materialCodes = [];
  // quantityLocked = [];
  quantityInOrder = [];
  isPm = false;
  isPmNabrutas = false;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private orderArticleService: OrderArticleService,
    private ordersService: OrdersService,
    private userService: UserService
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.loader.show();
    this.userService
      .fromStorage()
      .pipe(map(user => user.role.name))
      .subscribe((role: UserRole) => {
        if ([this.userRole.ROLE_PM, this.userRole.ROLE_PM_RU].includes(role)) {
          this.isPm = true;
        }
        if (this.userRole.ROLE_PM_NARBUTAS === role) {
          this.isPmNabrutas = true;
        }
      });

    const buildInnerFormGroup = (
      index: number,
      discount: number = null,
      customPrice: number = null,
      fullCode: string,
      type: number,
    ): UntypedFormGroup => {
      const customPriceValidators = this.items[index].customPriceAllowed ? [Validators.min(-1), decimalNumbers] : [];
      const discountValidators =
        this.items[index].customDiscountAllowed && this.items[index].discount !== undefined
          ? [percentageValidator()]
          : [];

      return this.fb.group({
        itemType: orderArticleTypeToFormValue(type),
        fullCode,
        discount: [discount, discountValidators],
        customPrice: [customPrice, customPriceValidators],
      });
    };

    this.itemsFormArray = this.fb.array([]);
    this.form = this.fb.group({
      items: this.itemsFormArray,
    });

    this.discountForm = this.fb.group({
      discount: [null, [percentageValidator()]],
    });

    if (this.ids.length > 0) {
      this.subscriptions.add(
        this.load()
          .pipe(
            tap(response => {
              this.totalsFromResponse(response);
              response.orderArticles.forEach((item, index) => {
                (this.form.get('items') as UntypedFormArray).push(
                  buildInnerFormGroup(
                    index,
                    item.discountType === ORDER_ARTICLE_DISCOUNT_TYPE.CUSTOM ? item.discount : null,
                    item.customPrice,
                    item.fullCode,
                    item.type
                  )
                );

                // materials in raw format
                this.materialCodes[item.id] = [];
                item.orderArticleMaterialsWithChildren.forEach(e => this.materialCodes[item.id].push(e.code));
              });
              this.loader.hide();
            }),
            switchMap(response => {
              return this.form.valueChanges.pipe(
                debounceTime(1500),
                distinctUntilChanged(),
                switchMap(value => {
                  if (this.form.valid) {
                    const items: OrderArticleFormGroupInterface[] = value.items.map(item => this.makeItemPriceUpdatePayload(item));
                    return this.recalculate(items).pipe(
                      tap((recalculatedResponse: BatchOrderArticlesInterface) => {
                        this.totalsFromResponse(recalculatedResponse);
                      })
                    );
                  }

                  return of({});
                })
              );
            })
          )
          .subscribe()
      );
    }
  }

  onClose() {
    this.activeModal.dismiss();
  }

  areAllSelected() {
    return this.selectedItems.length === this.items.length;
  }

  onSelectAll() {
    this.selectedItems = this.areAllSelected() ? [] : [...this.items];
  }

  isSelected(id: number): boolean {
    return !!this.selectedItems.find(item => item.id === id);
  }

  onSelect(item) {
    const found = this.selectedItems.find(selectedItem => selectedItem.id === item.id);
    this.selectedItems = found
      ? [...this.selectedItems.filter(selectedItem => selectedItem.id !== found.id)]
      : [...this.selectedItems, item];
  }

  onPropertyAllowed(property: string, index: number, value?: number, shouldAllowProperty?: boolean) {
    const validatorsByProperty =
      property === PROPERTY_TYPES.CUSTOM_PRICE_ALLOWED
        ? [Validators.required, Validators.min(-1), decimalNumbers]
        : [Validators.required, percentageValidator()];
    const formProperty = property === PROPERTY_TYPES.CUSTOM_PRICE_ALLOWED ? 'customPrice' : 'discount';

    const item = this.items[index];
    const propertyAllowed = shouldAllowProperty || !item[property];

    const validators = propertyAllowed && value ? validatorsByProperty : [];
    const formControl = (this.form.get('items') as UntypedFormArray).at(index);
    formControl.get(formProperty).setValidators(validators);

    if (!propertyAllowed) {
      formControl.patchValue({
        [formProperty]: null,
      });

      this.items[index][formProperty] = null;
    } else {
      if (property === PROPERTY_TYPES.CUSTOM_DISCOUNT_ALLOWED && propertyAllowed !== item[property]) {
        formControl.patchValue({
          [formProperty]: 0,
        });

        this.items[index][formProperty] = 0;
      }
    }

    this.items[index][property] = propertyAllowed;
  }

  onSave() {
    this.loader.show();
    this.orderArticleService
      .batchPrice(
        this.ids,
        {
          orderArticles: this.form.get('items').value.map(item => this.makeItemPriceUpdatePayload(item)),
        },
        false
      )
      .subscribe(() => {
        this.saved.emit();
        this.loader.hide();
        this.onClose();
      });
  }

  public onApply() {
    if (this.discountForm.valid) {
      const discount = this.discountForm.get('discount').value;
      const indices = this.selectedItems.reduce((carry, selectedItem) => {
        const foundIndex = this.items.findIndex(item => item.id === selectedItem.id);
        if (foundIndex > -1) {
          selectedItem.customDiscountAllowed = true;
          carry.push(foundIndex);
        }

        return carry;
      }, []);

      const controls = this.form.get('items') as UntypedFormArray;
      indices.forEach(index => {
        controls.at(index).patchValue({
          discount,
        });
      });
    }
  }

  onCustomPriceChanged(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    const value = Number(input.value);

    this.items[index].customPriceAllowed = value && value !== 0;
    this.onPropertyAllowed(PROPERTY_TYPES.CUSTOM_PRICE_ALLOWED, index, value, this.items[index].customPriceAllowed);
  }

  onCustomDiscountChanged(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    const value = Number(input.value);

    this.items[index].customDiscountAllowed = value && value !== 0;
    this.onPropertyAllowed(PROPERTY_TYPES.CUSTOM_DISCOUNT_ALLOWED, index, value, this.items[index].customDiscountAllowed);
  }

  private totalsFromResponse(response: BatchOrderArticlesInterface) {
    this.totals = {
      ...response,
    };
  }

  private load(): Observable<BatchOrderArticlesInterface> {
    return this.orderArticleService
      .batchGet(this.ids)
      .noCache()
      .pipe(
        tap((response: BatchOrderArticlesInterface) => {
          this.items = response.orderArticles.map((item: OrderArticleInterface) => {
            const found = this.items.find(existing => existing.id === item.id);
            return {
              ...item,
              customDiscountAllowed: found ? found.customDiscountAllowed : item.discountType === ORDER_ARTICLE_DISCOUNT_TYPE.CUSTOM,
              customPriceAllowed: found ? found.customPriceAllowed : item.customPrice !== null && item.customPrice !== 0,
            };
          });
        })
      );
  }

  private recalculate(values: OrderArticleFormGroupInterface[]) {
    return this.orderArticleService
      .batchPrice(
        this.ids,
        {
          orderArticles: values as OrderArticleBatchUpdateInterface[],
        },
        true)
      .pipe(
        tap((response: BatchOrderArticlesInterface) => {
          response.orderArticles.forEach((item: OrderArticleInterface) => {
            this.items.map(existing => {
              if (existing.id === item.id) {
                existing.price = item.price;
                existing.totalPrice = item.totalPrice;
                existing.formattedPrice = item.formattedPrice;
              }

              return existing;
            });
          });
        }),
      );
  }

  private makeItemPriceUpdatePayload({discount, customPrice, fullCode, itemType}): OrderArticleBatchUpdateInterface {
    return {
      itemType,
      fullCode,
      discount: customParseFloat(discount),
      customPrice: this.isPmNabrutas ? customParseFloat(customPrice) : undefined,
    } as OrderArticleBatchUpdateInterface;
  }
}
