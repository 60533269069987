<app-generic-modal [heading]="(
  item.type === priceRequestItemTypes.BASED_ON_STANDARD 
    ? 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.TITLE_BASED_ON_STANDARD'
    : 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.TITLE_CUSTOM_MADE'
  ) | translate">

  <ng-template appModalBody>
    <div class="row mb-2" *ngIf="item.type === priceRequestItemTypes.BASED_ON_STANDARD">
      <div class="col-12">
        <div class="mb-3">{{'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.SELECTED_STANDARD_PARTS' | translate}}</div>
        <app-price-request-item-articles-table [articles]="item.articles"></app-price-request-item-articles-table>
        <div class="mb-3 mt-4">{{'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.YOUR_MODIFICATIONS' | translate}}</div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6 fw-bold text-black text-break--all">{{item.title}}</div>
      <div class="col-6">
        <label class="form-label d-inline">
          {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.QUANTITY' | translate }}
        </label>
        <span class="text-black ms-2">{{item.quantity}}</span>
      </div>
    </div>

    <div class="mb-4">
      <label class="form-label mb-3">
        {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.DESCRIPTION' | translate }}
      </label>
      <div class="text-black text-break--all">
        {{item.description}}
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <label class="form-label mb-3">
          {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DIMENSIONS' | translate }}
        </label>
        <div class="text-black text-break--all">{{item.dimensions}}</div>
      </div>
      <div class="col-6">
        <label class="form-label mb-3">
          {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.FINISHES' | translate }}
        </label>
        <app-order-article-material-input
          #finishesMaterialInput
          id="finishes"
          name="finishes"
          [value]="item.finishes"
          [listOnly]="true"
        />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <label class="form-label mb-3">
          {{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.ATTACHMENTS' | translate }}
        </label>
        <div class="d-flex flex-wrap gap-2 position-relative">
          <app-file-preview *ngFor="let attachment of item.customMadePriceRequestItemFiles;" [file]="attachment.file"></app-file-preview>
        </div>
      </div>

      <div class="col-6" *ngIf="item.clarificationAnswer">
        <label class="form-label">
          {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.CLARIFICATION' | translate }}
        </label>

        <div class="color-gray-900">
          {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.CLARIFIED_BY' | translate }}
          {{item.clarificationAnsweredByFullName}}, {{item.clarificationAnsweredAtTimestamp * 1000 | i18nDate}}
        </div>

        <div class="color-gray-900">
          {{item.clarificationAnswer}}
        </div>
      </div>
    </div>
  </ng-template>
</app-generic-modal>