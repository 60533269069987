import { Component, Input } from '@angular/core';
import { OrderArticleMaterialInterface } from '../order-article-material-input/order-article-material-interface';

@Component({
  selector: 'app-materials-list',
  templateUrl: './materials-list.component.html',
})
export class MaterialsListComponent {
  @Input() materials: OrderArticleMaterialInterface[];
  @Input() showMaterialCode = false;
  @Input() showMaterialTitle = true;
  @Input() materialListItemClass = 'd-flex';
}
