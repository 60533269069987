import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FieldClass } from '../../../../../../core/enums/field-class';
import { InquiriesRoutePath } from '../../../../../../core/enums/route-types.enum';
import { FileData } from '../../../../../../core/models/file-data.model';
import {
  PriceRequestItemInterface,
  PriceRequestItemStatusEnum,
  PriceRequestItemStatusTranslationMap,
  PriceRequestItemTypeEnum,
} from '../../../../../../core/models/price-request-item.model';
import { FieldService } from '../../../../../../core/services/field/field.service';
import { PriceRequestItemsService } from '../../../../../../inquiries/custom-made-price-requests/services/price-request-items.service';
import { FieldType } from '../../../../../../shared/directives/hide-by-field-type/field-types.enum';
import { OrderArticleMaterialInputService } from '../../../../../../ui-elements/order-article-material-input/order-article-material-input.service';
import { OrderArticleMaterialInterface } from '../../../../../../ui-elements/order-article-material-input/order-article-material-interface';
import { OrderArticlesListColumns, OrderArticlesListRow, OrderArticlesListRowInterface } from '../../../../order-articles-list.interface';
import { SelectedRowsService } from '../../../../services/selected-rows/selected-rows.service';
import { SelectableRowInterface } from '../../../models/selectable-row.interface';
import { COLUMNS } from '../../../order-articles-list-columns.constants';
import { OrderState } from '../../../../../../core/enums/order.state.enum';
import { UserService } from '../../../../../../core/services/user/user.service';
import { OrderArticleRowsFilteringService } from '../../../../services/order-article-rows-filtering/order-article-rows-filtering.service';
import { SaleMode } from "../../../../../../shared/components/list-mode-switch/sale-mode.types";
import { ListModeSwitchService } from "../../../../../../shared/components/list-mode-switch/list-mode-switch.service";
import { UserInterface } from "../../../../../../core/models/user.model";
import { OrderArticlesRowsService } from "../../../../services/order-articles-rows/order-articles-rows.service";
import { environment } from '../../../../../../../environments/environment';
import { PriceRequestStatusEnum } from '../../../../../../core/models/price-request.model';

@Component({
  selector: 'app-order-articles-price-request-item-row',
  templateUrl: './order-articles-price-request-item-row.component.html',
  styleUrls: ['./order-articles-price-request-item-row.component.scss'],
})
export class OrderArticlesPriceRequestItemRowComponent implements OnInit, OnChanges, SelectableRowInterface, OnDestroy {
  @Input() row: OrderArticlesListRowInterface;
  @Input() columns: OrderArticlesListColumns = COLUMNS;
  @Input() selectedRows: OrderArticlesListRow[];
  @Input() limitedView?: boolean;
  @Input() orderState?: OrderState;
  @Input() hasDiscountsInPurchaseMode = true;
  @Output() clarificationCanceled = new EventEmitter<boolean>();
  @Output() clarificationSubmitted = new EventEmitter<void>();

  selected: boolean;
  fieldsToShow = [];
  imageFile: FileData | undefined;
  rowMaterials: OrderArticleMaterialInterface[] = [];

  statusTranslations = PriceRequestItemStatusTranslationMap;
  fieldTypes = FieldType;
  orderStates = OrderState;
  isFilterEnabled = false;

  selectedMode: SaleMode;
  user: UserInterface;

  saleMode = SaleMode;
  priceRequestItemStatuses = PriceRequestItemStatusEnum;

  isDealer = false;
  isPM = false;
  isPmNarbutas = false;

  private subscriptions = new Subscription();

  constructor(
    public userService: UserService,
    private selectedRowsService: SelectedRowsService,
    private orderArticleMaterialInputService: OrderArticleMaterialInputService,
    private fieldService: FieldService,
    private priceRequestItemsService: PriceRequestItemsService,
    private router: Router,
    private orderArticleRowsFilteringService: OrderArticleRowsFilteringService,
    private listModeSwitchService: ListModeSwitchService,
    private orderArticlesRowsService: OrderArticlesRowsService,
  ) {}

  ngOnInit() {
    this.imageFile = this.priceRequestItemsService.getFirstImageFile(this.priceRequestItem);

    this.initializeMaterials();

    this.subscriptions.add(
      this.fieldService.getOptionsAsObservable(FieldClass.ORDER).subscribe((groups) => {
        this.fieldsToShow = groups
          .flatMap((group) => group.values)
          .filter((value) => value.checked)
          .map((item) => item.name);
      })
    );

    this.subscriptions.add(
      this.orderArticleRowsFilteringService.getIsFilterEnabledObservable().subscribe((isEnabled) => {
        this.isFilterEnabled = isEnabled;
      })
    );

    this.subscriptions.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode: SaleMode) => {
        this.selectedMode = +saleMode;
      })
    );

    this.subscriptions.add(this.userService.getUser().subscribe(user => (this.user = user)));

    this.isDealer = this.userService.isDealer();
    this.isPM = this.userService.isPM();
    this.isPmNarbutas = this.userService.isPmNarbutas();
  }

  ngOnChanges(): void {
    this.setSelected();
  }

  initializeMaterials(): void {
    if (!this.priceRequestItem.finishes) {
      return;
    }

    this.subscriptions.add(
      this.orderArticleMaterialInputService.parseMaterialsString(this.priceRequestItem.finishes).subscribe((result) => {
        this.rowMaterials = result;
      })
    );
  }

  onOpenPriceRequest(): void {
    this.router.navigate([
      InquiriesRoutePath.ROOT,
      InquiriesRoutePath.CUSTOM_MADE_PRICE_REQUESTS,
      this.priceRequestItem.customMadePriceRequestId,
    ]);
  }

  onOpenPriceRequestInSD(event): void {
    event.stopPropagation();

    const { jiraIssueKey } = this.priceRequestItem;

    if (!jiraIssueKey || this.isDealer) {
      this.onSelect(true);
      return;
    }

    window.open(`${environment.serviceDeskURL}${jiraIssueKey}`, '_blank');
  }

  onReviewItem(): void {
    this.priceRequestItemsService.openPriceRequestItemModal(this.priceRequestItem);
  }

  get icon(): string {
    return this.row.customMadePriceRequestItem.type === PriceRequestItemTypeEnum.BASED_ON_STANDARD ? 'ni-widgets' : 'ni-widgets-fill';
  }

  get priceRequestItem(): PriceRequestItemInterface {
    return this.row.customMadePriceRequestItem;
  }

  get isCanceledOrExpired(): boolean {
    return [PriceRequestItemStatusEnum.CANCELED, PriceRequestItemStatusEnum.EXPIRED].includes(this.priceRequestItem.status);
  }

  get description(): string {
    const { description, clarificationAnswer} = this.priceRequestItem;

    return [description, clarificationAnswer].filter(Boolean).join(' ');
  }

  shouldDisplayStatusBadge(): boolean {
    return this.row.pageBreak?.customMadePriceRequest?.status !== PriceRequestStatusEnum.EXPIRED && this.isCanceledOrExpired;
  }

  isColumnsVisible(targetColumns = []): boolean {
    return targetColumns.some((value) => this.fieldsToShow.includes(value));
  }

  setSelected(): void {
    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
  }

  onToggleSelect(): void {
    this.onSelect(!this.selected);
  }

  onSelect(selected: boolean): void {
    if (this.row.belongsToLockedGroup) {
      return;
    }

    this.selectedRowsService.onSelect(this.row, selected);
  }

  onCancelClarification(shouldRefresh: boolean) {
    this.clarificationCanceled.emit(shouldRefresh);
  }

  onSubmitClarification() {
    this.clarificationSubmitted.emit();
  }

  onApplyCustomDiscount(row: OrderArticlesListRowInterface) {
    this.orderArticlesRowsService.applyCustomDiscount.next(row);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
