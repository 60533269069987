import { Inject, Injectable } from '@angular/core';
import { PERSIST_LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constants';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(PERSIST_LOCAL_STORAGE_KEYS) private keysToPersist: string[]) {}

  clearStorage(): void {
    Object.keys(localStorage).forEach((key) => {
      if (!this.keysToPersist.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }
}
